import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { getAuthCredentails, verifyAuthCredentails } from "../../utils";
import CustomizedSnackbars from "../Shared/SnackBar";

export default function Login() {
  const [requestNumber, setRequestNumber] = React.useState("");
  const [otpData, setOtpData] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState({
    isAlert: false,
    msg: "",
    type: "",
  });
  const [enableOTP, setEnableOTP] = React.useState(false);

  const handleRequestEmailOrPhone = (e) => {
    e.preventDefault();
    if (alertOpen?.isAlert) {
      setAlertOpen({ isAlert: false, msg: "", type: "" });
    }
    if (enableOTP) {
      setEnableOTP(false);
    }
    const value = e.target.value;
    setRequestNumber(value);
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    const opt = e.target.value;
    setOtpData(opt);
  };

  const submitVerifyOtp = async () => {
    const requestOptData = otpData;
    const requestEmailorMobile = requestNumber;

    const verifyOtpResones = await verifyAuthCredentails({
      emailID: requestEmailorMobile,
      type: "login",
      userOTP: requestOptData,
    });
    const { data = "", status = 100 } = verifyOtpResones;

    if (status === 200) {
      localStorage.setItem("userToken", JSON.stringify(data));
      if (window !== undefined) {
        window.location.href = "/";
      }
    } else {
      setAlertOpen({
        isAlert: true,
        msg: "Something went wrong, try again",
        type: "error",
      });
    }
  };

  const getLoginOTP = async () => {
    const mobileOrEmail = requestNumber;
    const regexForPhone = /^([+]\d{2})?\d{10}$/;
    const regexForEmail =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (
      mobileOrEmail.match(regexForPhone) ||
      mobileOrEmail.match(regexForEmail)
    ) {
      setOtpData("");
      const validateEmailResonse = await getAuthCredentails({
        emailID: mobileOrEmail,
        type: "login",
      });
      const { status = 0 } = validateEmailResonse;
      const {
        response: {
          data: {
            error = "",
            errorMessage = "",
            status: errorStatus = 100,
          } = {},
        } = {},
      } = validateEmailResonse;
      if (status === 200) {
        setAlertOpen({
          isAlert: true,
          msg: "Sent Code",
          type: "success",
        });
        setEnableOTP(true);
      } else if (errorStatus === 400 && error === "Bad Request") {
        setAlertOpen({
          isAlert: true,
          msg: `${errorMessage} Create an account or Try different email`,
          type: "error",
        });
      } else {
        setAlertOpen({
          isAlert: true,
          msg: "Something went wrong, try again",
          type: "error",
        });
      }
    } else {
      setAlertOpen({
        isAlert: true,
        msg: "Invalid Email",
        type: "error",
      });
      console.log("not valid number");
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={1}
          direction={"column"}
          minWidth={350}
          maxWidth={350}
        >
          <Grid xs={12} item textAlign={"center"}>
            <Typography
              component="h1"
              variant="h5"
              fontWeight={500}
              color={"primary"}
            >
              Log in
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              id="mobileOrEmail"
              label="Enter Email/Mobile number"
              name="mobileOrEmail"
              autoComplete="number"
              onChange={handleRequestEmailOrPhone}
              autoFocus
            />
          </Grid>
          {enableOTP && (
            <Grid xs={12} item>
              <TextField
                margin="normal"
                type="number"
                required
                size="small"
                fullWidth
                name="otp"
                label="Enter OTP"
                id="otp"
                onChange={handleVerifyOTP}
                disabled={!enableOTP}
                autoComplete="otp"
              />
            </Grid>
          )}
          <Grid xs={12} item>
            {!enableOTP && (
              <Button
                type="submit"
                id="OTPRequest"
                fullWidth
                size="small"
                name="OTPRequest"
                variant="contained"
                onClick={getLoginOTP}
                color="login"
                sx={{ color: "white" }}
              >
                Login
              </Button>
            )}
            {enableOTP && (
              <Button
                type="submit"
                id="OTPRequest"
                fullWidth
                size="small"
                name="OTPRequest"
                variant="contained"
                onClick={submitVerifyOtp}
                color="login"
                sx={{ color: "white" }}
              >
                Verify
              </Button>
            )}
          </Grid>
          <Grid xs={12} item>
            <Grid container spacing={2} direction={"row"}>
              <Grid xs={6} item>
                <Link to="/signup">{"Create an account"}</Link>
              </Grid>
              <Grid xs={6} item>
                {enableOTP && (
                  <Link onClick={getLoginOTP}>{"Request Again? "}</Link>
                )}
              </Grid>
              <Grid xs={12} item>
                {alertOpen?.isAlert && (
                  <CustomizedSnackbars
                    text={alertOpen?.msg}
                    isOpen={alertOpen?.isAlert}
                    type={alertOpen?.type}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
