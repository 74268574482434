import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import { getDeliveryRate } from "../../../../utils";
import {
  getProductRateDetails,
  isPincodeAvailable,
  setDefaultAddress,
  setUpdatePincode,
} from "../../../../actions";
import CustomizedSnackbars from "../../../Shared/SnackBar";
import DeliveryAddList from "./deliveryAddList";
import FullLoader from "../../../Shared/FullLoader";

const Delivery = () => {
  const dispatch = useDispatch();
  const useDetails = useSelector(
    (state) => state.userDetails?.userProfile || {}
  );
  const productDetails = useSelector((state) => state.productDetails || {});
  const { prodDetails = {}, productRateDetails = {} } = productDetails;
  const { sku = "", productVariantMap = {} } = prodDetails;
  const { expectedDeliveryDate = "", rate = "" } = productRateDetails;
  const firstVariant = Object.keys(productVariantMap);
  const splitSkuwithVariant =
    firstVariant[0]?.split("_").slice(1).join("_") || "";

  const { contactDetails = [] } = useDetails;

  const [alertOpen, setAlertOpen] = useState({
    isAlert: false,
    type: "",
    msg: "",
  });
  const [selectedAddress, setSelectedAddress] = useState("");
  const [getRateLoading, setGetRateLoading] = useState(false);
  const [rateAPIError, setRateAPIError] = useState(false);

  const addressChangeHandler = (event) => {
    const {
      target: { value },
    } = event;

    setSelectedAddress(value);
    setGetRateLoading(true);
    const getSelectedAddress = contactDetails?.find(
      (address) => address.contactDetailId === value
    );
    const { address: { pinCode } = {} } = getSelectedAddress;
    if (getSelectedAddress && Object.keys(getSelectedAddress).length > 0) {
      dispatch(setDefaultAddress(getSelectedAddress));
      handleDeliveryRateAPI({
        sku,
        splitSkuwithVariant,
        selectedPincode: pinCode,
      });
    }
  };

  const handleDeliveryRateAPI = async ({
    sku,
    splitSkuwithVariant,
    selectedPincode,
  }) => {
    const responseData = await getDeliveryRate({
      sku,
      variantType: splitSkuwithVariant,
      pinCode: selectedPincode,
    });
    const { status = 100, data = "" } = responseData;
    if (status === 200) {
      setRateAPIError(false);
      const { isDeliverable = true } = data;
      if (isDeliverable) {
        dispatch(isPincodeAvailable(true));
        dispatch(getProductRateDetails(data));
        dispatch(setUpdatePincode(selectedPincode));
      }
      setGetRateLoading(false);
      setAlertOpen({
        isAlert: false,
        type: "",
        msg: "",
      });
    } else {
      setRateAPIError(true);
      setGetRateLoading(false);
      setAlertOpen({
        isAlert: true,
        type: "error",
        msg: "Something went wrong, try again!",
      });
    }
  };

  const handleRateAPI = () => {
    const getSelectedAddressPin = contactDetails?.find(
      (address) => address.contactDetailId === selectedAddress
    );
    const { address: { pinCode } = {} } = getSelectedAddressPin;
    handleDeliveryRateAPI({
      sku,
      splitSkuwithVariant,
      selectedPincode: pinCode,
    });
  };

  useEffect(() => {
    if (contactDetails?.length) {
      const defaultAddress = contactDetails?.findIndex(
        (address) => address?.default
      );
      if (defaultAddress > -1) {
        const defaultAddressId = defaultAddress?.contactDetailId || "";
        const { address: { pinCode } = {} } = defaultAddressId;
        setSelectedAddress(defaultAddressId);
        setGetRateLoading(true);
        handleDeliveryRateAPI({
          sku,
          splitSkuwithVariant,
          selectedPincode: pinCode,
        });
      } else {
        const getFirstAddress = contactDetails?.[0];
        const { address: { pinCode } = {} } = getFirstAddress;
        setSelectedAddress(getFirstAddress?.contactDetailId || "");
        if (pinCode) {
          dispatch(setDefaultAddress(getFirstAddress));
          setGetRateLoading(true);
          handleDeliveryRateAPI({
            sku,
            splitSkuwithVariant,
            selectedPincode: pinCode,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box direction={"column"} spacing={2} sx={{ maxWidth: "400px" }}>
        <Grid container spacing={1} direction={"column"} m={0}>
          <Grid xs={12} item>
            <Typography
              variant="body"
              color="text.secondary"
              fontWeight={"bold"}
              ml={0}
            >
              Deliver to
            </Typography>
          </Grid>
          <Grid xs={12} item>
            {!getRateLoading ? (
              <DeliveryAddList
                addressChangeHandler={addressChangeHandler}
                addressList={contactDetails}
                selectedAddress={selectedAddress}
                handleRatingRetry={handleRateAPI}
                rateAPIError={rateAPIError}
              />
            ) : (
              <>
                <FullLoader loaderOpenClose={getRateLoading} />
              </>
            )}
          </Grid>
          <Grid xs={12} item>
            {expectedDeliveryDate?.length > 0 && (
              <Typography variant="body" color={"black"}>
                Delivery in {`${expectedDeliveryDate} | ₹${rate}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      {alertOpen?.isAlert && (
        <CustomizedSnackbars
          text={alertOpen?.msg}
          isOpen={alertOpen?.isAlert}
          type={alertOpen?.type}
        />
      )}
    </React.Fragment>
  );
};

export default Delivery;
