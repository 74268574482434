import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import { Button, Divider, Paper, Typography } from "@mui/material";
import Constants from "../../../constants";
import { getAPIService } from "../../API";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails } from "../../../actions";
import OrderDetailsContainer from "./OrderDetailsContainer";
import { getDate } from "../../../utils";
import TrackPackage from "../../Shared/TrackPackage";

const OrderDetails = () => {
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const orderNumQuery = searchParams.get("orderId");
  const orderDetails = useSelector(
    (state) => state.orderDetails?.orderDetailData || {}
  );
  const {
    orderDate = "",
    orderNumber = "",
    orderReleaseList = [],
    shipToAddress = {},
  } = orderDetails;
  const { city = "", state = "", pinCode = "" } = shipToAddress;
  const shipmentEvents =
    (orderReleaseList?.length > 0 && orderReleaseList?.[0]?.shipmentEvents) ||
    [];
  useEffect(() => {
    const getOrderDetailsAPI = async () => {
      const URL = `${Constants.orders.getOrderDetails}?orderNumber=${orderNumQuery}`;
      getAPIService({ url: URL }).then((response) => {
        const { status = 100, data = {} } = response;
        dispatch(getOrderDetails(data));
        if (status === 200 && Object.keys(data).length > 0) {
        }
      });
    };
    getOrderDetailsAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNumQuery]);

  const handleDrawer = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Container maxWidth={"lg"}>
        <Grid
          container
          spacing={2}
          direction={"column"}
          sx={{ m: 0, mt: 2, mb: 2 }}
        >
          <Grid xs={12} md={12}>
            <Box sx={{ flexGrow: 1, m: 1 }}>
              <Grid container spacing={3} direction={"row"}>
                <Grid xs={12} md={12}>
                  <Typography
                    variant="h5"
                    fontWeight={"bold"}
                    color={"GrayText"}
                  >
                    Order Details
                  </Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <Typography
                    variant="body"
                    fontWeight={"bold"}
                    color={"GrayText"}
                  >
                    {`Ordered on ${getDate(orderDate)}`}
                  </Typography>
                  <Divider
                    orientation={"vertical"}
                    sx={{ mr: 1, ml: 1 }}
                    component={"span"}
                  />
                  <Typography
                    variant="body"
                    fontWeight={"bold"}
                    color={"GrayText"}
                  >
                    {`Order# ${orderNumber}`}
                  </Typography>
                </Grid>
                <Grid xs={4}>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={handleDrawer}
                    sx={{ color: "white" }}
                  >
                    Track Package
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  pb: 0,
                  pt: 0,
                },
              }}
            >
              <OrderDetailsContainer
                orderReleaseList={orderReleaseList}
                orderNumber={orderNumber}
              />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ flexGrow: 1, m: 1 }}>
              <Paper elevation={2} sx={{ border: "1px #D5D9D9 solid" }}>
                <Grid container spacing={3} direction={"row"} p={1}>
                  <Grid xs={6} md={4}>
                    <Grid sx={{ pt: 1, pb: 1 }}>
                      <Typography variant="body" fontWeight={"bold"}>
                        Shipping Address
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        variant="body"
                        component="div"
                        color="text.secondary"
                        fontWeight={500}
                      >
                        {`${city}, ${state}, ${pinCode}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <Grid sx={{ pt: 1, pb: 1 }}>
                      <Typography variant="body" fontWeight={"bold"}>
                        Payment Methods
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        variant="body"
                        component="div"
                        color="text.secondary"
                        fontWeight={500}
                      >
                        {`${"Online Payment"}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <Grid sx={{ pt: 1, pb: 1 }} xs={12}>
                      <Typography variant="body" fontWeight={"bold"}>
                        Order Summary
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Grid
                        container
                        spacing={1}
                        direction={"row"}
                        columns={12}
                      >
                        <Grid xs={6}>
                          <Typography>Items subtotal:</Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography>₹1234.22</Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography>Shipping:</Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography>₹12.22</Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography fontWeight={"bold"}>
                            Grand Total:
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography>₹1234.22</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
          {open && (
            <Grid>
              <TrackPackage
                open={open}
                setOpen={setOpen}
                shipmentEvents={shipmentEvents}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default OrderDetails;
