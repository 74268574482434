import React from "react";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";

const ProductSpec = (props) => {
  const { selectedColor = "" } = props;
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={handleClick}>
          <ListItemText primary="Product Details" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Stack
              direction="column"
              spacing={3}
              sx={{
                alignItems: "flex-start",
                pl: 2,
              }}
            >
              <Stack direction={"row"} spacing={2} width={"100%"}>
                <ListItemText primary="Pack of" />
                <ListItemText primary="1" />
              </Stack>
              <Stack direction={"row"} spacing={2} width={"100%"}>
                <ListItemText primary="Color" />
                <ListItemText primary={selectedColor || ""} />
              </Stack>
              <Stack direction={"row"} spacing={2} width={"100%"}>
                <ListItemText primary="Fit" />
                <ListItemText primary="Regular" />
              </Stack>
              <Stack direction={"row"} spacing={2} width={"100%"}>
                <ListItemText primary="Net Quantity" />
                <ListItemText primary="1" />
              </Stack>
            </Stack>
          </List>
        </Collapse>
      </List>
    </React.Fragment>
  );
};

export default ProductSpec;
