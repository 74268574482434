import styled from "styled-components";

const CatalogNavImgWrapper = styled.div`
  max-height: 64px;
  max-width: 64px;
  margin-bottom: 12px;
  height: 70px;
`;

export { CatalogNavImgWrapper };
