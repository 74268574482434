import React from "react";
import { Routes, Route } from "react-router-dom";
import { AppContainer, AppWrapper } from "./App.style";
import Container from "@mui/material/Container";
import HomePage from "../HomePage";
import ProductsList from "../ProductsList";
import ProductDetails from "../ProductDetails";
import { ErrorPage } from "../Shared/NoMatch";
import Checkout from "../Checkout";
import ViewCart from "../ViewCart";
import SearchProduct from "../SearchProduct";
import { SiteLogin, SiteSignup } from "../Login";
import HomeAppNavBar from "../HomeAppNavBar";
import MyProfile from "../MyProfile";
import OrderSuccessPage from "../OrderSuccessPage";
import OrdersHistory from "../OrdersHistory";
import OrderDetails from "../OrdersHistory/OrderDetails";
import BuyNow from "../BuyNow";
import Review from "../Reviews";
import CancelAndReturns from "../policies/CancelAndReturns";
import PrivacyPolicy from "../policies/PrivacyPolicy";
import FAQ from "../policies/FAQ";
import TermsAndConditions from "../policies/TermsAndConditions";
import ShipmentPolicy from "../policies/ShipmentPolicy";

function App() {
  return (
    <AppWrapper id="app-wrapper">
      <AppContainer id="app-container">
        <Container
          maxWidth={"xl"}
          sx={{ p: 0, mb: 6 }}
          className="app-container-wrapper"
        >
          <Routes>
            <Route path="/login" element={<SiteLogin />} />
            <Route path="/signup" element={<SiteSignup />} />
            <Route path="/" element={<HomeAppNavBar />}>
              <Route index element={<HomePage />} />
              <Route path="/account" element={<MyProfile />} />
              <Route path="/products/:catId" element={<ProductsList />} />
              <Route path="/pd/:skuid" element={<ProductDetails />} />
              <Route path="/search" element={<SearchProduct />} />
              <Route path="/viewcart" element={<ViewCart />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/buynow" element={<BuyNow />} />
              <Route path="/ordersuccess" element={<OrderSuccessPage />} />
              <Route path="/myaccount/orders" element={<OrdersHistory />} />
              <Route path="/product/review" element={<Review />} />
              {
                <Route
                  path="/myaccount/orderDetails"
                  element={<OrderDetails />}
                />
              }
              <Route
                path="/policies/returnpolicy"
                element={<CancelAndReturns />}
              />
              <Route
                path="/policies/privacypolicy"
                element={<PrivacyPolicy />}
              />
              <Route
                path="/policies/termsandconditions"
                element={<TermsAndConditions />}
              />
              <Route
                path="/policies/shippingpolicy"
                element={<ShipmentPolicy />}
              />
              <Route path="/policies/faq" element={<FAQ />} />
              <Route path="/*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </Container>
      </AppContainer>
    </AppWrapper>
  );
}

export default App;
