import axios from "axios";
import { chooseToken } from "../../utils";

export const getAPIService = async ({ url, headers }) => {
  try {
    const response_1 = await axios.get(url, { headers: { ...headers } });
    return response_1;
  } catch (error_2) {
    return error_2;
  }
};

export const postAPIService = async ({ url, data, headers = {} }) => {
  try {
    const response_1 = await axios.post(url, data, { headers: { ...headers } });
    return response_1;
  } catch (error_2) {
    return error_2;
  }
};

export const createOrderAPICall = async ({ url, data, headers = {} }) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("error", error);
      return error;
    }
  );

  try {
    const response_1 = await axios.post(url, data, { headers: { ...headers } });
    return response_1;
  } catch (error_2) {
    return error_2;
  }
};

export const deleteAPIService = async ({ url }) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    }
  );

  try {
    const response_1 = await axios.delete(url);
    return response_1;
  } catch (error_2) {
    return error_2;
  }
};

export const putAPIService = async ({ url, data, headers }) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    }
  );

  try {
    const resonse_1 = await axios.put(url, data, { headers: { ...headers } });
    return resonse_1;
  } catch (error) {
    return error;
  }
};

export const patchAPIService = async ({ url, data }) => {
  axios.interceptors.request.use(
    (config) => {
      if (config?.metaData) {
        config.metaData = { startTime: new Date() };
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (response?.config?.metaData) {
        response.config.metaData.endTime = new Date();
        response.config.metaData.duration =
          response.config.metaData.endTime - response.config.metaData.startTime;
      }
      return response;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  try {
    const response_1 = await axios.patch(url, data);
    return response_1;
  } catch (error_2) {
    return error_2;
  }
};

export const ATCAPIService = async ({ url, data, headers = {} }) => {
  try {
    const response_1 = await axios.post(url, data, { headers: { ...headers } });
    return response_1;
  } catch (error_2) {
    return error_2;
  }
};

export const getCartAPIService = async ({ url, pincode, headers }) => {
  const { userId = "" } = chooseToken();
  const params = {
    cartId: userId,
    pincode: pincode,
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let status = error?.response?.status;
      if (status === 401) {
        console.log("cart_error", error);
      }
      console.log("cart_error", error);
      return error;
    }
  );

  try {
    const response_1 = await axios.get(
      `${url}?cartId=${params?.cartId}&pincode=${params?.pincode}`,
      { headers: { ...headers } }
    );
    return response_1;
  } catch (error_2) {
    return error_2;
  }
};

export const initiateRazorPayOrder = async ({ url, data, optiondata }) => {
  try {
    const response_1 = await axios.post(url, data, {
      headers: { Authorization: `Basic ${optiondata}` },
    });
    return response_1;
  } catch (error_2) {
    return error_2;
  }
};
