import React from "react";
import Box from "@mui/material/Box";
import { WishListImageWrapper } from "./WishList.style";

const ImageContainer = (props) => {
  const { imageDetails: { imageUrl = "" } = {} } = props;

  return (
    <React.Fragment>
      <Box display="flex">
        <WishListImageWrapper>
          <img
            src={imageUrl}
            width={"120px"}
            height={"140px"}
            alt="wishlist_image"
          />
        </WishListImageWrapper>
      </Box>
    </React.Fragment>
  );
};

export default ImageContainer;
