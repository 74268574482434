import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import CatalogWithoutImg from "../Shared/CatalogList/CatalogWitoutImg";
import ProductListSkeleton from "../Skeletons/ProductListSkeleton";
import Constants from "../../constants";
import { getProductsPayload } from "../../utils";
import { postAPIService } from "../API";
import { getProductsList } from "../../actions";
import PaginationSection from "../ProductsList/PaginationSection";
import ProductsListFilters from "../ProductsList/ProductsListFilters";
import ProductsListView from "../ProductsList/ProductsListView";

const SearchProduct = () => {
  const selectedFilters = useSelector(
    (state) => state?.allProducts?.filters || {}
  );
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [itemsData, setItemsData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enablePagination, setEnablePagination] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const query = searchParams.get("q");

  const getProductsCall = async ({ URL, payload }) => {
    return postAPIService({ url: URL, data: payload })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
        setFilterLoader(false);
      });
  };

  const handleApplyFilters = async () => {
    const URL = Constants.Apis.getProductsList;
    setFilterLoader(true);
    const payload = getProductsPayload({ /* categoryId, */ selectedFilters });
    const productResponse = await getProductsCall({ URL, payload });
    const { productsList: { totalCount = 0 } = {} } =
      productResponse?.data || {};
    dispatch(getProductsList(productResponse.data));
    if (totalCount > 10) {
      setEnablePagination(true);
    }
    setFilterLoader(false);
  };

  const getSearchData = async (queryData) => {
    const URL = Constants.Apis.getProductsList;
    const categoryId = queryData;
    setLoading(true);
    const payload = getProductsPayload({
      categoryId,
      selectedFilters,
      searchType: "search",
    });
    const productResponse = await getProductsCall({ URL, payload });
    const { items = [], productsList: { totalCount = 0 } = {} } =
      productResponse?.data || {};
    if (!items?.length) {
      setItemsData(false);
    } else {
      dispatch(getProductsList(productResponse.data));
      setItemsData(true);
    }

    if (totalCount > 10) {
      setEnablePagination(true);
    }
    setLoading(false);
    setFilterLoader(false);
  };

  useEffect(() => {
    getSearchData(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <React.Fragment>
      <Grid container direction={"column"}>
        <Grid sx={{ width: "-webkit-fill-available" }}>
          <CatalogWithoutImg />
        </Grid>
        <Grid
          container
          spacing={2}
          direction={"row"}
          sx={{
            pt: 2,
            m: 0,
          }}
        >
          {loading ? (
            <ProductListSkeleton />
          ) : itemsData ? (
            <>
              <Grid xs={"none"} md={2} sx={{ pr: 1 }}>
                <ProductsListFilters handleApplyFilters={handleApplyFilters} />
              </Grid>
              <Grid xs={12} md={10}>
                {filterLoader ? <ProductListSkeleton /> : <ProductsListView />}
              </Grid>
              <Grid xs={12}>{enablePagination && <PaginationSection />}</Grid>
            </>
          ) : (
            <>
              <h4>No data</h4>
            </>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SearchProduct;
