import { Grid } from "@mui/material";
import React from "react";
import ImageContainer from "./ImageContainer";
import DescriptionContainer from "./DescriptionContainer";
import DeleteContainer from "./DeleteListItem";
import { Link } from "react-router-dom";

const WishListTile = (props) => {
  const { wishListItem = {} } = props;
  const { sku = "", product = {} } = wishListItem;
  const {
    imageUrl = {},
    brand = "",
    shortDescription = "",
    name = "",
    pdUrl = "",
    price = {},
  } = product;
  return (
    <React.Fragment>
      {Object.keys(wishListItem)?.length > 0 && (
        <Grid container direction={"row"}>
          <Grid xs={12} md={3} lg={2} sx={{ mb: 2 }}>
            <Link to={`${pdUrl}`}>
              <ImageContainer imageDetails={imageUrl} />
            </Link>
          </Grid>
          <Grid xs={12} md={6} lg={3}>
            <Link to={`${pdUrl}`} className="route-link-black">
              <DescriptionContainer
                brand={brand}
                title={name}
                price={price}
                description={shortDescription}
              />
            </Link>
          </Grid>
          <Grid xs={12} md={3}>
            <DeleteContainer sku={sku} />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default WishListTile;
