import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";

const CartItemDesc = (props) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const {
    desc = "",
    name = "",
    price = 0,
    brand = "",
    variantType = "",
    discountedPrice = 0,
    vendorName = "",
    sku = "",
  } = props;

  const VarSize = variantType?.length > 0 && variantType.split("_")[0];
  const discountPercentage = Math.floor(
    ((Number(price) - Number(discountedPrice)) / Number(price)) * 100
  );

  return (
    <React.Fragment>
      <Grid container sx={{ p: 2 }}>
        <Grid xs={12} sx={{ pb: 1 }}>
          <Typography
            variant="body"
            fontWeight={500}
          >{`Brand: ${brand}`}</Typography>
        </Grid>
        <Grid xs={12}>
          <Link to={`/pd/${sku}`} style={{ textDecoration: "none" }}>
            <Typography variant="body2">{`SKUID: #${sku}`}</Typography>
          </Link>
        </Grid>
        <Grid xs={12}>
          <Typography variant="body">{`${name} ${desc}`}</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography variant="body" color="text.secondary" fontWeight={500}>
            {`size: ${VarSize}`}
          </Typography>
        </Grid>
        <Grid xs={12} sx={{ pt: 2 }}>
          <Typography variant="body" color="text.secondary">
            {`Seller: ${vendorName}`}
          </Typography>
        </Grid>
        <Grid xs={12} md={matches ? 3 : 1.5} sx={{ pt: 2, pb: 2 }}>
          <Typography
            variant="body"
            color={"black"}
            fontWeight={500}
            gutterBottom
          >
            {`₹ ${discountedPrice}`}
          </Typography>
        </Grid>
        <Grid xs={12} md={6} sx={{ pt: 2, pb: 2 }}>
          <Typography
            variant="body"
            color={"GrayText"}
            gutterBottom
            sx={{ textDecoration: "line-through" }}
          >
            {` ₹ ${price}`}
          </Typography>
          <Typography variant="body" color={"green"} gutterBottom>
            {` ${discountPercentage} % off`}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default CartItemDesc;
