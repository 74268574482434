import React from "react";
import DeliveryDetailsSection from "../DeliveryDetailsSection";
import CartItemDetails from "../CartItemDetails";
import PlaceOrderCTA from "../PlaceOrderCTA";

const CartItemsList = (props) => {
  const { shipments = [] } = props;
  return (
    <React.Fragment>
      <DeliveryDetailsSection />
      <CartItemDetails shipments={shipments} />
      <PlaceOrderCTA />
    </React.Fragment>
  );
};

export default CartItemsList;
