import React from "react";
import Stack from "@mui/material/Stack";
import { CartImageWrapper } from "./CartItemImage.style";

const CartItemImage = (props) => {
  const {
    src = "https://res.cloudinary.com/dpxnddlks/image/upload/v1707938527/clan_pics/s2zbtgmeirsxhdwgttmu.jpg",
  } = props;

  return (
    <CartImageWrapper className="cart-item-image">
      <Stack direction="row" spacing={1} sx={{ padding: 2, maxHeight: 250 }}>
        <img src={src} alt="cart_item_img" height={"auto"} width={"100%"} />
      </Stack>
    </CartImageWrapper>
  );
};

export default CartItemImage;
