import React from "react";

export const ErrorPage = () => {
  return (
    <>
      <h2>404:</h2>
      <h2>Page Not Found</h2>
    </>
  );
};
