import { Container, Grid, Typography } from "@mui/material";
import React from "react";

const FAQ = () => {
  return (
    <React.Fragment>
      <Container maxWidth={"md"}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="h4">
              FAQ(frequent questions or concerns)
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" fontWeight={"bold"}>
              {`Why should I sell on clan.business ?`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body">
              {`*Increased Exposure: Selling on Clan.Business exposes your products or services to a wider audience. With a large user base actively searching for goods and services, you can reach potential customers who might not have found your business otherwise.`}
            </Typography>
            <ul>
              <li>
                {`*Enhanced Credibility: Being listed on Clan.Business lends credibility to your business. Customers trust established platforms, and by associating your brand with Clan.Business, you signal reliability and legitimacy, thus increasing trust among potential buyers.`}
              </li>
              <li>
                {`*Marketing Tools: Clan.Business provides various marketing tools and features to help you promote your products or services effectively. From sponsored listings to targeted advertising options, you have the resources to reach your target audience and drive sales.`}
              </li>
              <li>
                {`*Customer Engagement: Clan.Business facilitates direct communication between you and your customers. Engaging with customers through messaging, reviews, and feedback helps build relationships, address concerns promptly, and ultimately, foster loyalty.`}
              </li>
              <li>
                {`*Business Growth: Selling on Clan.Business can lead to significant business      
 growth. With increased visibility, credibility, and customer engagement, you're poised to attract more sales and expand your customer base, ultimately driving revenue and fostering long-term success.`}
              </li>
            </ul>
          </Grid>
          <Grid item>
            <Typography variant="body" fontWeight={"bold"}>
              {`How does Clan.Business work?`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body">
              {`Clan.Business works by allowing businesses to create profiles and list their products or services on the platform. Customers can then browse these listings, place orders, and interact with businesses directly through the platform. Clan.Business provides various features such as search filters, reviews, and messaging options to facilitate smooth transactions between businesses and customers.`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" fontWeight={"bold"}>
              {`What are the benefits of using Clan.Business?`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body">
              {`Using Clan.Business offers several benefits for businesses, including:`}
            </Typography>
            <ul>
              <li>
                {`Increased visibility: Clan.Business provides a platform for businesses to showcase their products or services to a larger audience, increasing their visibility and reach.`}
              </li>
              <li>
                {`Customer engagement: Businesses can interact with customers directly through Clan.Business, answering questions, addressing concerns, and building relationships.`}
              </li>
              <li>
                {`Marketing opportunities: Clan.Business offers various marketing tools and features to help businesses promote their products or services effectively.`}
              </li>
              <li>
                {`Sales growth: By reaching a broader audience and engaging with customers more efficiently, businesses can experience significant growth in sales and revenue.`}
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default FAQ;
