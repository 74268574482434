import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CatMenuWithoutImg from "./CatMenu/CatMenuWithoutImg";
import useMediaQuery from "@mui/material/useMediaQuery";

const CatalogWithoutImg = () => {
  const categories = useSelector((state) => state.categories || []);
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          textAlign: "center",
          overflow: matches ? "scroll" : "none",
          width: "-webkit-fill-available",
        }}
      >
        <Paper
          elevation={1}
          square
          sx={{
            display: "grid",
            gridAutoFlow: "column",
            justifyContent: "space-evenly",
            gridAutoColumns: "auto",
          }}
        >
          {categories?.length > 0 &&
            categories.map((element) => (
              <CatMenuWithoutImg element={element} key={element?.id} />
            ))}
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default CatalogWithoutImg;
