import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import Constants from "../../../constants";
import { getAPIService } from "../../API";
import { decodeUserToken } from "../../../utils";
import { getUserWishList } from "../../../actions";
import WishListTile from "./WishListContainer/WishListTile";

const WishList = () => {
  const dispatch = useDispatch();
  const userWishList = useSelector(
    (state) => state.userDetails?.userWishList || {}
  );
  const { Token = "" } = decodeUserToken();
  useEffect(() => {
    const URL = Constants.user.getWishList;
    const headers = {
      token: `${Token}`,
    };
    getAPIService({ url: URL, headers: { ...headers } }).then((response) => {
      const { status = 100, data = [] } = response;
      if (status === 200) {
        dispatch(getUserWishList(data));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Grid container direction={"row"} sx={{ p: 1 }}>
        {userWishList?.length > 0 &&
          userWishList?.map((wishListItem) => (
            <WishListTile wishListItem={wishListItem} />
          ))}
      </Grid>
    </React.Fragment>
  );
};

export default WishList;
