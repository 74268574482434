import React from "react";
import { useLocation } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const PaginationSection = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const curPage = parseInt(query.get("page") || "1", 10);
  const [page, setPage] = React.useState(curPage);
  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Pagination
          count={10}
          page={page}
          variant="outlined"
          color="primary"
          onChange={handleChange}
          sx={{ alignSelf: "center", padding: 4 }}
          size="large"
        />
      </Stack>
    </React.Fragment>
  );
};
export default PaginationSection;
