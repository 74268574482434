import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";

const CartItemDelivery = (props) => {
  const { expectedDelDate = "" } = props;
  return (
    <React.Fragment>
      <Grid container sx={{ p: 2 }}>
        <Typography variant="body">{`Delivery in ${expectedDelDate}`}</Typography>
      </Grid>
    </React.Fragment>
  );
};
export default CartItemDelivery;
