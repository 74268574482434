import * as React from "react";
import Box from "@mui/material/Box";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useSearchParams } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function SearchAppBar(props) {
  const { placeHolder = "" } = props;
  const [searchParams] = useSearchParams();
  const [searchVal, setSearchVal] = React.useState("");
  const navigate = useNavigate();
  const query = searchParams.get("q");
  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };
  const handleKeydown = (e) => {
    if (e.key === "Enter") {
      navigate(`/search?q=${searchVal}`);
    }
  };

  React.useEffect(() => {
    if (query === null && searchVal?.length) {
      setSearchVal("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <Box sx={{ flexGrow: 1, mr: 2 }}>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          sx={{ fontWeight: 500 }}
          placeholder={placeHolder || "Search…"}
          inputProps={{ "aria-label": "search" }}
          onChange={handleSearch}
          onKeyDown={handleKeydown}
          value={searchVal}
        />
      </Search>
    </Box>
  );
}
