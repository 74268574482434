import {
  ADD_TO_CART_ITEM,
  CLEAR_CART_ITEMS,
  GET_CART_LIST,
  UPDATE_CART_ITEMS,
} from "../actions";

const initialState = {
  AllCartData: [],
  updateCartData: [],
};

export const viewCartReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_CART_LIST:
      return {
        ...state,
        AllCartData: { ...payload },
      };
    case ADD_TO_CART_ITEM:
      return {
        ...state,
        AllCartData: { ...state?.cartItems, cartItems: [...payload] },
      };
    case UPDATE_CART_ITEMS:
      return {
        ...state,
        updateCartData: [...payload],
      };
    case CLEAR_CART_ITEMS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
