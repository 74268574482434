/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Header from "../Shared/Header";
import CatalogWithoutImg from "../Shared/CatalogList/CatalogWitoutImg";
import Login from "./Login";
import SignUp from "./SignUp";

export const SiteLogin = () => {
  return (
    <React.Fragment>
      <Grid container direction={"column"}>
        <Header />
        <CatalogWithoutImg />
        <Grid
          container
          spacing={2}
          direction={"row"}
          sx={{
            pt: 2,
            alignSelf: "center",
          }}
        >
          <Login />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const SiteSignup = () => {
  return (
    <React.Fragment>
      <Grid container direction={"column"}>
        <Header />
        <CatalogWithoutImg />
        <Grid
          container
          spacing={2}
          direction={"row"}
          sx={{
            pt: 2,
            alignSelf: "center",
          }}
        >
          <SignUp />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
