import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import QuantityInput from "../QtyInput";
import CartItemImage from "../CartItemImage";
import CartItemDesc from "../CartItemDesc";
import CartItemDelivery from "../CartItemDelivery";
import CartItemSaveLaterOrRemove from "../CartItemSaveLaterOrRemove";
import { Link } from "react-router-dom";

const SingleCartItem = (props) => {
  const {
    item = {},
    handleUpdateRemoveCart = () => {},
    vendorName = "",
    deliveryDateAndTime = "",
  } = props;

  const {
    imageUrl = {},
    itemCount = 0,
    name = "",
    sku = "",
    brand = "",
    variantType = "",
    itemPrice: { price = 0, discountedPrice = 0 } = {},
    shortDescription = "",
  } = item;

  const handleQty = (e) => {
    handleUpdateRemoveCart({ ...e, action: "updateItem" });
  };

  const removeItem = (e) => {
    handleUpdateRemoveCart({ ...e, action: "removeItem" });
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} direction={"row"} sx={{ pt: 2, pb: 2 }}>
        <Grid xs={3} md={3} sx={{ p: 0, textAlign: "-webkit-center" }}>
          <Link to={`/pd/${sku}`}>
            <CartItemImage src={imageUrl?.imageUrl || ""} />
          </Link>
        </Grid>
        <Grid xs={6} md={6} sx={{ p: 0 }}>
          <CartItemDesc
            desc={shortDescription}
            brand={brand}
            variantType={variantType}
            name={name}
            price={price || 0}
            sku={sku}
            vendorName={vendorName}
            discountedPrice={discountedPrice || 0}
          />
        </Grid>
        <Grid xs={3} md={3} sx={{ p: 0 }}>
          <CartItemDelivery expectedDelDate={deliveryDateAndTime} />
        </Grid>
        <Grid xs={3} md={3} sx={{ p: 0 }}>
          <QuantityInput
            handleQty={handleQty}
            qty={itemCount}
            skuID={sku}
            variantType={variantType}
          />
        </Grid>
        <Grid xs={6} md={6} sx={{ p: 0 }}>
          <CartItemSaveLaterOrRemove
            removeItem={removeItem}
            skuID={sku}
            variantType={variantType}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SingleCartItem;
