import React from "react";
import { CatalogNavImgWrapper } from "./catalogNavWrapper.style";
/* import JA101 from "../assets/CatalogNavImgs/JA101.jpg";
import Cloths from "../assets/CatalogNavImgs/Cloths&Shoes.jpg";
import offers from "../assets/CatalogNavImgs/offerzone.jpg";
import crafts from "../assets/CatalogNavImgs/craftSupplies.jpg";
import handmade from "../assets/CatalogNavImgs/HandMade.jpg";
import HomeLiving from "../assets/CatalogNavImgs/HomeAndLiving.jpg";
import gifts from "../assets/CatalogNavImgs/gifts.jpg";
import odop from "../assets/CatalogNavImgs/ODOP.jpg"; */

const HomeCatalogNavBar = (props) => {
  const { imgsrc = "" } = props;
  /* const getImgSrc = (src) => {
    switch (src) {
      case "JA101":
        return (
          <img
            src={JA101}
            alt={"nav-img"}
            height={"100%"}
            width={"100%"}
            style={{ borderRadius: "15px" }}
          />
        );
      case "CS201":
        return (
          <img
            src={Cloths}
            alt={"nav-img"}
            height={"100%"}
            width={"100%"}
            style={{ borderRadius: "15px" }}
          />
        );
      case "HL301":
        return (
          <img
            src={HomeLiving}
            alt={"nav-img"}
            height={"100%"}
            width={"100%"}
            style={{ borderRadius: "15px" }}
          />
        );
      case "CS501":
        return (
          <img
            src={crafts}
            alt={"nav-img"}
            height={"100%"}
            width={"100%"}
            style={{ borderRadius: "15px" }}
          />
        );
      case "GC401":
        return (
          <img
            src={gifts}
            alt={"nav-img"}
            height={"100%"}
            width={"100%"}
            style={{ borderRadius: "15px" }}
          />
        );
      case "HP501":
        return (
          <img
            src={handmade}
            alt={"nav-img"}
            height={"100%"}
            width={"100%"}
            style={{ borderRadius: "15px" }}
          />
        );
      case "ODOP6":
        return (
          <img
            src={odop}
            alt={"nav-img"}
            height={"100%"}
            width={"100%"}
            style={{ borderRadius: "15px" }}
          />
        );
      case "OZ701":
        return (
          <img
            src={offers}
            alt={"nav-img"}
            height={"100%"}
            width={"100%"}
            style={{ borderRadius: "15px" }}
          />
        );
      default:
        return "";
    }
  } */ return (
    <CatalogNavImgWrapper>
      <img
        src={imgsrc}
        alt={"nav-img"}
        height={"100%"}
        width={"100%"}
        style={{ borderRadius: "15px" }}
      />
    </CatalogNavImgWrapper>
  );
};
export default HomeCatalogNavBar;
