/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import CatalogWithoutImg from "../Shared/CatalogList/CatalogWitoutImg";
import ProductsListView from "./ProductsListView";
import ProductsListFilters from "./ProductsListFilters";
import PaginationSection from "./PaginationSection";
import constants from "../../constants";
import { postAPIService } from "../API";
import { getProductsPayload } from "../../utils";
import { useDispatch } from "react-redux";
import { clearProductList, getProductsList } from "../../actions";
import { useLocation } from "react-router-dom";
import ProductListSkeleton from "../Skeletons/ProductListSkeleton";
import { Container } from "@mui/material";
import Footer from "../Footer";

const ProductsList = () => {
  const selectedFilters = useSelector(
    (state) => state?.allProducts?.filters || {}
  );
  const [enablePagination, setEnablePagination] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const dispatch = useDispatch();
  const params = useLocation();
  const categoryId = params?.pathname?.split("/")[2] || "";

  const getProductsCall = async ({ URL, payload }) => {
    return postAPIService({ url: URL, data: payload })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
        setFilterLoader(false);
      });
  };

  const handleApplyFilters = async () => {
    const URL = constants.Apis.getProductsList;
    setFilterLoader(true);
    const payload = getProductsPayload({ categoryId, selectedFilters });
    const productResponse = await getProductsCall({ URL, payload });
    const { productsList: { totalCount = 0 } = {} } =
      productResponse?.data || {};
    dispatch(getProductsList(productResponse.data));
    if (totalCount > 10) {
      setEnablePagination(true);
    }
    setFilterLoader(false);
  };

  useEffect(() => {
    const fetchProductsList = async () => {
      const URL = constants.Apis.getProductsList;
      setLoading(true);
      const payload = getProductsPayload({ categoryId });
      const productResponse = await getProductsCall({ URL, payload });
      const { productsList: { totalCount = 0 } = {} } =
        productResponse?.data || {};
      dispatch(getProductsList(productResponse.data));
      if (totalCount > 10) {
        setEnablePagination(true);
      }
      setLoading(false);
    };

    dispatch(clearProductList());
    fetchProductsList();
  }, [categoryId]);

  return (
    <React.Fragment>
      <Grid container direction={"column"}>
        <Grid sx={{ width: "-webkit-fill-available" }}>
          <CatalogWithoutImg />
        </Grid>
        <Grid
          container
          spacing={2}
          direction={"row"}
          sx={{
            pt: 2,
            m: 0,
          }}
        >
          {loading ? (
            <ProductListSkeleton />
          ) : (
            <>
              <Grid xs={"none"} md={2} sx={{ pr: 1 }}>
                <ProductsListFilters handleApplyFilters={handleApplyFilters} />
              </Grid>
              <Grid xs={12} md={10} lg={10}>
                {filterLoader ? <ProductListSkeleton /> : <ProductsListView />}
              </Grid>
              <Grid xs={12}>{enablePagination && <PaginationSection />}</Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Container
        maxWidth={"xl"}
        sx={{ p: 0, pt: 2, mt: 2, bottom: 0, position: "relative" }}
      >
        <Footer />
      </Container>
    </React.Fragment>
  );
};
export default ProductsList;
