import * as React from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getAuthCredentails, verifyAuthCredentails } from "../../utils";
import { setAuthToken } from "../../actions";
import CustomizedSnackbars from "../Shared/SnackBar";

export default function SignUp() {
  const dispatch = useDispatch();
  const [requestNumber, setRequestNumber] = React.useState("");
  const [otpData, setOtpData] = React.useState("");
  const [alertOpen, setAlertOpen] = React.useState({
    isAlert: false,
    msg: "",
    type: "",
  });
  const [enableOTP, setEnableOTP] = React.useState(false);

  const handleRequestEmailOrPhone = (e) => {
    e.preventDefault();
    if (alertOpen?.isAlert) {
      setAlertOpen({ isAlert: false, msg: "", type: "" });
    }
    if (enableOTP) {
      setEnableOTP(false);
    }
    const value = e.target.value;
    setRequestNumber(value);
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    const opt = e.target.value;
    setOtpData(opt);
  };

  const submitVerifyOtp = async () => {
    const requestOptData = otpData;
    const requestEmailorMobile = requestNumber;

    const verifyOtpResponse = await verifyAuthCredentails({
      emailID: requestEmailorMobile,
      type: "signup",
      userOTP: requestOptData,
    });
    const { data = "", status = 100 } = verifyOtpResponse;
    if (status === 200) {
      localStorage.setItem("userToken", JSON.stringify(data));
      if (window !== undefined) {
        window.location.href = "/";
      }
    } else {
      setAlertOpen({
        isAlert: true,
        msg: `Something went wrong, try again`,
        type: "error",
      });
    }
  };

  const verifyEmailAddress = async () => {
    const mobileOrEmail = requestNumber;
    const regexForPhone = /^([+]\d{2})?\d{10}$/;
    const regexForEmail =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (
      mobileOrEmail.match(regexForPhone) ||
      mobileOrEmail.match(regexForEmail)
    ) {
      const validateEmail = await getAuthCredentails({
        emailID: mobileOrEmail,
        type: "signup",
      });
      const { data = "", response = "", status = 0 } = validateEmail;
      const { data: { status: errorStatus = 100, errorMessage = "" } = {} } =
        response;
      if (status === 200) {
        dispatch(setAuthToken(data));
        setAlertOpen({ isAlert: false, msg: "", type: "" });
        setEnableOTP(true);
      } else if (
        errorStatus === 400 &&
        errorMessage === "User already exists!"
      ) {
        setAlertOpen({
          isAlert: true,
          msg: `${errorMessage}, try login`,
          type: "error",
        });
      }
    } else {
      setAlertOpen({ isAlert: true, msg: "Invalid Email", type: "error" });
      console.log("not valid email");
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={1}
          direction={"column"}
          minWidth={350}
          maxWidth={350}
        >
          <Grid xs={12} textAlign={"center"}>
            <Typography
              component="h1"
              variant="h5"
              fontWeight={500}
              color={"primary"}
            >
              Sign up
            </Typography>
          </Grid>
          <Grid xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              size="small"
              id="mobileOrEmail"
              label="Enter Email"
              name="mobileOrEmail"
              autoComplete="number"
              onChange={handleRequestEmailOrPhone}
              autoFocus
            />
          </Grid>
          <Grid xs={12}>
            {enableOTP && (
              <TextField
                margin="normal"
                type="number"
                required
                size="small"
                fullWidth
                name="otp"
                label="Enter OTP"
                id="otp"
                onChange={handleVerifyOTP}
                disabled={!enableOTP}
                autoComplete="none"
              />
            )}
          </Grid>
          <Grid xs={12}>
            {!enableOTP && (
              <Button
                type="submit"
                id="OTPRequest"
                fullWidth
                size="small"
                name="OTPRequest"
                variant="contained"
                onClick={verifyEmailAddress}
                color="login"
                sx={{ color: "white" }}
              >
                Sign Up
              </Button>
            )}
            {enableOTP && (
              <Button
                type="submit"
                id="OTPRequest"
                fullWidth
                size="small"
                name="OTPRequest"
                variant="contained"
                onClick={submitVerifyOtp}
                color="login"
                sx={{ color: "white" }}
              >
                Verify
              </Button>
            )}
          </Grid>
          <Grid container spacing={2} direction={"row"}>
            <Grid xs={6}>
              <Link to="/login">{"login"}</Link>
            </Grid>
            {enableOTP && (
              <Grid xs={6}>
                <Link onClick={verifyEmailAddress}>{"Request Again? "}</Link>
              </Grid>
            )}
            <Grid xs={12}>
              {alertOpen?.isAlert && (
                <CustomizedSnackbars
                  text={alertOpen?.msg}
                  isOpen={alertOpen?.isAlert}
                  type={alertOpen?.type}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
