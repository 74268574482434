import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { ImagesWrap } from "./TileContainer.style";
import HomeAndLivingCarousel from "../HomeAndLivingCarousel";
import { Link } from "react-router-dom";

const TileContainer = ({ tileItems }) => {
  const { items = [], title = "" } = tileItems;
  return (
    <>
      {title === "Carousel" && (
        <Grid xs={12} md={12}>
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "center",
              mt: 1,
            }}
          >
            <Paper
              elevation={1}
              square
              sx={{
                display: "flex",
              }}
            >
              <Swiper
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {items?.length &&
                  items.map((imgSrc, idx) => (
                    <SwiperSlide key={idx}>
                      <Link
                        to={
                          imgSrc?.type === "product"
                            ? `/pd/${imgSrc?.id}`
                            : `/products/${imgSrc?.id}`
                        }
                      >
                        <ImagesWrap className="cat_img_Wrap">
                          <img src={imgSrc?.imageUrl} alt="top_cat" />
                        </ImagesWrap>
                      </Link>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Paper>
          </Box>
        </Grid>
      )}
      {title !== "Carousel" && (
        <Grid xs={12} md={6}>
          <Box
            sx={{
              flexGrow: 1,
              textAlign: "center",
              mt: 1,
            }}
          >
            <Paper
              elevation={1}
              square
              sx={{
                display: "flex",
              }}
            >
              <HomeAndLivingCarousel items={items} title={title} />
            </Paper>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default TileContainer;
