import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const CancelAndReturns = () => {
  return (
    <React.Fragment>
      <Container maxWidth={"md"}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="h4">
              Order Cancellation and Return Policy
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body">
              Customers may cancel an order at any time before it is dispatched.
              Once an order is out for delivery, cancellation is not possible,
              but the customer can choose to reject it at the doorstep. The
              cancellation time window varies depending on different categories,
              and once this specified time has passed, cancellation may not be
              allowed for free, and a cancellation fee may be charged. The
              details regarding the cancellation time window are provided on the
              product page or order confirmation page and are considered final.
              In the event of cancellation by the seller due to unforeseen
              circumstances, a full refund will be initiated for prepaid orders.
              Clan business pvt ltd reserves the right to accept the
              cancellation of any order. Additionally, clan.business retains the
              right to waive or modify the cancellation fee and time window from
              time to time.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "left", mt: 2 }}>
            <Typography variant="h5">Return Policy</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body">
              Returns are facilitated directly by respective sellers under this
              policy. This scheme offers options for exchange, replacement,
              and/or refund as provided by the sellers. Please note that not all
              products listed under a particular category may have the same
              returns policy. The returns/replacement policy outlined on the
              product page supersedes the general returns policy for all
              products. Customers are advised to refer to the applicable
              return/replacement policy on the product page for any exceptions
              to the general returns policy. Our return policy consists of three
              parts. Please carefully review all sections to understand the
              conditions and cases under which returns will be accepted.
            </Typography>
            <Typography variant="h5" sx={{ p: 2 }}>
              {" "}
              Part 1:
            </Typography>
            <Table sx={{ border: "1px solid" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>Category</TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    Returns Window, Actions Possible and Conditions(if any)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      Furniture Home: Pet Supplies & Rest of Home. (Except Home
                      décor, Furnishing, Home Improvement Tools, Household
                      Items)
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      9-10 days Refund or Replacement For products requiring
                      installation, returns shall be eligible only when such
                      products are installed by the brand & authorized
                      personnel. In order to help you resolve issues with your
                      product, we may troubleshoot your product either through
                      online tools, over the phone, and/or through an in-person
                      technical visit. If a defect is determined within the
                      Returns Window, a refund/replacement of the same product
                      will be provided at no additional cost. If no defect is
                      confirmed or the issue is not diagnosed within 10 days of
                      delivery or Installation wherever applicable, you will be
                      directed to a brand service center to resolve any
                      subsequent issues. In any case, only one replacement shall
                      be provided.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      Lifestyle: Watch, T-Shirt, Footwear, Sari, Short, Dress,
                      Kid’s (Capri, Shorts & Tops), Men’s (Ethnic Wear, Shirt,
                      Formals, Jeans, Clothing Accessory), Women’s (Ethnic Wear,
                      Fabric, Blouse, Jean, Skirt, Trousers, Bra), Bags,
                      Raincoat, Sunglass, Belt, Frame, Backpack, Suitcase,
                      Luggage, etc... Lifestyle: Jewelry, Footwear Accessories,
                      Travel Accessories, Watch Accessories, etc.. Lifestyle:
                      WinterWear(sweatshirt, jacket, sweater, cardigan,
                      kids_thermal, pullover, windcheater, track_suit,
                      thermal,shawl, track_top, glove, muffler, scarf, blazer,
                      uniform_sweatshirt, uniform_blazer, kids_muffler,
                      kids_mitten, shrug, poncho,uniform_sweater, cap,
                      waistcoat, leg_warmer,legging,elder_halloween_costume)
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      9-10 days Refund, Replacement or Exchange
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      Home: Home Improvement Tools, Household Items, Home décor,
                      Furnishing
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>11-12 days Refund or replacement</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      Books (All books) Sports Equipment (Racquet, ball,
                      support, gloves, bags etc.) Exercise & Fitness Equipment
                      (Home Gym combos, dumbbell etc.) Toys (Remote controlled
                      toys, Learning toys, Stuffed toys etc.) Stationary (Pens,
                      Diary notebooks, Calculators etc.) Musical Instruments
                      (Microphones & Accessories, Guitars, Violins etc.)
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      9 days Replacement only Free replacement will be provided
                      within 7 days if the product is delivered in
                      defective/damaged condition or different from the ordered
                      item.Please keep the product intact, with original
                      accessories, user manual and warranty cards in the
                      original packaging at the time of returning the product. 8
                      days Replacement only Free replacement will be provided
                      within 7 days if the product is delivered in
                      defective/damaged condition or different from the ordered
                      item. Please keep the product intact, with original
                      accessories, user manual and warranty cards in the
                      original packaging at the time of returning the product.
                      Non-Returnable - All Wind Instruments (Harmonicas, Flutes
                      etc.) This item is non-returnable due to hygiene and
                      personal wellness.In case these products are delivered in
                      damaged/defective condition or different from the ordered
                      item, we will provide a free replacement.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>No Questions Asked</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      9-10 days Refund or replacement This policy enables easy
                      product return requests for customers through the
                      Platform, subject to product validations at the time of
                      pick-up and fraud prevention mechanisms. This policy shall
                      be applicable only if the product was bought when this
                      policy was applicable to the product. If not, the policy
                      provided here shall apply to the order. It is clarified
                      that a customer may only be able to seek a one-time
                      replacement under this Policy, subject to the other terms
                      provided herein. Exceptions to this policy: Following
                      claims will be covered under the policy provided here and
                      through corresponding validation processes a. product
                      undelivered b. product/accessories missing c. wrong
                      product/accessories delivered
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>No Returns categories</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      Some products in the above categories are not returnable
                      due to their nature or other reasons. For all products,
                      the policy on the product page shall prevail.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Typography variant="h5" sx={{ p: 2 }}>
              {" "}
              Part 2:
            </Typography>
            <Table
              sx={{ border: "1px solid", mb: 2 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>Category</TableCell>
                  <TableCell sx={{ border: "1px solid" }}>Conditions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>Correct Product</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      IMEI/ name/ image/ brand/ serial number/ article number/
                      bar code should match and the MRP tag should be undetached
                      and clearly visible.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>Complete Product</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      All in-the-box accessories (like remote control, starter
                      kits, instruction manuals, chargers, headphones, etc.),
                      freebies and combos (if any) should be present.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>Unused Product</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      The product should be unused, unwashed, unsoiled, without
                      any stains and with non-tampered quality check
                      seals/return tags/warranty seals (wherever applicable).
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>Undamaged Product</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      The product should be undamaged and without any scratches,
                      dents, tears or holes.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>Undamaged Packaging</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>
                      The product’s original packaging/ box should be undamaged.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Grid item xs={12}>
              <Typography variant="body">
                {`The field executive will refuse to accept the return if any of the conditions outlined in our return policy are not met.`}
              </Typography>
              <Typography variant="body">
                {`For products eligible for a refund, the refund will be processed once the returned product is received by the seller.`}
              </Typography>
            </Grid>
            <Typography variant="h5" sx={{ p: 2 }}>
              {" "}
              Part 3: General Rules for a Successful Return
            </Typography>
            <Grid item xs={12}>
              <Typography variant="body">
                {`In certain cases where the seller is unable to process a replacement for any reason, a refund will be provided.`}
              </Typography>
              <ul>
                <li>
                  {`If a product accessory is missing, damaged, or defective, the seller may choose to either replace the accessory or issue an eGV (electronic gift voucher) equivalent to the accessory's price, at the seller's discretion.`}
                </li>
                <li>
                  {`During open box deliveries, if you receive a different or damaged product upon acceptance, you will be refunded (spot refunds for cash-on-delivery orders). Once you accept an open box delivery, return requests will not be processed, except for manufacturing defects. Category-specific replacement/return conditions will apply in such cases.`}
                </li>
                <li>
                  {`For products with installation provided by clan.business  service partners, please refrain from opening the product packaging yourself. Authorized personnel will assist in unboxing and installation.`}
                </li>
                <li>
                  {`For Furniture, authorized service personnel will inspect product-related issues (free of charge) and attempt to resolve them by replacing faulty/defective parts. Full replacement will only be provided if the service personnel determine that replacing the faulty/defective part will not resolve the issue.`}
                </li>
              </ul>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {`Clan.business reserves the right to limit the number of returns per order unit after evaluation of the product/order defect by our authorized representative.`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default CancelAndReturns;
