import styled from "styled-components";

const HomeAndLivingWrapper = styled.div`
  width: -webkit-fill-available;
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    max-width: 320px;
    margin-right: 0px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper {
    width: 100%;
    height: 280px;
    margin: auto auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #4eb7cf;
  }
`;

const HALImageWrap = styled.div`
  width: fit-content;
`;

export { HomeAndLivingWrapper, HALImageWrap };
