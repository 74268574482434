const baseURL = "https://catalog-test.up.railway.app";
const AuthURL = "https://account-n-session-test.up.railway.app";
const cartURL = "https://cart-n-checkout-test.up.railway.app";
const orderServiceURL = "https://order-service-test.up.railway.app";

// const RazorPayBase = "https://api.razorpay.com/v1";
const Constants = {
  HomePage: {
    getHomeMenuItems: `${baseURL}/category/all`,
    getHomePageTileData: `${baseURL}/content`,
  },
  Apis: {
    GetCategories: `${baseURL}/category`,
    getProductsList: `${baseURL}/product`,
    getProductDetails: `${baseURL}/product/detail`,
  },
  cart: {
    addToCart: `${cartURL}/cart/addToCart`,
    getCart: `${cartURL}/cart`,
    clearCart: `${cartURL}/cart/clearCart`,
    buyNow: `${cartURL}/cart/checkout`,
    getRate: `${cartURL}/logistics/rate`,
  },
  Auth: {
    getToken: `${AuthURL}/auth/sendOTP`,
    verifyToken: `${AuthURL}/auth/verifyOTP`,
    guestToken: `${AuthURL}/auth/generate_token?isGuestUser=true`,
  },
  user: {
    getUserDetails: `${AuthURL}/user`,
    editUserDetails: `${AuthURL}/user/edit`,
    AddOrRemoveWishList: `${AuthURL}/activity/wishList/addOrRemove`,
    getWishList: `${AuthURL}/activity/wishList?limit=20`,
    editAddressDetails: `${AuthURL}/contact/edit`,
    AddAddressDetails: `${AuthURL}/contact/add`,
    deleteAddressDetails: `${AuthURL}/contact/delete`,
  },
  orders: {
    create: `${cartURL}/order/create`,
    getOrdersHistory: `${orderServiceURL}/order/service`,
    getOrderDetails: `${orderServiceURL}/order/service/detail`,
    cancelOrder: `${orderServiceURL}/order/service/cancel`,
  },
  payment: {
    createOrder: `${cartURL}/razorpay/order`,
  },
  reviews: {
    AddUserReview: `${AuthURL}/review/add`,
    getReviewByIdAndSKU: `${AuthURL}/review`,
    PDPReview: `${AuthURL}/review/product`,
    PDPReviewCount: `${AuthURL}/review/product/count`,
  },
};

export default Constants;
