import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, TextField, Typography } from "@mui/material";
import Constants from "../../../constants";
import { decodeUserToken } from "../../../utils";
import { getAPIService, postAPIService } from "../../API";
import DrawerComponent from "../../Shared/DrawerComponent";
import HoverRating from "../../Reviews/rating";
import CustomizedSnackbars from "../../Shared/SnackBar";
import { Link } from "react-router-dom";

const DetailSingleItem = ({
  itemDetails = {},
  status = "",
  orderNumber = "",
}) => {
  const {
    brand = "",
    imageUrl = "",
    lineRefId = "",
    name = "",
    sku = "",
    price: { discountedPrice = "" } = {},
  } = itemDetails;
  const { userId = "" } = decodeUserToken();
  const [openReviewDrawer, setOpenReviewDrawer] = useState(false);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const [alertOpen, setAlertOpen] = useState({
    isAlert: false,
    msg: "",
    type: "",
  });

  const [openCancelDrawer, setOpenCancelDrawer] = useState(false);

  const handleComment = (e) => {
    const value = e.target.value;
    setComment(value);
  };

  const handleCancelOrder = async () => {
    const URL = Constants.orders.cancelOrder;
    const payload = {
      userId,
      orderNumber,
      lineId: lineRefId,
    };
    const cancelResponse = await postAPIService({ url: URL, data: payload });
    const { status = 100 } = cancelResponse;
    if (status === 200) {
      setOpenCancelDrawer(false);
      setAlertOpen({
        isAlert: true,
        msg: "Order cancelled, your amount will get refund soon",
        type: "success",
      });
    } else {
      setAlertOpen({
        isAlert: false,
        msg: "Something went wrong, please try again",
        type: "error",
      });
    }
  };

  const reviewProduct = () => {
    setOpenReviewDrawer(true);
  };

  const cancelItem = () => {
    setOpenCancelDrawer(true);
  };

  const SubmitProductReview = async () => {
    const URL = `${Constants.reviews.AddUserReview}`;
    const payload = {
      userId,
      rating: rating,
      comment: comment,
      sku: sku,
    };
    const response = await postAPIService({ url: URL, data: payload });
    const { status = 100 } = response;
    if (status === 200) {
      // setOrderDetails(data);
      setAlertOpen({
        isAlert: true,
        msg: "Rating Updated Successfully.",
        type: "success",
      });
      setTimeout(() => {
        setOpenReviewDrawer(false);
      }, 1000);
    } else {
      setAlertOpen({
        isAlert: true,
        msg: "Something went wrong, please try again.",
        type: "error",
      });
      setTimeout(() => {
        setOpenReviewDrawer(false);
      }, 1000);
    }
  };

  const bodyContainer = () => {
    return (
      <React.Fragment>
        <Grid container direction={"row"} marginTop={2}>
          <Grid xs={3}>
            <img src={imageUrl} alt="product_url" width={80} height={70} />
          </Grid>
          <Grid xs={8}>
            <Typography variant="body">{name}</Typography>
          </Grid>
          <Grid xs={11} sx={{ pt: 3, pb: 1 }}>
            <Typography variant="body" fontWeight={500}>
              {"OverAll Rating:"}
            </Typography>
          </Grid>
          <Grid xs={11} sx={{ pt: 1, pb: 2 }}>
            <HoverRating value={rating} setValue={setRating} />
          </Grid>
          <Grid xs={11} sx={{ mt: 2 }}>
            <TextField
              multiline
              rows={3}
              placeholder="what did you like or dislike? what did you use this product for?"
              fullWidth
              value={comment}
              onChange={handleComment}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const footerContainer = () => {
    return (
      <React.Fragment>
        <Grid container spacing={2} direction={"column"}>
          <Grid item xs={11}>
            <Button
              variant="contained"
              fullWidth
              sx={{ color: "white" }}
              onClick={SubmitProductReview}
            >
              Submit Review
            </Button>
          </Grid>
          <Grid item xs={11}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setOpenReviewDrawer(false)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const CancelBodyContainer = () => {
    return (
      <React.Fragment>
        <Grid container spacing={3} direction={"row"} sx={{ mt: 2 }}>
          <Grid xs={3}>
            <img src={imageUrl} alt="product_url" width={80} height={70} />
          </Grid>
          <Grid xs={8}>
            <Typography variant="body">{name}</Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const cancelFooterContainer = () => {
    return (
      <React.Fragment>
        <Grid container spacing={2} direction={"column"}>
          <Grid item xs={11}>
            <Button
              variant="contained"
              fullWidth
              sx={{ color: "white" }}
              onClick={handleCancelOrder}
            >
              Confirm
            </Button>
          </Grid>
          <Grid item xs={11}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setOpenCancelDrawer(false)}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const getHistoryRating = async () => {
    const URL = `${Constants.reviews.getReviewByIdAndSKU}?userId=${userId}&sku=${sku}`;
    const response = await getAPIService({ url: URL });
    const { status = 0, data = {} } = response;
    const { rating = "", comment = "" } = data;
    if (status === 200) {
      setRating(rating);
      setComment(comment);
    }
  };

  useEffect(() => {
    getHistoryRating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={3} sx={{ mb: 2, mt: 1 }}>
        <Grid xs={2}>
          <Link to={`/pd/${sku}`}>
            <img src={imageUrl} alt="delivery_img" height={100} width={100} />
          </Link>
        </Grid>
        <Grid xs={6}>
          <Link to={`/pd/${sku}`} className="route-link">
            <Typography variant="body" component="div" fontWeight={500} pb={1}>
              {`${brand}`}
            </Typography>
            <Typography variant="body" component="div">
              {`${name}`}
            </Typography>
          </Link>
          <Typography variant="body" component="div">
            {`₹${discountedPrice}`}
          </Typography>
        </Grid>
        <Grid xs={3}>
          {status !== "Delivered" && (
            <Grid
              container
              spacing={2}
              direction={"column"}
              gap={3}
              sx={{ mb: 3 }}
            >
              <Button variant="outlined" onClick={cancelItem}>
                Cancel
              </Button>
            </Grid>
          )}
          <Grid container spacing={2} direction={"column"} gap={2}>
            <Button variant="outlined" onClick={reviewProduct}>
              Write a product review
            </Button>
          </Grid>
        </Grid>
        {openReviewDrawer && (
          <Grid item>
            <DrawerComponent
              isOpen={openReviewDrawer}
              setIsOpen={() => setOpenReviewDrawer(false)}
              title={"Review Product"}
              body={bodyContainer()}
              footer={footerContainer()}
            />
          </Grid>
        )}
        {openCancelDrawer && (
          <Grid item>
            <DrawerComponent
              isOpen={openCancelDrawer}
              setIsOpen={() => setOpenCancelDrawer(false)}
              title={"Cancel Item"}
              body={CancelBodyContainer()}
              footer={cancelFooterContainer()}
            />
          </Grid>
        )}
        <Grid item>
          {alertOpen.isAlert && (
            <CustomizedSnackbars
              text={alertOpen?.msg}
              isOpen={alertOpen?.isAlert}
              type={alertOpen?.type}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default DetailSingleItem;
