import React from "react";
import { useDispatch } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { addFilter, removeFilter } from "../../../actions";

export const CheckBoxFormGroup = (props) => {
  const dispatch = useDispatch();
  const { facetValues = [], title = "" } = props;
  const handleFacetChange = (e) => {
    if (e.target.checked) {
      const [id, value] = e?.target?.id?.split(":");
      dispatch(addFilter({ id, value }));
    } else if (!e.target.checked) {
      const [id, value] = e?.target?.id?.split(":");
      dispatch(removeFilter({ id, value }));
    }
  };
  return (
    <React.Fragment>
      <FormGroup>
        {facetValues?.length > 0 &&
          facetValues?.map((facetValue) => (
            <FormControlLabel
              key={facetValue?.name}
              control={
                <Checkbox
                  id={`${title}:${facetValue?.name}`}
                  onChange={handleFacetChange}
                />
              }
              label={title === "color" ? facetValue?.name : facetValue?.value}
            />
          ))}
      </FormGroup>
    </React.Fragment>
  );
};
