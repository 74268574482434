import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import ProductListDetails from "./ProductListDetails";
import useMediaQuery from "@mui/material/useMediaQuery";

const ProductsListView = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const productsList = useSelector(
    (state) => state?.allProducts?.productsList || {}
  );

  const { items = [] } = productsList || {};

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Paper variant="outlined" square>
          <Grid
            container
            spacing={2}
            sx={{ p: 1, flexGrow: 1, justifyContent: "flex-start" }}
            columns={matches ? 3 : 4}
          >
            {items?.length > 0 && <ProductListDetails listItems={items} />}
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default ProductsListView;
