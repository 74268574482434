import React from "react";
import Button from "@mui/material/Button";
import { AddToCartWrapper } from "./Addtocart.style";
import FlashOnOutlinedIcon from "@mui/icons-material/FlashOnOutlined";

const BuyNowCTA = ({
  handleBuyNowCTA = () => {},
  isDeliverable = true,
  checkPinCodeAvailable,
}) => {
  return (
    <AddToCartWrapper>
      <Button
        variant="contained"
        size="large"
        fullWidth
        color="buynow"
        onClick={handleBuyNowCTA}
        disabled={!isDeliverable || !checkPinCodeAvailable}
        sx={{ color: "white", fontSize: "1rem", columnGap: "3%" }}
      >
        <FlashOnOutlinedIcon />
        {"Buy Now"}
      </Button>
    </AddToCartWrapper>
  );
};

export default BuyNowCTA;
