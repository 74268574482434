import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export default function ProductDetailsSkeleton() {
  return (
    <Grid container wrap="nowrap" sx={{ alignSelf: "center" }} spacing={2}>
      <Grid item xs={0} md={5} sx={{ mr: 2 }}>
        {Array.from(new Array(1)).map((item, index) => (
          <Box key={index} sx={{ marginRight: 0.5, my: 5 }}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              width={"80%"}
              height={160}
            />
            {/* For other variants, adjust the size with `width` and `height` */}
            <Skeleton variant="rectangular" width={"80%"} height={60} />
            <Skeleton variant="rounded" width={"80%"} height={60} />
          </Box>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "inline-flex",
          flexFlow: "wrap",
          flexDirection: "column",
        }}
      >
        {Array.from(new Array(2)).map((item, index) => (
          <Box key={index} sx={{ width: "80%", marginRight: 0.5, my: 5 }}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}
            <Skeleton variant="rectangular" height={60} />
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
