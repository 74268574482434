import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Constants from "../../constants";
import { getAPIService } from "../API";
import { decodeUserToken } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getMyOrdersHistory } from "../../actions";
import OrdersList from "./OrdersList";
import { Button, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import SearchBar from "./shared/SearchBar";
import OHSkeleton from "../Skeletons/OHSkeleton";

const OrdersHistory = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const OrdersHistory = useSelector(
    (state) => state.ordersHistory?.ordersList || []
  );
  const { Token = "", userId = "" } = decodeUserToken();

  const getOHData = async () => {
    setLoading(true);
    setError(false);
    const URL = `${Constants.orders.getOrdersHistory}?userId=${userId}&pageSize=20&offset=0`;
    const header = { Authorization: `Bearer ${Token}` };

    await getAPIService({ url: URL, headers: { ...header } }).then(
      (response) => {
        const { status = 100, data: { orders = [] } = {} } = response;
        if (status === 200) {
          dispatch(getMyOrdersHistory(orders));
          setLoading(false);
        } else {
          setLoading(false);
          setError(true);
        }
      }
    );
  };

  const triggerOH = () => {
    getOHData();
  };

  useEffect(() => {
    getOHData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth={"lg"}>
        {loading && <OHSkeleton />}
        {!loading && error && (
          <Grid
            container
            spacing={2}
            direction={"column"}
            sx={{ m: 0, mt: 2, mb: 2 }}
            textAlign={"center"}
          >
            <Grid>
              <Typography variant="body" color="error">
                Something went wrong, please try again!
              </Typography>
            </Grid>
            <Grid>
              <Button onClick={triggerOH} color="error" variant="contained">
                Try Again
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          spacing={2}
          direction={"column"}
          sx={{ m: 0, mt: 2, mb: 2 }}
        >
          {!loading && OrdersHistory?.length > 0 && (
            <>
              <Grid xs={12} md={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3} direction={"row"}>
                    <Grid xs={12} md={6}>
                      <Typography
                        variant="h5"
                        fontWeight={"bold"}
                        color={"GrayText"}
                      >
                        Your Orders
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={4} sx={{ pr: 0 }}>
                      <Grid>
                        <SearchBar />
                      </Grid>
                    </Grid>
                    <Grid xs={12} md={2} sx={{ pl: 0 }}>
                      <Button
                        variant="contained"
                        sx={{ color: "white" }}
                        fullWidth
                      >
                        Search Orders
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    "& > :not(style)": {
                      m: 1,
                      width: "100%",
                      pb: 0,
                      pt: 0,
                    },
                  }}
                >
                  {OrdersHistory?.map((orderList) => (
                    <OrdersList orderList={orderList} />
                  ))}
                </Box>
              </Grid>
            </>
          )}
          {!loading && OrdersHistory?.length === 0 && !error && (
            <Grid xs={12} md={12} textAlign={"center"}>
              <Typography variant="h6">
                Order History Empty, place some orders
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default OrdersHistory;
