import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AccordianSection from "./AccordianSection";

const ProductsListFilters = (props) => {
  const { handleApplyFilters } = props;
  const [enableApplyFilter, setEnableApplyFilter] = useState(false);

  const productsList = useSelector(
    (state) => state?.allProducts?.productsList || {}
  );

  const selectedFilters = useSelector(
    (state) => state?.allProducts?.filters || {}
  );

  const { filters = [] } = productsList || {};
  useEffect(() => {
    let filterKeys = Object.keys(selectedFilters);
    let enabledCTA = filterKeys?.some((key) => {
      return selectedFilters[key]?.length > 0;
    });
    if (enabledCTA) {
      setEnableApplyFilter(true);
    } else {
      setEnableApplyFilter(false);
    }
  }, [selectedFilters]);

  return (
    <React.Fragment>
      <Grid container direction={"column"}>
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Grid>
            <Typography variant="h6" color="text.secondary">
              Filters
            </Typography>
          </Grid>
          <Grid sx={{ textAlign: "end", p: 1 }}>
            {enableApplyFilter && (
              <Button
                variant="contained"
                size="medium"
                sx={{ color: "white" }}
                onClick={handleApplyFilters}
              >
                Apply
              </Button>
            )}
          </Grid>
          <Divider variant="fullWidth" sx={{ opacity: 5 }} />
        </Box>
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <Paper variant="contained" square>
            {filters?.length > 0 &&
              filters.map((filterItem, idx) => (
                <Grid container key={idx}>
                  <AccordianSection filterItem={filterItem} />
                </Grid>
              ))}
          </Paper>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default ProductsListFilters;
