import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import CSSBaseLine from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { store } from "./store";
import App from "./components/App";
import theme from "./theme";
import "./global.css";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

if (window) {
  window.store = store;
}

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <CSSBaseLine />
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
);
