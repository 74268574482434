import React from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

const DescriptionContainer = (props) => {
  const { brand = "", description = "", price = {} } = props;
  const { price: itemPrice = 0, discountedPrice = 0 } = price;

  const discount = Math.floor(
    ((Number(itemPrice) - Number(discountedPrice)) / Number(itemPrice)) * 100
  );

  return (
    <React.Fragment>
      <Box display={"flex"}>
        <Grid container direction={"column"} textAlign={"left"}>
          <Grid>
            <Typography variant="h6" fontWeight={500}>{`${brand}`}</Typography>
          </Grid>
          <Grid>
            <Typography
              variant="body"
              fontWeight={400}
            >{`${description}`}</Typography>
          </Grid>
          <Grid>
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: "center", pt: 3, pb: 3 }}
            >
              <Typography
                variant="body2"
                color="black"
                fontWeight={500}
                fontSize={25}
              >
                {`₹ ${discountedPrice}`}
              </Typography>
              <Typography
                variant="body2"
                color="black"
                fontSize={15}
                sx={{ textDecoration: "line-through" }}
              >
                {`₹ ${itemPrice}`}
              </Typography>
              <Typography variant="body" color={"green"} gutterBottom>
                {` ${discount} % off`}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default DescriptionContainer;
