import React from "react";
import ManageAddress from "./ManageAddress";
import ProfileInfo from "./ProfileInfo";
import WishList from "./Wishlist";
import SavedCards from "./SavedCards";

const AccountsBody = ({ text = "" }) => {
  switch (text) {
    case "address":
      return <ManageAddress />;
    case "wishlist":
      return <WishList />;
    case "carddetails":
      return <SavedCards />;
    default:
      return <ProfileInfo />;
  }
};

export default AccountsBody;
