import React, { useEffect, useState } from "react";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CartItem from "../../ViewCart/shared/CartItem";
import Divider from "@mui/material/Divider";
import { getOrderPayload, getPaymentPayload } from "./checkoutUtil";
import { createOrderAPICall, getCartAPIService } from "../../API";
import Constants from "../../../constants";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { decodeUserToken, getPaymentAuthHeader } from "../../../utils";
import { clearCartItems, getCartList } from "../../../actions";

const CheckoutSummary = (props) => {
  const getPincode = localStorage.getItem("pincode");
  const parsePincode = JSON.parse(getPincode) || "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [paymentFailure, setPaymentFailure] = useState("");
  const { currentStep = {} } = props;
  const { step = 0 } = currentStep;

  const AllCartItems = useSelector(
    (state) => state.AllCartItems?.AllCartData || []
  );

  const userProfile = useSelector((state) => state.userDetails || {});
  const { defaultAddress = {}, isMergeCart = false } = userProfile;
  /*  const shipmentAddress = useSelector(
    (state) => state.userDetails?.defaultAddress || {}
  ); */

  const { address: { pinCode: selectedPincode = "" } = {} } = defaultAddress;
  const { shipments = [], cartPriceDetail: { totalAmount = 0 } = {} } =
    AllCartItems;

  const handleSuccessPayment = async (e) => {
    const URL = Constants?.orders?.create;
    const placeOrderPayload = getOrderPayload({
      defaultAddress,
      AllCartItems,
    });
    placeOrderPayload.payments = [
      {
        ...e,
        chargedAmount: totalAmount || "32506",
        transactionNumber: e?.razorpay_payment_id || "xxx",
        paymentMethod: "prepaid",
      },
    ];

    const paymentHeader = {
      paymentAuthHeader: `${getPaymentAuthHeader()}`,
    };
    createOrderAPICall({
      url: URL,
      data: placeOrderPayload,
      headers: paymentHeader,
    }).then((response) => {
      const { status = 10 } = response;
      if (status === 200) {
        dispatch(clearCartItems());
        navigate("/ordersuccess");
      }
    });
  };

  const handlePlaceOrder = (e) => {
    const URL = Constants?.payment?.createOrder;
    const paymentData = {
      amount: Math.floor(totalAmount) * 100,
      currency: "INR",
      receipt: uuidv4()?.substring(0, 16),
    };
    Axios.post(
      URL,
      {
        ...paymentData,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${getPaymentAuthHeader()}`,
        },
      }
    ).then((response) => {
      const { status = 100, data = "" } = response;
      if (status === 200) {
        const paymentInitiatePayload = getPaymentPayload(data, defaultAddress);
        paymentInitiatePayload.handler = handleSuccessPayment;
        var rzp1 = new window.Razorpay(paymentInitiatePayload);
        rzp1.on("payment.failed", function (response) {
          /* const { reason = "", description = "" } = response?.error;
          setPaymentFailure(`${reason} :${description}`); */
          console.log("PaymentFailure", response);
        });
        rzp1.open();
        e.preventDefault();
      }
    });
  };

  const mergeCartResponse = async () => {
    setIsLoading(true);
    setIsError(false);
    const URL = Constants?.cart?.getCart;
    // const { userId: guestId = "" } = decodeGuestToken();
    const { Token: loggedInUserToken = "" } = decodeUserToken();
    const token = `Bearer ${loggedInUserToken}`;
    const pincode = selectedPincode || parsePincode;
    let headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    /* if (isMergeCart) {
      headers = {
        ...headers,
        guestCartId: guestId,
      };
    } */
    const responseData = await getCartAPIService({
      url: URL,
      pincode: pincode,
      headers,
    });
    const {
      status = 100,
      data: { shipments = [] } = {},
      /* error = "",
      errorMessage = "", */
    } = responseData || {};

    if (status === 200 && shipments?.length) {
      dispatch(getCartList(responseData?.data));
      setIsLoading(false);
      setIsError(false);
    } else {
      setIsLoading(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (isMergeCart) {
      //mergecart need to defile when guest login
      mergeCartResponse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMergeCart]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            p: 2,
            pb: 0,
            pt: 0,
          },
        }}
      >
        {step !== 3 && (
          <Paper elevation={2}>
            <Grid container direction={"row"} sx={{ p: 1 }}>
              <Grid xs={10} md={10} sx={{ alignSelf: "center" }}>
                <Typography variant="body" component="div" fontWeight={500}>
                  ORDER SUMMARY
                </Typography>
              </Grid>
              {/* <Grid xs={2} md={2} sx={{ alignSelf: "center" }}>
                <Button variant="outlined" onClick={() => stepAction(3)}>
                  Change
                </Button>
              </Grid> */}
            </Grid>
          </Paper>
        )}
        {step === 3 && (
          <>
            <Paper elevation={2}>
              <Grid container direction={"row"}>
                <Grid xs={12} md={12}>
                  <Grid>
                    <Typography variant="body" component="div" fontWeight={500}>
                      ORDERS SUMMARY
                    </Typography>
                  </Grid>
                  {isLoading && (
                    <Grid container direction={"row"}>
                      <Grid xs={12} md={12} textAlign={"center"}>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  )}
                  {!isLoading && isError && (
                    <Grid container direction={"row"}>
                      <Grid xs={12} md={12} textAlign={"center"}>
                        <Grid>
                          <Typography
                            variant="body"
                            component="div"
                            fontWeight={500}
                            color={"error"}
                          >
                            Something went wrong, try again
                          </Typography>
                        </Grid>
                        <Grid sx={{ p: 2 }}>
                          <Button
                            variant="outlined"
                            color={"error"}
                            onClick={mergeCartResponse}
                          >
                            Try Again
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {shipments?.length > 0 && !isLoading && !isError && (
                    <Grid container>
                      {shipments?.map((cartItem) => (
                        <>
                          <CartItem cartItem={cartItem} />
                          <Divider />
                        </>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
            {shipments?.length > 0 && !isLoading && !isError && (
              <Paper elevation={1}>
                <Grid container direction={"row"} sx={{ p: 1 }}>
                  <Grid xs={9} md={9} sx={{ alignSelf: "center" }}>
                    {/* <Typography variant="body" component="div" fontWeight={500}>
                    Order Confirmation email will be sent to
                    Srikanthkongara91@gmail.com
                  </Typography> */}
                  </Grid>
                  <Grid xs={3} md={3} sx={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="buynow"
                      sx={{ color: "white" }}
                      size="large"
                      onClick={handlePlaceOrder}
                    >
                      Place Order
                    </Button>
                  </Grid>
                  {/*  {paymentFailure?.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="body" color={"error"}>
                        {paymentFailure}
                      </Typography>
                    </Grid>
                  )} */}
                </Grid>
              </Paper>
            )}
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

export default CheckoutSummary;
