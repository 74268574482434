import styled from "styled-components";

const CatMenuWrapper = styled.div`
  position: relative;
  width: -webkit-fill-available;
  text-align: -webkit-center;
  align-self: center;
  padding: 16px;
  font-weight: 500;
`;

const CatMenuWrapperWithoutImg = styled.div`
  position: relative;
  width: -webkit-fill-available;
  text-align: -webkit-center;
  align-self: center;
  padding: 12px;
  font-weight: 500;
`;

const CatMenuListWrapper = styled.div`
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 8px 1px rgba(0, 0, 0, 0.3);
  z-index: 2;

  .menu-list-link {
    text-decoration: none;
    color: #4eb7cf;
  }

  ${CatMenuWrapper}:hover && {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 5px 3px;
    background-color: #fff;
    .menu-list-link {
      padding: 5px;
      width: max-content;
      font-size: 15px;
      line-height: 20px;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }

  ${CatMenuWrapperWithoutImg}:hover && {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 5px 3px;
    background-color: #fff;
    .menu-list-link {
      padding: 5px;
      width: max-content;
      font-size: 15px;
      line-height: 20px;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
`;

export { CatMenuWrapper, CatMenuListWrapper, CatMenuWrapperWithoutImg };
