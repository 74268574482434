import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AccordianSectionComp } from "./Accordian.style";
import { CheckBoxFormGroup } from "./CheckBoxFormGroup";

const AccordianSection = (props) => {
  const { filterItem = {} } = props;
  const { title = "", facetValues = [] } = filterItem;
  return (
    <>
      {facetValues?.length > 0 && (
        <AccordianSectionComp id="Accordian-section">
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography
                fontWeight={500}
                variant="body2"
                textTransform={"uppercase"}
              >
                {title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxFormGroup
                facetValues={facetValues}
                title={title}
                key={title}
              />
            </AccordionDetails>
          </Accordion>
        </AccordianSectionComp>
      )}
    </>
  );
};
export default AccordianSection;
