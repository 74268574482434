import {
  ADD_FILTER,
  CLEAR_PRODUCT_LIST,
  GET_PRODUCTS_LIST,
  REMOVE_FILTER,
} from "../actions";

const initialState = {
  productsList: {},
  filters: {},
};

export const allProductsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_PRODUCTS_LIST:
      return {
        ...state,
        productsList: payload,
      };
    case ADD_FILTER:
      return {
        ...state,
        filters: {
          ...state?.filters,
          [payload?.id]: [
            ...(state?.filters?.[payload?.id] || ""),
            payload.value,
          ],
        },
      };
    case REMOVE_FILTER:
      return {
        ...state,
        filters: {
          ...state?.filters,
          [payload?.id]: [
            ...(state?.filters?.[payload?.id].length > 0 &&
              state?.filters?.[payload?.id].filter((key) => {
                return key !== payload?.value;
              })),
          ],
        },
      };
    case CLEAR_PRODUCT_LIST:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
