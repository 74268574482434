import React from "react";

const SavedCards = () => {
  return (
    <React.Fragment>
      <h1>SavedCards</h1>
    </React.Fragment>
  );
};

export default SavedCards;
