import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import OrderItem from "./OrderItem";

const OrdersList = (props) => {
  const { orderList = {} } = props;
  const {
    orderDate = "",
    orderNumber = "",
    orderTotalPrice = "",
    shipToAddress = {},
    orderReleaseList = [],
  } = orderList;

  const {
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    pinCode = "",
    state = "",
  } = shipToAddress;

  const localDate = orderDate && new Date(orderDate).toDateString();

  return (
    <React.Fragment>
      <Paper elevation={2} sx={{ border: "1px #D5D9D9 solid" }}>
        <Grid sx={{ backgroundColor: "#F0F2F2" }}>
          <Grid container spacing={4}>
            <Grid xs={2}>
              <Typography>ORDER PLACED</Typography>
              <Typography>{localDate}</Typography>
            </Grid>
            <Grid xs={2}>
              <Typography>TOTAL</Typography>
              <Typography>{`₹ ${orderTotalPrice}`}</Typography>
            </Grid>
            <Grid xs={5}>
              <Typography>SHIP TO</Typography>
              <Typography>{`${addressLine1}, ${
                addressLine2 && addressLine2
              }, ${city}, ${state}, ${pinCode}`}</Typography>
            </Grid>
            <Grid xs={3}>
              <Typography>{`ORDER# ${orderNumber}`}</Typography>
              <Link
                to={`${"/myaccount/orderDetails"}?orderId=${orderNumber}`}
                className="route-link"
                style={{ paddingRight: "10px" }}
              >
                View order details
              </Link>
              <Divider
                orientation={"vertical"}
                sx={{ mr: 1 }}
                component={"span"}
              />
              <Link to="#" className="route-link">
                Invoice
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {orderReleaseList?.length > 0 &&
            orderReleaseList?.map((orderItm) => (
              <>
                <OrderItem
                  orderItemDetails={orderItm}
                  orderNumber={orderNumber}
                />
                <Divider />
              </>
            ))}
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
export default OrdersList;
