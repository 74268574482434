import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import AuthorizeOrGuestLogin from "../../AuthorizeOrGuestLogin";

const CheckoutLogin = (props) => {
  const { currentStep = {}, stepAction = () => {} } = props;
  const { step = 0 } = currentStep;

  const userProfileInfo = useSelector(
    (state) => state.userDetails?.userProfile || {}
  );
  const { fullName = "", phoneNo = "" } = userProfileInfo;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            p: 2,
            pb: 0,
            pt: 0,
          },
        }}
      >
        <Paper elevation={2}>
          <Grid container direction={"row"} sx={{ p: 1 }}>
            {step !== 1 && (
              <>
                <Grid xs={10} md={10}>
                  <Grid>
                    <Typography variant="body" component="div" fontWeight={500}>
                      LOGIN
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="body" component="div" fontWeight={500}>
                      {`${fullName}, ${phoneNo}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={2} md={2} sx={{ alignSelf: "center" }}>
                  <Button variant="outlined" onClick={() => stepAction(1)}>
                    Change
                  </Button>
                </Grid>
              </>
            )}
            {step === 1 && (
              <>
                <AuthorizeOrGuestLogin stepAction={stepAction} />
              </>
            )}
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default CheckoutLogin;
