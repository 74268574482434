import React from "react";
import { ProductsListDescDetails } from "./ProductsList.style";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
// import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";

const ProductsListDesc = (props) => {
  const { productDetails = {} } = props;
  const {
    brand = "NA",
    name = "NA",
    price: { discountedPrice = "", price = "" } = {},
    /* sizes = "L,XL,S,M", */
  } = productDetails;

  const discountPercentage = Math.floor(
    ((Number(price) - Number(discountedPrice)) / Number(price)) * 100
  );
  return (
    <ProductsListDescDetails>
      <Grid
        container
        direction={"row"}
        spacing={2}
        sx={{ textAlign: "left", m: 0, mb: 0 }}
      >
        <Grid xs={12} sx={{ mt: 1, ml: 1, pb: 0 }}>
          <Typography
            variant="h6"
            color={"GrayText"}
            sx={{ m: 0 }}
            gutterBottom
          >
            {brand}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sx={{
            ml: 1,
            minHeight: 40,
            maxHeight: 80,
            overflow: "hidden",
            pb: 0,
            pt: 0.5,
          }}
        >
          <Typography
            variant="body"
            color={"GrayText"}
            sx={{ m: 0 }}
            gutterBottom
          >
            {name}
          </Typography>
        </Grid>
        <Grid xs={3} md={3} sx={{ pr: 0, pt: 0, pb: 0 }}>
          <Typography
            variant="body"
            color={"black"}
            fontWeight={500}
            gutterBottom
            sx={{ m: 0 }}
          >
            {`₹ ${discountedPrice}`}
          </Typography>
        </Grid>
        <Grid xs={3} md={7} sx={{ pl: 0, pr: 0, pt: 0, pb: 0 }}>
          <Typography
            variant="body"
            color={"GrayText"}
            gutterBottom
            sx={{ textDecoration: "line-through", m: 0 }}
          >
            {` ₹ ${price}`}
          </Typography>
          <Typography variant="body" color={"green"} gutterBottom>
            {` ${discountPercentage} % off`}
          </Typography>
        </Grid>
        {/* <Grid xs={12} sx={{ ml: 1 }}>
          <Typography variant="body" color={"GrayText"} gutterBottom>
            Size: {sizes}
          </Typography>
        </Grid> */}
      </Grid>
    </ProductsListDescDetails>
  );
};

export default ProductsListDesc;
