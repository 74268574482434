/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import CatalogWithoutImg from "../Shared/CatalogList/CatalogWitoutImg";
import constants from "../../constants";
import { getAPIService } from "../API";
import { useLocation } from "react-router-dom";
import { clearBuyNowData, productDetails } from "../../actions";
import ProductDetailContainer from "./ProductDetailContainer";
import { productMap } from "../../utils";
import ProductDetailsSkeleton from "../Skeletons/ProductDetailsSkeleton";
import { Container, Typography } from "@mui/material";
import Footer from "../Footer";

const ProductDetails = () => {
  const params = useLocation();
  const dispatch = useDispatch();
  const SKUID = params?.pathname?.split("/")[2] || "";
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState({ error: false, errorDetails: "" });
  useEffect(() => {
    dispatch(clearBuyNowData());
    const getProductDetails = () => {
      setIsLoading(true);
      const URL = `${constants.Apis.getProductDetails}?productSku=${SKUID}`;
      getAPIService({ url: URL })
        .then((response) => {
          const { status = 100, data = {} } = response;
          if (status === 200) {
            const Products = productMap(data);
            dispatch(productDetails(Products));
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsError({
            ...isError,
            error: true,
            errorDetails: JSON.stringify(error),
          });
          setIsLoading(false);
        });
    };

    getProductDetails();
  }, []);

  return (
    <React.Fragment>
      <Grid container direction={"column"}>
        <CatalogWithoutImg />
        {isError?.error && (
          <>
            <Grid>
              <Typography variant="h5">{isError?.errorDetails}</Typography>
            </Grid>
          </>
        )}
        {isLoading ? (
          <>
            <ProductDetailsSkeleton />
          </>
        ) : (
          <ProductDetailContainer />
        )}
      </Grid>
      <Container
        maxWidth={"xl"}
        sx={{ p: 0, pt: 2, mt: 2, bottom: 0, position: "relative" }}
      >
        <Footer />
      </Container>
    </React.Fragment>
  );
};
export default ProductDetails;
