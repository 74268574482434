import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { Button, Grid } from "@mui/material";

const DeliveryAddList = (props) => {
  const {
    addressChangeHandler = () => {},
    addressList = [],
    selectedAddress = "",
    handleRatingRetry = () => {},
    rateAPIError,
  } = props;

  return (
    <React.Fragment>
      <Box /* sx={{ maxWidth: 120 }} */>
        <Grid container spacing={2}>
          <Grid item sx={12} md={6}>
            {addressList?.length > 0 && (
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Select Delivery Address:
                </InputLabel>
                <NativeSelect
                  error={rateAPIError}
                  defaultValue={selectedAddress}
                  inputProps={{
                    name: "delivery-Address",
                    id: "uncontrolled-native",
                  }}
                  onChange={addressChangeHandler}
                >
                  {addressList?.map((item) => {
                    const {
                      fullName = "",
                      contactDetailId = "",
                      address: { addressLine1 = "", pinCode = "" } = {},
                    } = item;
                    return (
                      <option
                        value={contactDetailId}
                      >{`${fullName}, ${addressLine1}, ${pinCode}`}</option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            )}
          </Grid>
          {rateAPIError && (
            <Grid item sx={12} md={6} alignContent={"end"}>
              <Button
                variant="outlined"
                color={rateAPIError ? "error" : ""}
                onClick={handleRatingRetry}
              >
                Retry
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default DeliveryAddList;
