import React, { useEffect, useState } from "react";
import CheckoutLogin from "../CheckoutLogin";
import CheckoutAddress from "../CheckoutAddress";
import CheckoutSummary from "../CheckoutSummary";
import { chooseToken } from "../../../utils";
// import CheckoutPayment from "../CheckoutPayment";

const CheckoutItemsList = () => {
  const { isGuestUser = false } = chooseToken();
  const [currentStep, setCurrentStep] = useState({ step: 0 });

  const changeStep = (stepNumber) => {
    setCurrentStep({ step: Number(stepNumber) });
  };

  useEffect(() => {
    if (isGuestUser) {
      setCurrentStep({ step: 1 });
    } else {
      setCurrentStep({ step: 2 });
    }
  }, [isGuestUser]);

  return (
    <React.Fragment>
      <CheckoutLogin currentStep={currentStep} stepAction={changeStep} />
      <CheckoutAddress currentStep={currentStep} stepAction={changeStep} />
      <CheckoutSummary currentStep={currentStep} stepAction={changeStep} />
      {/* <CheckoutPayment currentStep={currentStep} /> */}
    </React.Fragment>
  );
};

export default CheckoutItemsList;
