import * as React from "react";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon fontSize="small" />,
          className: "increment",
        },
        decrementButton: {
          children: <RemoveIcon fontSize="small" />,
        },
      }}
      {...props}
      ref={ref}
    />
  );
});

export default function QuantityInput(props) {
  const { qty = 0, handleQty = () => {}, skuID = "", variantType = "" } = props;
  const [currQty, setCurrQty] = React.useState(0);
  const updateQty = ({ skuID, variantType, newValue }) => {
    setCurrQty(newValue);
    handleQty({ skuID, variantType, newValue });
  };

  React.useEffect(() => {
    setCurrQty(qty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NumberInput
      aria-label="Quantity Input"
      min={1}
      max={99}
      name="update_qty"
      id={skuID}
      value={currQty}
      onChange={(event, newValue) => {
        updateQty({ skuID, variantType, newValue });
      }}
    />
  );
}

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: "#4eb7cf";
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`
);

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: #4eb7cf;
  background: #fff;
  border: 1px solid #4eb7cf;
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 3rem;
  text-align: center;

  &:hover {
    border-color: #4eb7cf;
  }

  
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  border-color: #4eb7cf;
  background: #fff;
  color:#4eb7cf;
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: #fff;
    border-color: #4eb7cf;
    color: #4eb7cf;
  }

  &.increment {
    order: 1;
  }
`
);
