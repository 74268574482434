import React from "react";
import OrderDetailItem from "../OrderDetailItem";
import { Grid } from "@mui/material";

const OrderDetailsContainer = ({ orderReleaseList = [], orderNumber = "" }) => {
  return (
    <React.Fragment>
      <Grid container spacing={2} width={"100%"}>
        <Grid xs={12}>
          {orderReleaseList?.length > 0 &&
            orderReleaseList?.map((orderItms) => (
              <OrderDetailItem
                orderItemDetails={orderItms}
                orderNumber={orderNumber}
              />
            ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default OrderDetailsContainer;
