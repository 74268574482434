import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import { Alert } from "@mui/material";

export default function CustomizedSnackbars(props) {
  const { text = "", isOpen = false, type = "", AlertClose = () => {} } = props;
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    AlertClose();
    setState({ ...state, open: false });
  };

  React.useEffect(() => {
    setState({
      ...state,
      open: isOpen,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {text}
        </Alert>
      </Snackbar>
    </Box>
  );
}
