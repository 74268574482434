import React from "react";
import ClanLogo from "../assets/Clan_Logo_business.png";
import { ImageWrapper } from "./logoWrapper.style";

const LogoWrapper = () => {
  return (
    <ImageWrapper>
      <img src={ClanLogo} height={50} width={50} alt="clan_logo" />
    </ImageWrapper>
  );
};

export default LogoWrapper;
