import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import CheckoutPriceDetails from "../Checkout/CheckoutPriceDetails";
import { useSelector } from "react-redux";
import BuyNowContainer from "./BuyNowBodyContainer";

const BuyNow = () => {
  const buyNow = useSelector((state) => state.buyNow || {});
  const GuestDetails = useSelector((state) => state.userDetails || {});
  const { buyNowData: { cartPriceDetail = {}, shipments = [] } = {} } = buyNow;
  const { isGuest = false } = GuestDetails;

  return (
    <React.Fragment>
      <Grid container rowGap={2} direction={"column"}>
        <Grid container spacing={2} direction={"row"} sx={{ m: 0 }}>
          <Grid xs={8} md={8} lg={8} xl={9}>
            <BuyNowContainer />
          </Grid>
          <Grid xs={4} md={4} lg={4} xl={3}>
            {!isGuest && (
              <CheckoutPriceDetails
                cartPriceDetail={cartPriceDetail}
                shipmentsLen={shipments?.length || 0}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default BuyNow;
