import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const CheckoutPriceDetails = (props) => {
  const { cartPriceDetail = {}, shipmentsLen = 0 } = props;

  const {
    price = 0,
    totalAmount = 0,
    shippingFee = 0,
    discount = 0,
    /* discountedShippingFee = 0, */
    saveAmount = 0,
  } = cartPriceDetail;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            p: 2,
          },
        }}
      >
        <Paper elevation={3}>
          <Grid container direction={"column"}>
            <Grid xs={12} md={12}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                color="text.secondary"
              >
                PRICE DETAILS
              </Typography>
            </Grid>
            <Divider />
            <Grid container direction={"row"} spacing={2} sx={{ pt: 2, pb: 2 }}>
              <Grid xs={6} md={6}>
                <Typography variant="body">{`Price(${shipmentsLen} items)`}</Typography>
              </Grid>
              <Grid xs={6} md={6}>
                <Typography variant="body">{`₹${price}`}</Typography>
              </Grid>
              <Grid xs={6} md={6}>
                <Typography variant="body">Discount</Typography>
              </Grid>
              <Grid xs={6} md={6}>
                <Typography variant="body">{`₹${discount}`}</Typography>
                {/* <Typography
                  variant="body"
                  sx={{ color: "green" }}
                >{`${" Free"}`}</Typography> */}
              </Grid>
              <Grid xs={6} md={6}>
                <Typography variant="body">Delivery Charge</Typography>
              </Grid>
              <Grid xs={6} md={6}>
                <Typography variant="body">{`₹${shippingFee}`}</Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} direction={"row"} sx={{ pt: 2 }}>
              <Grid xs={6} md={6}>
                <Typography
                  variant="h6"
                  component="div"
                  fontWeight={500}
                  color="#000"
                >
                  Total Payable
                </Typography>
              </Grid>
              <Grid xs={6} md={6}>
                <Typography
                  variant="h6"
                  component="div"
                  fontWeight={500}
                  color="#000"
                >
                  {`₹${totalAmount}`}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            {saveAmount > 0 && (
              <Grid xs={12} md={12} sx={{ pt: 2, pb: 2 }}>
                <Typography
                  gutterBottom
                  variant="body"
                  component="div"
                  fontWeight={500}
                  color="#388e3c"
                >
                  {`Your Total Savings on this order ₹${saveAmount}`}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default CheckoutPriceDetails;
