import { Grid, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const params = useLocation();
  const footerParams = params?.pathname?.split("/")[1] || "";
  const [footerVisible, setFooterVisible] = useState(true);

  useEffect(() => {
    if (
      footerParams === "login" ||
      footerParams === "buynow" ||
      footerParams === "signup" ||
      footerParams === "ordersuccess"
    ) {
      setFooterVisible(false);
    } else {
      setFooterVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footerParams]);

  return (
    footerVisible && (
      <React.Fragment>
        <Grid container direction={"row"} pb={2}>
          <Grid xs={12} md={12} pb={3}>
            <Typography variant="h6" pb={2}>
              Brand Directory
            </Typography>
            <Typography>
              Photo frames | MDF crafts| tribal crafts | resin products |
              banarasi saree | Kolhapuri sandals| artificial plants| leather
              products | bean bag | macrame products | ram darbar statue |metal
              crafts | jewelry | leather bags| ladies purse| wooden ram mandir
              |religious decor| cotton curtains| bedsheets| blackout curtains|
              ottoman stools| photo frames| womens dress| mens dress
            </Typography>
          </Grid>
          <Grid xs={3} md={3}>
            <Typography variant="h6" pb={1} color={"primary"}>
              Registered Office Address:
            </Typography>
            <Typography>Clan Business Private Limited.</Typography>
            <Typography>A-49 Engine House,</Typography>
            <Typography>Mohan Estate Mathura Road,</Typography>
            <Typography>Badarpur, New Delhi,</Typography>
            <Typography>Pincode: 110044</Typography>
            <Typography>
              {`Telephone: `}
              <a href="tel:+91 9821847814">+91 9821847814</a>
            </Typography>
          </Grid>
          <Grid xs={3} md={3}>
            <Typography variant="h6" pb={1} color={"primary"}>
              Customer Policies:
            </Typography>
            <Typography>
              <Link
                underline="none"
                color={"black"}
                href="/policies/returnpolicy"
              >
                Cancellation & Returns
              </Link>
            </Typography>
            <Typography>
              <Link
                underline="none"
                color={"black"}
                href="/policies/shippingpolicy"
              >
                Shipments
              </Link>
            </Typography>
            <Typography>
              <Link
                underline="none"
                color={"black"}
                href="/policies/privacypolicy"
              >
                Privacy policy
              </Link>
            </Typography>
            <Typography>
              <Link
                underline="none"
                color={"black"}
                href="/policies/termsandconditions"
              >
                Terms & Conditions
              </Link>
            </Typography>
            <Typography>
              <Link underline="none" color={"black"} href="/policies/faq">
                FAQ
              </Link>
            </Typography>
          </Grid>
          <Grid xs={3} md={3}>
            <Typography variant="h6" color={"primary"}>
              Mail Us:
            </Typography>
            <Typography>Info@clan.business</Typography>
          </Grid>
          <Grid xs={3} md={3}>
            <Typography variant="h6" color={"primary"}>
              Connect with Us
            </Typography>
            <Typography>
              <Link
                underline="none"
                color={"black"}
                href="https://pin.it/3lvmDCdLU"
              >
                Pinterest
              </Link>
            </Typography>
            <Typography>
              <Link
                underline="none"
                color={"black"}
                href="https://www.instagram.com/clan.business?igsh=Z3ZkZXV4anM0NHFo"
              >
                Instagram
              </Link>
            </Typography>
            <Typography>
              <Link
                underline="none"
                color={"black"}
                href="https://www.facebook.com/profile.php?id=100093338202634&mibextid=LQQJ4d"
              >
                Facebook
              </Link>
            </Typography>
            <Typography>
              <Link
                underline="none"
                color={"black"}
                href="https://www.linkedin.com/in/clan-business-804550284?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
              >
                LinkedIn
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} pt={2} pb={0} justifyContent={"center"}>
          <Grid>
            <Typography>Clan.Business @2024</Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  );
};
export default Footer;
