import { GET_ORDER_DETAILS } from "../actions";

const initialState = {};

export const orderDetailsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ORDER_DETAILS:
      return {
        ...state,
        orderDetailData: payload,
      };
    default:
      return state;
  }
};
