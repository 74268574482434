import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import CheckoutItemsList from "./CheckoutItemsList";
import CheckoutPriceDetails from "./CheckoutPriceDetails";
import { CheckoutWrapper } from "./style";

const Checkout = () => {
  const AllCartItems = useSelector(
    (state) => state.AllCartItems?.AllCartData || []
  );
  const userProfile = useSelector((state) => state.userDetails || {});
  const { isGuest = true } = userProfile;
  const { cartPriceDetail = {}, shipments = [] } = AllCartItems;

  useEffect(() => {
    if (window !== undefined) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (!shipments?.length) {
      if (window !== undefined) {
        window.location.href = "/";
      }
    }
  }, [shipments?.length]);

  return (
    <CheckoutWrapper>
      <Grid container rowGap={2} direction={"column"}>
        <Grid container spacing={2} direction={"row"} sx={{ m: 0 }}>
          <Grid xs={8} md={8} lg={8} xl={9}>
            <CheckoutItemsList />
          </Grid>
          {!isGuest && shipments?.length > 0 && (
            <Grid xs={4} md={4} lg={4} xl={3}>
              <CheckoutPriceDetails
                cartPriceDetail={cartPriceDetail}
                shipmentsLen={shipments?.length || 0}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </CheckoutWrapper>
  );
};

export default Checkout;
