import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { Button, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getAPIService, putAPIService } from "../../API";
import Constants from "../../../constants";
import CustomizedSnackbars from "../../Shared/SnackBar";
import {
  decodeUserToken,
  EmailIdValidation,
  mobileNumValidation,
} from "../../../utils";
import { getAuthDetails } from "../../../actions";

const ProfileInfo = () => {
  const { Token = "" } = decodeUserToken();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails || {});
  const { userProfile = {} } = userDetails;
  const {
    emailId = "",
    fullName = "",
    phoneNo = "",
    gender: genderInfo = "",
    userId = "",
  } = userProfile;
  const [first = "", Last = ""] = fullName?.split(" ") || fullName;
  const [gender, setGender] = useState("");
  const [name, setName] = useState({ firstName: "", lastName: "" });
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [validMobile, setValidMobile] = useState("");
  const [validEmail, setValidEmail] = useState("");
  const [enableEdit, setEnableEdit] = useState({
    PI: false,
    EA: false,
    MN: false,
    PIDis: false,
    EADis: false,
    MNDis: false,
  });

  const handleRadio = (e) => {
    setGender(e.target.value);
  };

  const handleOnChange = (e) => {
    let targetName = e.target.name;
    let value = e.target.value;
    if (targetName === "userFirstName") {
      setName({ ...name, firstName: value });
    } else if (targetName === "userLastName") {
      setName({ ...name, lastName: value });
    } else if (targetName === "usermobile") {
      const checkMobile = mobileNumValidation(value);
      setValidMobile(String(checkMobile));
      setMobile(value);
    } else if (targetName === "useremail") {
      const checkEmail = EmailIdValidation(value);
      setValidEmail(String(checkEmail));
      setEmail(value);
    }
  };

  const handleEdit = (e) => {
    let targetName = e.target.name;
    if (targetName === "PI") {
      setEnableEdit({ ...enableEdit, PI: true, EADis: true, MNDis: true });
    } else if (targetName === "EA") {
      setEnableEdit({ ...enableEdit, EA: true, PIDis: true, MNDis: true });
    } else if (targetName === "MN") {
      setEnableEdit({ ...enableEdit, MN: true, PIDis: true, EADis: true });
    }
  };

  const handleSaveProfile = async (e) => {
    let targetName = e.target.name;
    if (targetName === "PI") {
      setEnableEdit({ ...enableEdit, PI: false, EADis: false, MNDis: false });
    } else if (targetName === "EA") {
      setEnableEdit({ ...enableEdit, EA: false, PIDis: false, MNDis: false });
    } else if (targetName === "MN") {
      setEnableEdit({ ...enableEdit, MN: false, PIDis: false, EADis: false });
    }

    const url = Constants.user.editUserDetails;
    const payload = {
      userId: userId,
      fullName: `${name?.firstName} ${name.lastName}`,
      loggedInSystemId: "system1",
      gender: gender,
      emailId: email,
      phoneNo: mobile,
    };
    const headers = {
      token: `${Token}`,
    };
    let response = await putAPIService({ url, data: payload, headers });
    const { status = 100 } = response;
    if (status === 200) {
      setIsAlert(true);
    }
  };

  const getUserDetails = async () => {
    const URL = `${Constants.user.getUserDetails}`;
    const headers = {
      token: `${Token}`,
    };
    const response = await getAPIService({ url: URL, headers });
    const { status = 100, data = {} } = response;
    if (status === 200) {
      dispatch(getAuthDetails({ ...data, isAuthUser: true }));
    }
  };

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setName({ firstName: first, lastName: Last });
    setEmail(emailId);
    setGender(genderInfo);
    setMobile(phoneNo);
  }, [Last, emailId, first, genderInfo, phoneNo]);

  return (
    <React.Fragment>
      <Grid container direction={"row"} spacing={2} pb={2}>
        <Grid xs={12} sx={{ display: "flex", padding: 3 }}>
          <Grid>
            <Typography variant="h6" fontWeight={500}>
              Personal Information
            </Typography>
          </Grid>
          <Grid>
            <Button
              variant="text"
              name="PI"
              onClick={handleEdit}
              disabled={enableEdit?.PIDis}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid xs={12} sx={{ display: "flex", pr: 3, pl: 3, gap: 2 }}>
          <Grid>
            <TextField
              name="userFirstName"
              required
              fullWidth
              size="small"
              id="userFirstName"
              label="Enter FirstName"
              value={name?.firstName}
              disabled={!enableEdit?.PI}
              onChange={handleOnChange}
            />
          </Grid>
          <Grid>
            <TextField
              name="userLastName"
              required
              fullWidth
              size="small"
              id="userLastName"
              label="Enter LastName"
              value={name?.lastName}
              disabled={!enableEdit?.PI}
              onChange={handleOnChange}
            />
          </Grid>
          {enableEdit?.PI && (
            <Grid>
              <Button
                variant="contained"
                name="PI"
                onClick={handleSaveProfile}
                sx={{ color: "white" }}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid xs={12} sx={{ display: "flex", padding: 3, textAlign: "left" }}>
          <FormControl>
            <FormLabel id="row-radio-buttons-group-label">
              Your Gender
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={gender}
              defaultValue={"male"}
              onChange={handleRadio}
            >
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Male"
                disabled={!enableEdit?.PI}
              />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
                disabled={!enableEdit?.PI}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container direction={"row"} spacing={2} pb={2}>
        <Grid xs={12} sx={{ display: "flex", padding: 3 }}>
          <Grid>
            <Typography variant="h6" fontWeight={500}>
              Email Address
            </Typography>
          </Grid>
          <Grid>
            <Button
              variant="text"
              name="EA"
              onClick={handleEdit}
              disabled={enableEdit?.EADis}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid xs={12} sx={{ display: "flex", pr: 3, pl: 3, gap: 2 }}>
          <Grid>
            <TextField
              name="useremail"
              error={validEmail === "false"}
              required
              fullWidth
              size="small"
              id="useremail"
              label="Enter Email"
              value={email}
              onChange={handleOnChange}
              disabled={!enableEdit?.EA}
            />
          </Grid>
          {enableEdit?.EA && (
            <Grid>
              <Button
                variant="contained"
                name="EA"
                disabled={!validEmail}
                onClick={handleSaveProfile}
                sx={{ color: "white" }}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container direction={"row"} spacing={2} pb={2}>
        <Grid xs={12} sx={{ display: "flex", padding: 3 }}>
          <Grid>
            <Typography variant="h6" fontWeight={500}>
              Mobile Number
            </Typography>
          </Grid>
          <Grid>
            <Button
              variant="text"
              name="MN"
              onClick={handleEdit}
              disabled={enableEdit?.MNDis}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid xs={12} sx={{ display: "flex", pr: 3, pl: 3, gap: 2 }}>
          <Grid>
            <TextField
              name="usermobile"
              error={validMobile === "false"}
              required
              size="small"
              fullWidth
              id="usermobile"
              label="Enter Mobile"
              value={mobile}
              onChange={handleOnChange}
              disabled={!enableEdit?.MN}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{`+91 `}</InputAdornment>
                ),
              }}
            />
          </Grid>
          {enableEdit?.MN && (
            <Grid>
              <Button
                variant="contained"
                name="MN"
                onClick={handleSaveProfile}
                sx={{ color: "white" }}
                disabled={!validMobile}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <CustomizedSnackbars
        text={"Profile Updated Successfully"}
        isOpen={isAlert}
        type={"success"}
      />
    </React.Fragment>
  );
};

export default ProfileInfo;
