import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import CartItemsList from "./CartItemsList";
import Typography from "@mui/material/Typography";
import CartItemsPriceDetails from "./CartItemsPriceDetails";
import { getCartList } from "../../actions";
import CartSkeleton from "../Skeletons/CartSkeleton";
import { getCarItems } from "./cartUtil";
import { CartviewWrapper } from "./styles";
import { Button, Container } from "@mui/material";
import Footer from "../Footer";

const ViewCart = () => {
  const dispatch = useDispatch();
  const getExistingPincode = localStorage.getItem("pincode");
  const parsePincode = JSON.parse(getExistingPincode) || "";
  const [emptyCart, setEmptyCart] = useState(false);
  const [checkGuest, setCheckGuest] = useState(false);
  const [shipmentsCount, setShipmentsCount] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState({ error: false, errorDetails: "" });
  const cartList = useSelector(
    (state) => state?.AllCartItems?.AllCartData || {}
  );
  const { cartPriceDetail = {}, shipments = [] } = cartList || {};

  const updatePincode = useSelector(
    (state) => state.productDetails?.updatedPincode || ""
  );

  const guestUser = useSelector((state) => state.userDetails?.isGuest);

  const getCartData = async () => {
    setIsLoading(true);
    setIsError({ error: false, errorDetails: "" });
    const AllcartData = await getCarItems({
      setIsError,
      setIsLoading,
      pincode: parsePincode,
    });
    if (AllcartData?.data?.shipments.length) {
      setEmptyCart(false);
      dispatch(getCartList(AllcartData?.data));
    }
    setEmptyCart(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (window !== undefined) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (parsePincode.length) {
      getCartData();
    } else {
      setEmptyCart(true);
    }
    if (shipments?.length > 0) {
      let cartItemsCount = shipments?.reduce((prev, curr) => {
        let count = curr?.cartItemDetails?.length;
        prev = prev + count;
        return prev;
      }, 0);
      setShipmentsCount(cartItemsCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCheckGuest(guestUser);
  }, [guestUser]);
  useEffect(() => {
    if (updatePincode?.length) {
      getCartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePincode]);
  /* 
  useMemo(() => {
    const getCartData = async () => {
      setIsLoading(true);
      const AllcartData = await getCarItems({ setIsError, setIsLoading });
      if (AllcartData?.data) {
        dispatch(getCartList(AllcartData?.data));
      }
      setIsLoading(false);
    };
    if (updateCartItems !== previousObject.current) {
      getCartData();
      previousObject.current = updateCartItems;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCartItems]); */

  return (
    <CartviewWrapper>
      {isError?.error && !shipments?.length && (
        <Grid container spacing={2} width={"100%"} textAlign={"center"} mt={4}>
          <Grid item xs={12}>
            <Typography variant="body" color={isError?.error ? "error" : ""}>
              {isError?.errorDetails}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color={isError?.error ? "error" : ""}
              onClick={getCartData}
            >
              Try Again
            </Button>
          </Grid>
        </Grid>
      )}
      {isLoading ? (
        <>
          <CartSkeleton />
        </>
      ) : (
        <Grid container rowGap={2} direction={"column"}>
          {shipments?.length > 0 && (
            <Grid container spacing={2} direction={"row"} sx={{ m: 0 }}>
              <Grid xs={8} md={8} lg={8} xl={9}>
                <CartItemsList shipments={shipments} />
              </Grid>
              <Grid xs={4} md={4} lg={4} xl={3}>
                <CartItemsPriceDetails
                  cartPriceDetail={cartPriceDetail}
                  shipmentLength={shipmentsCount}
                />
              </Grid>
            </Grid>
          )}
          {!shipments?.length && !isError?.error && emptyCart && (
            <Grid container sx={{ textAlign: "center", marginTop: 2 }}>
              <Grid xs={12} md={12}>
                <Typography variant="h5">
                  Your Cart is Empty, Place few Orders
                </Typography>
                {checkGuest && (
                  <Grid item sx={{ p: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        window.location.href = "/login";
                      }}
                    >
                      Login
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      <Container
        maxWidth={"xl"}
        sx={{
          p: 0,
          pt: 2,
          mt: 2,
          top: "100%",
          bottom: 0,
          position: "relative",
        }}
      >
        <Footer />
      </Container>
    </CartviewWrapper>
  );
};

export default ViewCart;
