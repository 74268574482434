import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Constants from "../../../../constants";
import { decodeUserToken } from "../../../../utils";
import { postAPIService } from "../../../API";
import CustomizedSnackbars from "../../../Shared/SnackBar";
import { useDispatch } from "react-redux";
import { deleteWishListItem } from "../../../../actions";

const DeleteContainer = (props) => {
  const { sku = "" } = props;
  const dispatch = useDispatch();
  const [wishListAlert, setWishListAlert] = useState({
    text: "",
    isOpen: false,
    type: "",
  });
  const { Token = "" } = decodeUserToken();
  const handleRemoveWishList = () => {
    const URL = Constants.user.AddOrRemoveWishList;
    const payload = {
      wishListType: "REMOVE",
      wishListItem: sku,
    };
    const headers = { token: `${Token}` };
    postAPIService({ url: URL, data: payload, headers: { ...headers } })
      .then((response) => {
        const { status = 100 } = response;
        if (status === 200) {
          setWishListAlert({
            text: "Item Removed",
            isOpen: true,
            type: "success",
          });
          dispatch(deleteWishListItem(sku));
        }
      })
      .catch((error) => {
        console.log("wishlist_error", error);
      });
  };

  const AlertClose = () => {
    setWishListAlert({
      text: "",
      isOpen: false,
      type: "",
    });
  };

  return (
    <React.Fragment>
      <Grid container sx={{ justifyContent: "flex-end" }}>
        <Button onClick={handleRemoveWishList}>
          <DeleteOutlineOutlinedIcon sx={{ color: "gray" }} />
        </Button>
        {wishListAlert?.isOpen && (
          <CustomizedSnackbars {...wishListAlert} AlertClose={AlertClose} />
        )}
      </Grid>
    </React.Fragment>
  );
};

export default DeleteContainer;
