import React, { useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogComponent from "../../Shared/DialogComponent";
import DeliveryAddressSelect from "./DeliveryAddressSelect";

const DeliveryDetailsSection = () => {
  const getExistingPincode = localStorage.getItem("pincode");
  const parsePincode = JSON.parse(getExistingPincode) || "";

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const userDetails = useSelector((state) => state.userDetails || {});
  const { userProfile = {}, isGuest = true } = userDetails;

  const userDefaultAddress = useSelector(
    (state) => state.userDetails?.defaultAddress || {}
  );

  const { fullName = "", address = {} } = userDefaultAddress;
  const {
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    state = "",
    pinCode = "",
  } = address;
  const { contactDetails = [] } = userProfile;

  const handleChangeAddress = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const DailogTitle = "Select Delivery Address";

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            p: 2,
            pb: 0,
            pt: 0,
          },
        }}
      >
        <Paper elevation={3}>
          <Grid container direction={"row"}>
            <Grid xs={10} md={10}>
              <Grid container>
                <Grid>
                  <Typography
                    variant="body"
                    component="div"
                    color="text.secondary"
                  >
                    Delivery to:
                  </Typography>
                </Grid>
                {!isGuest && (
                  <Grid>
                    {pinCode ? (
                      <Typography
                        variant="body"
                        component="div"
                        color="text.secondary"
                        fontWeight={500}
                      >
                        {`${fullName}, ${pinCode}`}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body"
                        component="div"
                        color="text.secondary"
                        fontWeight={500}
                      >
                        {`${parsePincode}`}
                      </Typography>
                    )}
                  </Grid>
                )}
                {isGuest && (
                  <Grid>
                    <Typography
                      variant="body"
                      component="div"
                      color="text.secondary"
                      fontWeight={500}
                    >
                      {`${parsePincode}`}
                    </Typography>
                  </Grid>
                )}
                <Grid xs={12} sx={{ m: 0, pt: 0 }}>
                  {!isGuest && pinCode ? (
                    <Typography
                      variant="body"
                      component="div"
                      color="text.secondary"
                      fontWeight={500}
                    >
                      {`${addressLine1}, ${
                        addressLine2 && addressLine2
                      }, ${city}, ${state}, ${pinCode}`}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={2} md={2} sx={{ alignSelf: "center" }}>
              <Button variant="outlined" onClick={handleChangeAddress}>
                Change
              </Button>
              <DialogComponent
                isOpen={isDialogOpen}
                isClose={handleDialogClose}
                Title={DailogTitle}
                body={
                  <DeliveryAddressSelect
                    DialogClose={handleDialogClose}
                    AddressList={contactDetails}
                    currentPincode={parsePincode}
                    isGuest={isGuest}
                  />
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default DeliveryDetailsSection;
