import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const ShipmentPolicy = () => {
  return (
    <React.Fragment>
      <Container maxWidth={"md"}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="h4">Shipping Policy</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body">
              At Clan Business Pvt Ltd, we aim to provide efficient and reliable
              shipping services. Please note that our order processing times are
              separate from the shipping times displayed at checkout.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Order Processing
            </Typography>
            <Typography variant="body">
              All orders are processed within 2 to 3 business days (excluding
              weekends and holidays) after receiving your order confirmation
              email. You will receive another notification when your order has
              shipped. Please note that during high volume periods or postal
              service issues outside of our control, there may be delays.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Domestic Shipping Rates and Estimates
            </Typography>
            <Typography variant="body">
              For calculated shipping rates: Shipping charges for your order
              will be calculated and displayed at checkout.
            </Typography>
            <Typography variant="body">
              {`For simple flat rate shipping: We offer a flat rate shipping of
              ${(<b>₹100</b>)} to all states within India.`}
            </Typography>
            <Typography variant="body">
              {`${(
                <b>Free Shipping:</b>
              )} We offer free shipping for orders over ₹500.`}
            </Typography>
          </Grid>
          <Grid item width={"100%"}>
            <Table sx={{ border: "1px solid" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    Shipping Option
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    Estimated Delivery Time
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>Standard</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>3 to 6 business days</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>₹80</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>Expedited</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>1 to 3 business days</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>₹100</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>Express</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>1 to 2 business days</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid" }}>
                    <Typography>₹150</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Local Delivery
            </Typography>
            <Typography variant="body">
              Free local delivery is available for orders over ₹500 within the
              Delhi-NCR region. For orders under, we charge ₹80 for local
              delivery.
            </Typography>
            <Typography variant="body">
              Deliveries are made from 9 AM to 8 PM on weekdays. We will contact
              you via text message with the phone number provided at checkout to
              notify you on the day of our arrival.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Order Tracking
            </Typography>
          </Grid>
          <Grid item>
            <ul>
              <li>
                <Typography>
                  {<b>Tracking Information:</b>}
                  {`
                Once your order has shipped, you will receive a shipping confirmation email with a tracking number. You can track your order using the link provided in the email.`}
                </Typography>
              </li>
              <li>
                <Typography>
                  {<b>Lost or Delayed Orders: </b>}
                  If your order is significantly delayed or lost in transit,
                  please contact us at{" "}
                  <a href="mailto:info@clan.business">
                    info@clan.business
                  </a>, <a href="tel:+91 9821847814">+91 9821847814</a> for
                  assistance.
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Local Delivery and In-Store Pickup
            </Typography>
          </Grid>
          <Grid item>
            <ul>
              <li>
                <Typography>
                  {<b>Local Delivery:</b>}
                  {`
                We offer local delivery for orders within [specified area]. Free local delivery is available for orders over [amount]. For orders under this amount, a delivery fee of [amount] will apply.
`}
                </Typography>
              </li>
              <li>
                <Typography>
                  {<b>In-Store Pickup: </b>}
                  You may opt for in-store pickup at [specified location]. Your
                  order will be ready for pickup within [X to X] business days.
                  Please bring your order confirmation email with you.
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Payment and Security
            </Typography>
          </Grid>
          <Grid item>
            <ul>
              <li>
                <Typography>
                  {<b>Payment Gateway:</b>}
                  {`We use Razorpay to securely process payments. Your payment information is encrypted and processed in compliance with industry standards.`}
                </Typography>
              </li>
              <li>
                <Typography>
                  {<b>Transaction Security: </b>}
                  Our payment gateway ensures that your payment details are
                  safe, and we do not store any sensitive payment information on
                  our servers.
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
export default ShipmentPolicy;
