import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";

const CartItemSaveLaterOrRemove = (props) => {
  const { removeItem = () => {}, skuID = "", variantType = "" } = props;

  const handleRemoveItems = (details) => {
    removeItem({ ...details });
  };
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {/* <Grid xs={4} md={4}>
          <Button variant="text">Save For Later</Button>
        </Grid> */}
        <Grid xs={4} md={4}>
          <Button
            variant="text"
            size="large"
            onClick={() => handleRemoveItems({ skuID, variantType })}
          >
            Remove
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CartItemSaveLaterOrRemove;
