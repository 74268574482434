import styled from "styled-components";

const ProductsListImageWrapper = styled.div`
  max-height: 360px;
  height: 355px;
  width: 265px;
  max-width: 270px;
  margin-bottom: 12px;
`;

const ProductsListDescDetails = styled.div``;

export { ProductsListImageWrapper, ProductsListDescDetails };
