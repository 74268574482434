import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { AddToWishListWrapper } from "./AddtoWishList.style";
import { postAPIService } from "../../API";
import CustomizedSnackbars from "../SnackBar";
import Constants from "../../../constants";
import { decodeUserToken } from "../../../utils";

const AddToWishList = () => {
  const currentProduct = useSelector(
    (state) => state.productDetails?.prodDetails || {}
  );
  const { Token = "" } = decodeUserToken();
  const [wishlistAlert, setWishListAlert] = useState({
    text: "",
    isOpen: false,
    type: "",
  });

  const { sku = "" } = currentProduct;
  const AddToWishListAction = async () => {
    const URL = Constants.user.AddOrRemoveWishList;
    const payload = {
      wishListType: "ADD",
      wishListItem: sku,
    };
    const headers = { token: `${Token}`, "Content-Type": "application/json" };
    await postAPIService({ url: URL, data: payload, headers: { ...headers } })
      .then((response) => {
        const { status = 100 } = response;
        if (status === 200) {
          setWishListAlert({
            text: "Item Added to Wishlist",
            isOpen: true,
            type: "success",
          });
        }
      })
      .catch((error) => {
        console.log("wishlist_error", error);
      });
  };

  const AlertClose = () => {
    setWishListAlert({
      text: "",
      isOpen: false,
      type: "",
    });
  };
  return (
    <React.Fragment>
      <AddToWishListWrapper>
        <Button
          variant="contained"
          /* size="small" */
          fullWidth
          color="textcolor"
          sx={{ border: "1px solid" }}
          onClick={AddToWishListAction}
        >
          {"Add To Wish List"}
        </Button>
      </AddToWishListWrapper>
      {wishlistAlert?.isOpen && (
        <CustomizedSnackbars {...wishlistAlert} AlertClose={AlertClose} />
      )}
    </React.Fragment>
  );
};

export default AddToWishList;
