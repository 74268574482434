import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import ProductDetailsImgSlider from "../../Shared/ProductDetailsImgSlider";
import AddToCartCTA from "../../Shared/AddToCartCTA";
import BuyNowCTA from "../../Shared/AddToCartCTA/buyNowCTA";
import constants from "../../../constants";
import { ATCAPIService } from "../../API";
import {
  buyNowPayload,
  chooseToken,
  getNoVariantBuyNowPayload,
  getNoVariantPayload,
  ItemATCPayload,
} from "../../../utils";
import {
  addToCartItem,
  getBuyNowDataFail,
  getBuyNowDataSuccess,
  getCartList,
  initiateBuyNowData,
} from "../../../actions";
import CustomizedSnackbars from "../../Shared/SnackBar";
import { useNavigate } from "react-router-dom";
import AddToWishList from "../../Shared/AddToWishList";
import Constants from "../../../constants";
import { Typography } from "@mui/material";

const ProductDetailsImg = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Token = "" } = chooseToken();
  const [atcText, setAtcText] = useState("Add To Cart");
  const [setAlert, isSetAlert] = useState({
    isOpen: false,
    type: "error",
    text: "select a color",
  });

  const productDetails = useSelector(
    (state) => state?.productDetails?.prodDetails
  );
  const checkPinCodeAvailable = useSelector(
    (state) => state.productDetails?.pincodeAvailable || false
  );

  const { variantDetailsMap = {} } = productDetails;
  const varianceLenCheck = Object.keys(variantDetailsMap)?.length;
  const selectedProduct = useSelector(
    (state) => state?.productDetails?.selectedProdVar || {}
  );
  const { selectedColor = "", selectedSize = "" } = selectedProduct;
  const deliveryDetails = useSelector(
    (state) => state.productDetails?.productRateDetails || {}
  );
  const { isDeliverable = true } = deliveryDetails;

  const images =
    productDetails?.imageUrls?.length > 0
      ? productDetails?.imageUrls.reduce((prev, curr) => {
          let img = curr.imageUrl;
          prev.push(img);
          return prev;
        }, [])
      : [];

  const initiateATCAPICall = ({ URL, payload }) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    ATCAPIService({ url: URL, data: payload, headers })
      .then((response) => {
        const { status = 100, data = "" } = response;
        if (status === 200) {
          setAtcText("Go To Cart");
          isSetAlert({
            isOpen: true,
            type: "success",
            text: "Item added to Cart",
          });
          dispatch(addToCartItem(data?.cartItems));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initiateBuyNowAPICall = ({ URL, payload }) => {
    dispatch(initiateBuyNowData());
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    ATCAPIService({ url: URL, data: payload, headers }).then((response) => {
      const { status = 100, data = "" } = response;
      if (status === 200) {
        dispatch(getBuyNowDataSuccess(data));
        dispatch(getCartList(data));
        if (window !== undefined) {
          navigate("/buynow");
        }
      } else {
        dispatch(getBuyNowDataFail());
      }
    });
  };

  const AddtoCartItem = () => {
    const URL = constants.cart.addToCart;
    const type = "ADD_COUNT";

    if ((!selectedColor || !selectedSize) && varianceLenCheck) {
      isSetAlert({
        isOpen: true,
        type: "error",
        text: !selectedColor
          ? "choose a Color"
          : !selectedSize
          ? "Select size"
          : "",
      });
    } else if (atcText === "Go To Cart") {
      navigate("/viewcart");
    } else {
      if (selectedSize) {
        const addToCartPayload = ItemATCPayload({ selectedProduct, type });
        initiateATCAPICall({ URL, payload: addToCartPayload });
      } else {
        const noVariantPaylaod = getNoVariantPayload({ productDetails, type });
        initiateATCAPICall({ URL, payload: noVariantPaylaod });
      }
    }
  };

  const initiateBuyNowAPI = () => {
    const URL = Constants.cart.buyNow;

    if ((!selectedColor || !selectedSize) && varianceLenCheck) {
      isSetAlert({
        isOpen: true,
        type: "error",
        text: !selectedColor
          ? "choose a Color"
          : !selectedSize
          ? "Select size"
          : "",
      });
    } else {
      if (selectedSize) {
        const getBuynowPayload = buyNowPayload({ selectedProduct });
        initiateBuyNowAPICall({ URL, payload: getBuynowPayload });
      } else {
        const buyNowNoVariantPaylaod = getNoVariantBuyNowPayload({
          productDetails,
        });
        initiateBuyNowAPICall({ URL, payload: buyNowNoVariantPaylaod });
      }
    }
  };

  const handleBuyNowCTA = () => {
    initiateBuyNowAPI();
  };

  const AlertClose = () => {
    isSetAlert({ ...setAlert, isOpen: false });
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, textAlign: "center" }}>
        <Grid container spacing={2}>
          <Grid xs={12} md={12}>
            <ProductDetailsImgSlider image={images} />
          </Grid>
          {!checkPinCodeAvailable && isDeliverable && (
            <Grid xs={12}>
              <Typography variant="body" color={"error"}>
                {"Enter Delivery Pincode"}
              </Typography>
            </Grid>
          )}
          {!isDeliverable && (
            <Grid xs={12}>
              <Typography variant="body" color={"error"}>
                {"product Unavailable"}
              </Typography>
            </Grid>
          )}
          <Grid xs={12} md={6} sx={{ alignSelf: "center" }}>
            <AddToCartCTA
              AddtoCartItem={AddtoCartItem}
              ATCText={atcText}
              isDeliverable={isDeliverable}
              checkPinCodeAvailable={checkPinCodeAvailable}
            />
          </Grid>
          <Grid xs={12} md={6} sx={{ alignSelf: "center" }}>
            <BuyNowCTA
              handleBuyNowCTA={handleBuyNowCTA}
              isDeliverable={isDeliverable}
              checkPinCodeAvailable={checkPinCodeAvailable}
            />
          </Grid>
          <Grid xs={12} md={12} sx={{ alignSelf: "center" }}>
            <AddToWishList />
          </Grid>
        </Grid>
        <Grid>
          {setAlert?.isOpen && (
            <CustomizedSnackbars {...setAlert} AlertClose={AlertClose} />
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
};
export default ProductDetailsImg;
