import React from "react";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DetailSingleItem from "../DetailSingleItem";

const OrderDetailItem = (props) => {
  const {
    orderItemDetails: {
      orderItems = [],
      expectedDeliveryDate = "",
      status = "",
    } = {},
    orderNumber = "",
  } = props;

  return (
    <React.Fragment>
      <Paper elevation={2} sx={{ border: "1px #D5D9D9 solid" }}>
        <Grid>
          <Grid container spacing={4}>
            <Grid xs={8}>
              <Typography fontWeight={500}>{`Status: ${status}`}</Typography>
            </Grid>
            <Grid xs={4}>
              <Typography
                fontWeight={500}
              >{`${expectedDeliveryDate}`}</Typography>
            </Grid>
          </Grid>
          <Grid>
            {orderItems?.length > 0 &&
              orderItems?.map((SingleItem) => (
                <DetailSingleItem
                  itemDetails={SingleItem}
                  status={status}
                  orderNumber={orderNumber}
                />
              ))}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default OrderDetailItem;
