import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import ViewMoreMenu from "./viewMore";
import AddOrEditAddress from "../../Shared/AddOrEditAddress";
import Constants from "../../../constants";
import { deleteAPIService } from "../../API";
import { deleteUserAddressById } from "../../../actions";

const ManageAddress = () => {
  const userProfile = useSelector(
    (state) => state.userDetails?.userProfile || {}
  );
  const { contactDetails = [] } = userProfile;
  const [enableAddAddress, setEnableAddAddress] = useState(false);

  const addNewAddressClick = () => {
    setEnableAddAddress(true);
  };

  const disableAddAddress = () => {
    setEnableAddAddress(false);
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction={"row"}
        sx={{ textAlign: "start", p: 2 }}
        gap={2}
      >
        <Grid xs={12}>
          <Typography variant="h6">Manage Addresses</Typography>
        </Grid>
        <Grid xs={12}>
          <Paper variant="outlined">
            <Button
              variant="text"
              sx={{ pt: 2, pb: 2 }}
              onClick={addNewAddressClick}
            >
              Add A New Address
            </Button>
            {enableAddAddress && (
              <AddOrEditAddress
                action="add"
                disableAddAddress={disableAddAddress}
              />
            )}
          </Paper>
        </Grid>
        <Grid xs={12}>
          {contactDetails?.length > 0 &&
            contactDetails.map((address, idx) => (
              <AddressDetails addressDetail={address} key={idx} />
            ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ManageAddress;

export const AddressDetails = (props) => {
  const dispatch = useDispatch();
  const { addressDetail = {} } = props;
  const [enableEdit, setEnableEdit] = useState(false);
  const {
    fullName = "",
    phoneNo = "",
    address = {},
    contactDetailId = "",
  } = addressDetail;
  const {
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    state = "",
    pinCode = "",
  } = address;

  const EditAddress = () => {
    setEnableEdit(true);
  };
  const cancelEditAddress = () => {
    setEnableEdit(false);
  };

  //   const setDefaultAddress = () => {};

  const DeleteAddress = async (addressId) => {
    const URL = `${Constants.user.deleteAddressDetails}?contactDetailId=${addressId}`;
    const deleteAddressResonse = await deleteAPIService({ url: URL });
    const { status = 100 } = deleteAddressResonse;
    if (status === 200) {
      dispatch(deleteUserAddressById(addressId));
    }
  };

  return (
    <React.Fragment>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Grid container spacing={2} direction={"row"}>
          <Grid xs={11}>
            <>
              <Grid
                container
                spacing={2}
                direction={"column"}
                sx={{ p: 0, m: 0 }}
                rowSpacing={1}
              >
                <Grid>
                  <Typography
                    variant="body"
                    component="div"
                    color="black"
                    fontWeight={"500"}
                  >
                    {`${fullName}, ${phoneNo}`}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    variant="body"
                    component="div"
                    color="text.secondary"
                    fontWeight={500}
                  >
                    {`${addressLine1}, ${
                      addressLine2 && addressLine2
                    }, ${city}, ${state}, ${pinCode}`}
                  </Typography>
                </Grid>
              </Grid>
            </>
          </Grid>
          <Grid>
            <ViewMoreMenu
              EditAddress={EditAddress}
              deleteAddress={DeleteAddress}
              contactDetailId={contactDetailId}
            />
          </Grid>
        </Grid>
      </Paper>
      {enableEdit && (
        <Grid>
          <AddOrEditAddress
            action="edit"
            cancelEditAddress={cancelEditAddress}
            editAddressDetails={addressDetail}
          />
        </Grid>
      )}
    </React.Fragment>
  );
};
