import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Catalog from "../Shared/CatalogList";
import { useSelector } from "react-redux";
import TileContainer from "../Shared/TileContainer";
import { Container } from "@mui/material";
import Footer from "../Footer";

const HomePage = () => {
  const homePageTiles = useSelector((state) => state?.HomePageTiles || []);

  return (
    <React.Fragment>
      <Grid container rowGap={2} direction={"column"}>
        <Catalog />
        <Grid
          container
          direction={"row"}
          width={"-webkit-fill-available"}
          sx={{ overflowX: "auto" }}
        >
          {homePageTiles?.length > 0 &&
            homePageTiles?.map((Item) => <TileContainer tileItems={Item} />)}
        </Grid>
      </Grid>
      <Container
        maxWidth={"xl"}
        sx={{ p: 0, pt: 2, mt: 2, bottom: 0, position: "relative" }}
      >
        <Footer />
      </Container>
    </React.Fragment>
  );
};

export default HomePage;
