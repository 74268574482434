import React from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import ProductDetailsImg from "../ProductDetailsImg";
import ProductDetailBody from "../ProductDetailBody";
import FullLoader from "../../Shared/FullLoader";
import CustomizedSnackbars from "../../Shared/SnackBar";
import useMediaQuery from "@mui/material/useMediaQuery";

const ProductDetailContainer = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const productDetail = useSelector(
    (state) => state?.productDetails?.prodDetails || {}
  );

  const buyNow = useSelector((state) => state.buyNow || {});

  const {
    isLoading,
    isError,
    alert: { isAlert = "", type = "", msg = "" } = {},
  } = buyNow;

  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        direction={"row"}
        gap={5}
        sx={{
          pt: 2,
          m: 0,
        }}
        id="image-grid"
      >
        {isLoading && <FullLoader loaderOpenClose={isLoading} />}
        {!isLoading && (
          <>
            <Grid xs={12} md={matches ? 5 : 4}>
              <ProductDetailsImg />
            </Grid>
            <Grid xs={12} md={matches ? 6 : 7}>
              <ProductDetailBody productDetail={productDetail} />
            </Grid>
          </>
        )}
        {isError && (
          <CustomizedSnackbars text={msg} isOpen={isAlert} type={type} />
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ProductDetailContainer;
