import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import ImgColors from "../common/ImgColors";
import ProductSizes from "../common/ProductSizes";
import Delivery from "../common/Delivery";
import Seller from "../common/Seller";
import Divider from "@mui/material/Divider";
import ProductSpec from "../common/ProductSpec";
import { setSelectedProdVariant } from "../../../actions";
import GuestPincode from "../common/GuestPincode";
import { Rating } from "@mui/material";

const ProductDetailBody = (props) => {
  const { productDetail = {} } = props;
  const dispatch = useDispatch();
  const selectedProductVar = useSelector(
    (state) => state.productDetails?.selectedProdVar || {}
  );
  const userProfile = useSelector((state) => state.userDetails || {});
  const {
    isGuest: isGuestUser = false,
    userProfile: { contactDetails = [] } = {},
  } = userProfile;
  const { selectedColor = "", selectedSize = "" } = selectedProductVar;
  const { price: selectedItemPrice = {} } =
    selectedProductVar[selectedSize] || {};
  const {
    price: selectedItmPrice = "",
    discountedPrice: selectedItmDisPrice = "",
  } = selectedItemPrice;

  const [isEmptyAddressList, setIsEmptyAddressList] = useState(false);

  const {
    brand = "",
    name = "",
    shortDescription = "",
    price: { price = "", discountedPrice = "" } = {},
    review: { averageRating = 2 } = {},
    variantDetailsMap = {},
  } = productDetail;
  const colorKeys = Object.keys(variantDetailsMap);
  const imagesList = colorKeys.map((item) => {
    return { ...variantDetailsMap[item].imgUrl, colorKey: item };
  });
  const sizes = Object.keys(selectedProductVar) || [];

  const discountPercentage = Math.floor(
    ((Number(price) - Number(discountedPrice)) / Number(price)) * 100
  );

  const selectItemDiscPerc = Math.floor(
    ((Number(selectedItmPrice) - Number(selectedItmDisPrice)) /
      Number(selectedItmPrice)) *
      100
  );

  const setProdColor = (colorKey) => {
    let selectedProd = variantDetailsMap[colorKey];
    let keysExist = Object.keys(selectedProd);
    if (keysExist?.length) {
      dispatch(
        setSelectedProdVariant({ ...selectedProd, selectedColor: colorKey })
      );
    }
  };

  const handleSize = (selSize) => {
    let selectedProd = selectedProductVar?.[selSize] || {};
    let keysExist = Object.keys(selectedProd);
    if (keysExist?.length) {
      dispatch(
        setSelectedProdVariant({
          selectedSize: selSize,
        })
      );
    }
  };

  useEffect(() => {
    if (!contactDetails?.length) {
      setIsEmptyAddressList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography variant="h6" color="text.secondary">
              {`${name}`}
            </Typography>
            <Typography variant="body" color="black">
              {shortDescription}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body"
              color="green"
              fontWeight={500}
            >{`Brand : ${brand}`}</Typography>
          </Grid>
          <Grid xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body" fontWeight={"bold"}>
                  Rating:
                </Typography>
              </Grid>
              <Grid item>
                <Rating
                  name="read-only-rating"
                  value={averageRating}
                  readOnly
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Stack direction="column" spacing={2}>
              <Typography variant="body2" color="green" fontWeight={500}>
                Special price
              </Typography>
              <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                <Typography
                  variant="body2"
                  color="black"
                  fontWeight={500}
                  fontSize={30}
                >
                  {`₹ ${selectedItmDisPrice || discountedPrice}`}
                </Typography>
                <Typography
                  variant="body2"
                  color="black"
                  fontSize={20}
                  sx={{ textDecoration: "line-through" }}
                >
                  {`₹ ${selectedItmPrice || price}`}
                </Typography>
                <Typography variant="body" color={"green"} gutterBottom>
                  {` ${selectItemDiscPerc || discountPercentage} % off`}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          {imagesList?.length > 0 && (
            <Grid xs={12} sx={{ pt: 3 }}>
              <ImgColors
                images={imagesList}
                setProdColor={setProdColor}
                selectedColor={selectedColor}
              />
            </Grid>
          )}
          {sizes?.length > 0 && (
            <Grid xs={12} sx={{ pt: 3 }}>
              <ProductSizes
                productSizes={sizes}
                handleSize={handleSize}
                selectedSize={selectedSize}
              />
            </Grid>
          )}
          {!isGuestUser && !isEmptyAddressList && (
            <Grid xs={12} sx={{ pt: 3 }}>
              <Delivery />
            </Grid>
          )}
          {!isGuestUser && isEmptyAddressList && (
            <Grid xs={12} md={12} sx={{ pt: 3 }}>
              <GuestPincode />
            </Grid>
          )}
          {isGuestUser && isEmptyAddressList && (
            <Grid xs={12} md={12} sx={{ pt: 3 }}>
              <GuestPincode />
            </Grid>
          )}
          <Grid xs={12} sx={{ pt: 3 }}>
            <Seller sellerName={brand} />
          </Grid>
          <Grid xs={12}>
            <Divider variant="fullWidth" sx={{ opacity: 5 }} />
          </Grid>
          <Grid xs={12} sx={{ pt: 3 }}>
            <ProductSpec selectedColor={selectedColor} />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
export default ProductDetailBody;
