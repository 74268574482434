import { configureStore } from "@reduxjs/toolkit";
import {
  categoriesReducer,
  homePageTileReducer,
  rootReducer,
} from "./reducers/rootReducer";
import { allProductsReducer } from "./reducers/productsList";
import { productDetailsReducer } from "./reducers/productDetails";
import { viewCartReducer } from "./reducers/viewCart";
import userReducer from "./reducers/user";
import { myOrdersReducer } from "./reducers/myOrders";
import { orderDetailsReducer } from "./reducers/orderDetails";
import { buyNowReducer } from "./reducers/buyNow";

export const store = configureStore({
  reducer: {
    test: rootReducer,
    categories: categoriesReducer,
    allProducts: allProductsReducer,
    productDetails: productDetailsReducer,
    AllCartItems: viewCartReducer,
    userDetails: userReducer,
    ordersHistory: myOrdersReducer,
    orderDetails: orderDetailsReducer,
    HomePageTiles: homePageTileReducer,
    buyNow: buyNowReducer,
  },
});
