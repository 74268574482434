import React from "react";
import Button from "@mui/material/Button";
import { AddToCartWrapper } from "./Addtocart.style";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

const AddToCartCTA = (props) => {
  const {
    AddtoCartItem,
    ATCText = "",
    isDeliverable = true,
    checkPinCodeAvailable,
  } = props;
  return (
    <AddToCartWrapper>
      <Button
        variant="contained"
        size="large"
        fullWidth
        color="ATC"
        disabled={!isDeliverable || !checkPinCodeAvailable}
        sx={{ color: "white", fontSize: "1rem", columnGap: "3%" }}
        onClick={AddtoCartItem}
      >
        <ShoppingCartOutlinedIcon />
        {ATCText}
      </Button>
    </AddToCartWrapper>
  );
};

export default AddToCartCTA;
