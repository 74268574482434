import {
  CLEAR_BUYNOW_DATA,
  GET_BUYNOW_DATA_FAIL,
  GET_BUYNOW_DATA_SUCCESS,
  INITIATE_BUYNOW_CALL,
} from "../actions";

const initialState = {
  isLoading: false,
  buyNowData: {},
  isError: false,
  alert: {
    isAlert: false,
    type: "",
    msg: "",
  },
};

export const buyNowReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case INITIATE_BUYNOW_CALL:
      return {
        ...state,
        isLoading: true,
        isError: false,
        alert: { ...initialState?.alert },
      };
    case GET_BUYNOW_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        alert: {
          ...state.alert,
          isAlert: true,
          type: "error",
          msg: "Something went wrong",
        },
      };
    case GET_BUYNOW_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        buyNowData: { ...payload },
        alert: {
          ...initialState.alert,
        },
      };
    case CLEAR_BUYNOW_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
