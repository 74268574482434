import React, { useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CheckoutAddressGroup from "./AddressGroup";
import CircularProgress from "@mui/material/CircularProgress";
import AddOrEditAddress from "../../Shared/AddOrEditAddress";

const CheckoutAddress = (props) => {
  const { currentStep = {}, stepAction = () => {} } = props;
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [enableAddAddress, setEnableAddAddress] = useState(false);
  const { step = 0 } = currentStep;
  const userProfile = useSelector((state) => state.userDetails || {});

  const { isGuest = true } = userProfile;

  const addNewAddressClick = () => {
    setEnableAddAddress(true);
  };
  const disableAddAddress = () => {
    setEnableAddAddress(false);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            p: 2,
            pb: 0,
            pt: 0,
          },
        }}
      >
        <Paper elevation={2}>
          <Grid container direction={"row"} sx={{ p: 1 }}>
            {step !== 2 && (
              <>
                <Grid xs={10} md={10}>
                  <Grid>
                    <Typography variant="body" component="div" fontWeight={500}>
                      DELIVERY ADDRESS
                    </Typography>
                  </Grid>
                </Grid>
                {!isGuest && (
                  <Grid xs={2} md={2} sx={{ alignSelf: "center" }}>
                    <Button variant="outlined" onClick={() => stepAction(2)}>
                      Change
                    </Button>
                  </Grid>
                )}
              </>
            )}
            {step === 2 && (
              <>
                {isLoading ? (
                  <Grid xs={12} md={12}>
                    <Grid>
                      <Typography
                        variant="body"
                        component="div"
                        fontWeight={500}
                      >
                        DELIVERY ADDRESS
                      </Typography>
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress />
                    </Box>
                  </Grid>
                ) : (
                  <Grid xs={12} md={12}>
                    <Grid>
                      <Typography
                        variant="body"
                        component="div"
                        fontWeight={500}
                      >
                        DELIVERY ADDRESS
                      </Typography>
                    </Grid>
                    <Grid container>
                      {isGuest ? (
                        <Grid xs={12} md={12} sx={{ textAlign: "center" }}>
                          {!enableAddAddress && (
                            <Button
                              variant="outlined"
                              sx={{ pt: 1, pb: 1 }}
                              onClick={addNewAddressClick}
                            >
                              Add A New Address
                            </Button>
                          )}
                          {enableAddAddress && (
                            <AddOrEditAddress
                              action="add"
                              disableAddAddress={disableAddAddress}
                            />
                          )}
                        </Grid>
                      ) : (
                        <Grid xs={12} md={12}>
                          <CheckoutAddressGroup stepAction={stepAction} />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default CheckoutAddress;
