import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4eb7cf",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: "#ef5350",
    },
    success: {
      main: "#4caf50",
    },
    info: {
      main: "#03a9f4",
    },
    header: {
      main: "#4eb7cf",
    },
    ATC: {
      main: "#ffa726",
      light: "#ffb851",
    },
    buynow: {
      main: "#fb641b",
    },
    login: {
      main: "#fb641b",
    },
    textcolor: {
      main: "#ffff",
    },
    discountText: {
      main: "#388e3c",
    },
  },
});

export default theme;
