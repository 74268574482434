import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export default function ProductListSkeleton() {
  return (
    <Grid container wrap="nowrap" sx={{ alignSelf: "center" }} spacing={2}>
      <Grid xs={"none"} md={2} sx={{ mr: 2 }}>
        {Array.from(new Array(3)).map((item, index) => (
          <Box key={index} sx={{ marginRight: 0.5, my: 5 }}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem" }}
              width={210}
              height={60}
            />
            {/* For other variants, adjust the size with `width` and `height` */}
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Box>
        ))}
      </Grid>
      <Grid xs={12} md={9} sx={{ display: "inline-flex", flexFlow: "wrap" }}>
        {Array.from(new Array(4)).map((item, index) => (
          <>
            <Box key={index} sx={{ width: "20%", marginRight: 0.5, my: 5 }}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton
                variant="rectangular"
                width={80}
                height={40}
                sx={{ m: 1 }}
              />
              <Skeleton variant="rectangular" height={60} />
              <Skeleton variant="rounded" height={60} />
              <Skeleton variant="rounded" height={60} />
            </Box>
            <Box key={index} sx={{ width: "20%", marginRight: 0.5, my: 5 }}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              {/* For other variants, adjust the size with `width` and `height` */}
              <Skeleton
                variant="rectangular"
                width={80}
                height={40}
                sx={{ m: 1 }}
              />
              <Skeleton variant="rectangular" height={60} />
              <Skeleton variant="rounded" height={60} />
              <Skeleton variant="rounded" height={60} />
            </Box>
          </>
        ))}
      </Grid>
    </Grid>
  );
}
