import * as React from "react";
import Typography from "@mui/material/Typography";
import { CatMenuListWrapper, CatMenuWrapperWithoutImg } from "../catalog.style";
import { Link } from "react-router-dom";

export default function CatMenuWithoutImg(props) {
  const { element = {} } = props;
  const { name: Title = "", children = [] } = element || {};

  return (
    <CatMenuWrapperWithoutImg className="menu-drop-down">
      <Typography variant="body" color={"GrayText"} gutterBottom>
        {Title}
      </Typography>
      <CatMenuListWrapper className="menu-drop-down-list">
        {children?.length > 0 &&
          children?.map((child, idx) => (
            <Link
              to={`/products/${child?.id}`}
              key={`${child?.id}${idx}`}
              className="menu-list-link"
            >
              {child?.name}
            </Link>
          ))}
      </CatMenuListWrapper>
    </CatMenuWrapperWithoutImg>
  );
}
