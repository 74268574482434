import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
// import MoreIcon from "@mui/icons-material/MoreVert";
import { HeaderNavBar } from "./Header.style";
import LogoWrapper from "../LogoWrapper";
import SearchAppBar from "./SearchAppBar";
import { Link, useLocation } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { clearAuthDetails, setIsLoggedIn } from "../../../actions";
import { decodeUserToken } from "../../../utils";
// import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";

const Header = () => {
  const dispatch = useDispatch();
  const { isAuthenticated = true } = decodeUserToken();
  // eslint-disable-next-line no-unused-vars
  const cartItemslist = useSelector(
    (state) => state.AllCartItems?.AllCartData || {}
  );
  const userDetails = useSelector((state) => state.userDetails || {});
  const { userProfile = {} } = userDetails;
  const { fullName = "", isAuthUser = false } = userProfile;
  const splitFirstName = (fullName?.length && fullName?.split(" ")[0]) || "";
  // eslint-disable-next-line no-unused-vars
  const [auth, setAuth] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [cartView, setCartView] = useState(false);
  const [checkoutView, setCheckoutView] = useState(false);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItems = (e) => {
    const text = e.currentTarget?.innerText || "";
    if (text === "Logout") {
      localStorage.removeItem("userToken");
      dispatch(clearAuthDetails());
      window.location.href = "/";
    }
  };
  const params = useLocation();
  const cartPath = params?.pathname?.split("/")[1] || "";

  useEffect(() => {
    if (cartPath === "viewcart" || cartPath === "buynow") {
      setCartView(true);
    } else if (cartPath === "checkout") {
      setCheckoutView(true);
    } else {
      setCartView(false);
      setCheckoutView(false);
    }
    if (!isAuthUser && !isAuthenticated) {
      dispatch(setIsLoggedIn(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartPath, cartView, checkoutView]);

  useEffect(() => {
    setAuth(isAuthUser);
    if (!isAuthUser && !isAuthenticated) {
      dispatch(setIsLoggedIn(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthUser]);

  return (
    <HeaderNavBar id="app-bar-header">
      <AppBar position="static" id="app-bar" className="app-bar">
        <Container maxWidth="xl">
          <Toolbar disableGutters className="toolbar">
            <Link to="/" className="header_logo">
              <LogoWrapper className="logoWrapper" />
              <Box sx={{ flexGrow: 0.5 }}>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  sx={{
                    mr: 2,
                    display: { xs: "flex", md: "flex" },
                    fontFamily: "monospace",
                    fontWeight: 500,
                    letterSpacing: ".2rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  Clan
                </Typography>
              </Box>
            </Link>
            {!checkoutView && (
              <>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  <SearchAppBar />
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Account Details">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      {!auth ? (
                        <>
                          <AccountCircleOutlinedIcon
                            sx={{
                              color: "white",
                              mr: 1,
                            }}
                          />
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, color: "white" }}
                          >
                            Profile
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ flexGrow: 1, color: "white" }}
                        >
                          {splitFirstName?.toUpperCase() || "Login"}
                        </Typography>
                      )}
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {auth && (
                      <MenuItem key={"MyProfile"} onClick={handleCloseUserMenu}>
                        <Link
                          to={`/account`}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography textAlign="center" color="text.primary">
                            {"My Profile"}
                          </Typography>
                        </Link>
                      </MenuItem>
                    )}
                    <MenuItem key={"Orders"} onClick={handleCloseUserMenu}>
                      <Link
                        to={`${!auth ? "/login" : "/myaccount/orders"}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography textAlign="center" color="text.primary">
                          {"Orders"}
                        </Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem key={"WishList"} onClick={handleCloseUserMenu}>
                      <Link
                        to={`${!auth ? "/login" : "/account?AM=wishlist"}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography textAlign="center" color="text.primary">
                          {"WishList"}
                        </Typography>
                      </Link>
                    </MenuItem>
                    {!auth && (
                      <MenuItem key={"Login"} onClick={handleCloseUserMenu}>
                        <Link to={`/login`} style={{ textDecoration: "none" }}>
                          <Typography textAlign="center" color="text.primary">
                            {"Login"}
                          </Typography>
                        </Link>
                      </MenuItem>
                    )}
                    {auth && (
                      <MenuItem key={"Logout"} onClick={handleMenuItems}>
                        <Link to={`/`} style={{ textDecoration: "none" }}>
                          <Typography textAlign="center" color="text.primary">
                            {"Logout"}
                          </Typography>
                        </Link>
                      </MenuItem>
                    )}
                  </Menu>
                </Box>
                {!cartView && (
                  <React.Fragment>
                    <Box sx={{ flexGrow: 0, display: "block" }}>
                      <Tooltip title="Cart">
                        <IconButton
                          size="small"
                          aria-label="show cart"
                          color="inherit"
                        >
                          <Badge
                            badgeContent={cartItemslist?.length}
                            color="error"
                          >
                            <Link to="/viewcart">
                              <AddShoppingCartOutlinedIcon
                                sx={{
                                  mr: 2,
                                  ml: 2,
                                  display: { xs: "flex", md: "flex" },
                                  fontFamily: "monospace",
                                  fontWeight: 700,
                                  letterSpacing: ".3rem",
                                  color: "white",
                                  textDecoration: "none",
                                }}
                              />
                            </Link>
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    </Box>
                    {/* <Box sx={{ flexGrow: 0, display: "block" }}>
                      <Tooltip title="Become a Seller">
                        <IconButton
                          size="small"
                          aria-label="show cart"
                          color="inherit"
                        >
                          <a
                            href="https://clanadminportal-test.up.railway.app/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <StorefrontOutlinedIcon
                              sx={{
                                display: { xs: "flex", md: "flex" },
                                fontFamily: "monospace",
                                fontWeight: 700,
                                letterSpacing: ".3rem",
                                color: "white",
                                textDecoration: "none",
                              }}
                            />
                          </a>
                        </IconButton>
                      </Tooltip>
                    </Box> */}
                    {/* <Box
                      sx={{ flexGrow: 0, display: { xs: "none", sm: "block" } }}
                    >
                      <Tooltip title="More">
                        <IconButton
                          size="large"
                          aria-label="display more actions"
                          edge="end"
                          color="inherit"
                        >
                          <MoreIcon />
                        </IconButton>
                      </Tooltip>
                    </Box> */}
                  </React.Fragment>
                )}
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {!checkoutView && (
        <Box
          sx={{
            flexGrow: 1,
            p: 1,
            color: "black",
            backgroundColor: "white",
            display: { xs: "flex", md: "none", sm: "none" },
          }}
        >
          <SearchAppBar placeHolder={"Search for Products, Brands"} />
        </Box>
      )}
    </HeaderNavBar>
  );
};

export default Header;
