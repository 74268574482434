import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSnackbars from "../Shared/SnackBar";
import { getAuthCredentails, verifyAuthCredentails } from "../../utils";
import { useNavigate } from "react-router-dom";
import {
  getAuthDetails,
  isMergeCartEligible,
  setIsLoggedIn,
} from "../../actions";
import Constants from "../../constants";
import { getAPIService } from "../API";

const AuthorizeOrGuestLogin = (props) => {
  const { stepAction = () => {} } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfileInfo = useSelector((state) => state.userDetails || {});
  const { userProfile = {}, isGuest = true } = userProfileInfo;
  const { fullName = "", phoneNo = "" } = userProfile;

  const [checkoutLogout, setCheckoutLogout] = useState(false);
  const [enableFieldError, setEnableFieldError] = useState(false);
  const [enableCreateAccountCTA, setEnableCreateAccountCTA] = useState(false);
  const [enableVerify, setEnableVerify] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [authCreds, setAuthCreds] = useState({});
  const [alertOpen, setAlertOpen] = React.useState({
    isAlert: false,
    msg: "",
    type: "",
  });

  const handleLogout = () => {
    setCheckoutLogout(true);
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    setAlertOpen({
      isAlert: false,
      msg: "",
      type: "",
    });
    setOtp("");
    setEnableFieldError(false);
    setEnableVerify(false);
    setEnableCreateAccountCTA(false);
    setEmail(e.target.value);
  };

  const handleOptChange = (e) => {
    e.preventDefault();
    setAlertOpen({
      isAlert: false,
      msg: "",
      type: "",
    });
    setOtp(e.target.value);
  };

  const validateEmail = async () => {
    const currEmail = email;
    if (!currEmail?.length) {
      setAlertOpen({
        isAlert: true,
        msg: "Enter valid Email",
        type: "error",
      });
    }
    const regexForEmail =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (currEmail.match(regexForEmail)) {
      setOtp("");
      const validateEmailResonse = await getAuthCredentails({
        emailID: email,
        type: "login",
      });
      const { status = 0 } = validateEmailResonse;
      const {
        response: {
          data: {
            error = "",
            errorMessage = "",
            status: errorStatus = 100,
          } = {},
        } = {},
      } = validateEmailResonse;

      if (status === 200) {
        setAlertOpen({
          isAlert: true,
          msg: "Sent Code",
          type: "success",
        });
        setEnableVerify(true);
      } else if (errorStatus === 400 && error === "Bad Request") {
        setEnableFieldError(true);
        setAlertOpen({
          isAlert: true,
          msg: `${errorMessage} Create an account or Try different email`,
          type: "error",
        });
        setEnableCreateAccountCTA(true);
      } else {
        setAlertOpen({
          isAlert: true,
          msg: "Something went wrong, try again",
          type: "error",
        });
      }
    } else {
      setAlertOpen({
        isAlert: true,
        msg: "Enter valid Email",
        type: "error",
      });
    }
  };

  const getUserDetails = async (data) => {
    const URL = `${Constants.user.getUserDetails}`;
    const headers = {
      token: `${data}`,
    };
    return await getAPIService({ url: URL, headers })
      .then((response) => {
        const { status = 0, data = {} } = response || {};
        if (status === 200) {
          dispatch(getAuthDetails({ ...data, isAuthUser: true }));
          return true;
        } else if (status !== 200) {
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateEmailOTPAndContinue = async () => {
    const currOTP = otp;
    if (!currOTP?.length || currOTP?.length < 5) {
      setAlertOpen({
        isAlert: true,
        msg: "Enter valid OTP",
        type: "error",
      });
    }

    const verifyOtpResones = await verifyAuthCredentails({
      emailID: email,
      type: "login",
      userOTP: otp,
      isMergeCart: true,
    });
    const { data = "", status = 100 } = verifyOtpResones;
    if (status === 200) {
      const { token: Token = "" } = data;
      setAuthCreds(data);
      localStorage.setItem("userToken", JSON.stringify(data));
      dispatch(isMergeCartEligible(true));
      //Need to set loader
      const userDetailsResponse = await getUserDetails(Token);
      if (userDetailsResponse) {
        dispatch(setIsLoggedIn(false));
        stepAction(2);
      }
    } else {
      setAlertOpen({
        isAlert: true,
        msg: "Something went wrong, try again",
        type: "error",
      });
    }
  };

  const handleNewAccount = () => {
    if (window !== undefined) {
      navigate("/signup");
    }
  };
  useEffect(() => {
    if (isGuest) {
      setCheckoutLogout(true);
    }
  }, [isGuest]);

  return (
    <React.Fragment>
      <Grid xs={12} md={12}>
        <Grid container direction={"row"}>
          <Grid xs={12} md={12} pb={1}>
            <Typography variant="body" component="div" fontWeight={500}>
              LOGIN
            </Typography>
          </Grid>
          {!checkoutLogout && (
            <Grid xs={12} md={6} pr={2}>
              <Grid container direction={"column"}>
                <Grid item>
                  <Typography
                    variant="body"
                    component="div"
                    color="text.secondary"
                    fontWeight={500}
                  >
                    {`${`Name: `} ${fullName}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body"
                    component="div"
                    color="text.secondary"
                    fontWeight={500}
                  >
                    {`${`Phone:`} ${phoneNo}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button onClick={handleLogout} sx={{ pl: 0 }}>
                    Logout & Sign in to another account
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="buynow"
                    sx={{ color: "white" }}
                    onClick={() => stepAction(2)}
                  >
                    continue checkout
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {checkoutLogout && (
            <Grid xs={12} md={4} pr={2}>
              <Grid container direction={"column"} gap={1}>
                <Grid item>
                  <TextField
                    size="small"
                    error={enableFieldError}
                    fullWidth
                    id="login-email"
                    label="Enter Email"
                    variant="standard"
                    onChange={handleEmailChange}
                    value={email}
                  />
                </Grid>
                {enableVerify && (
                  <Grid item>
                    <TextField
                      size="small"
                      fullWidth
                      id="login-passcode"
                      label="Enter OTP"
                      variant="standard"
                      onChange={handleOptChange}
                      disabled={!enableVerify}
                      value={otp}
                      type="number"
                    />
                  </Grid>
                )}
                {!enableVerify && (
                  <Grid item pt={1}>
                    <Button
                      variant="contained"
                      color="buynow"
                      sx={{ color: "white", marginRight: 1 }}
                      onClick={validateEmail}
                    >
                      Request OTP
                    </Button>
                    {enableCreateAccountCTA && (
                      <Button
                        variant="text"
                        color="buynow"
                        sx={{ color: "primary" }}
                        onClick={handleNewAccount}
                      >
                        Create New Account
                      </Button>
                    )}
                  </Grid>
                )}
                {enableVerify && (
                  <Grid item pt={1}>
                    <Button
                      variant="contained"
                      color="buynow"
                      sx={{ color: "white" }}
                      onClick={validateEmailOTPAndContinue}
                    >
                      Verify
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {checkoutLogout && <Grid xs={"none"} md={2}></Grid>}
          <Grid xs={"none"} md={6} sx={{ pt: 0 }}>
            <Grid container direction={"column"} spacing={2}>
              <Grid xs={12} md={12}>
                <Typography
                  variant="body"
                  component="div"
                  color="text.secondary"
                >
                  {`${"Advantages of our secure login"}`}
                </Typography>
              </Grid>
              <Grid xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <LocalShippingIcon color="primary" />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body"
                      component="div"
                      color="text.secondary"
                    >
                      {`${"Easily Track Orders, Hassle free Returns"}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} md={12}>
            {alertOpen?.isAlert && (
              <CustomizedSnackbars
                text={alertOpen?.msg}
                isOpen={alertOpen?.isAlert}
                type={alertOpen?.type}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AuthorizeOrGuestLogin;
