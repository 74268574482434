import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Seller = (props) => {
  const { sellerName = "" } = props;
  return (
    <React.Fragment>
      <Stack
        direction="row"
        spacing={4}
        sx={{
          alignItems: "flex-end",
          pt: 2,
          pb: 2,
        }}
      >
        <Typography variant="h6">Seller</Typography>
        <Typography variant="body">{sellerName}</Typography>
      </Stack>
    </React.Fragment>
  );
};

export default Seller;
