import { chooseToken } from "../../../utils";

export const getOrderPayload = ({ defaultAddress = {}, AllCartItems = {} }) => {
  const { address = {}, default: defAddress = false } = defaultAddress;
  const { userId = "" } = chooseToken();
  const {
    cartPriceDetail = {},
    shipments = [],
    cartId = "",
    isQuickCart = false,
  } = AllCartItems || {};

  /*  const passObj = (details) => {
    return details?.reduce((prev, curr) => {
      let obj = {
        ...curr,
        rate: {
          ...curr.rate,
          logisticInfo: {
            ...curr.rate.logisticInfo,
            logisticName: "Delhivery",
          },
        },
      };
      prev.push(obj);
      return prev;
    }, []);
  };
 */
  const combileShipments = shipments?.reduce((prev, curr) => {
    // let checkCurr = passObj(curr?.cartItemDetails);

    let shipment = {
      cartItemDetails: [...curr?.cartItemDetails],
      rate: {
        ...curr?.rate,
        logisticInfo: {
          ...curr?.rate?.logisticInfo,
          logisticName: "Delhivery",
        },
      },
      sourceContactDetailId: curr?.sourceContactDetailId || "",
      vendorId: curr?.vendorId || "",
    };
    prev.push(shipment);
    return prev;
  }, []);

  let payload = {
    userId: isQuickCart ? userId : cartId,
  };

  if (Object.keys(address).length) {
    payload.shipToAddress = {
      ...address,
      isDefaultAddress: defAddress,
    };
  }

  if (Object.keys(cartPriceDetail).length) {
    payload.cartPriceDetail = {
      ...cartPriceDetail,
    };
  }

  if (combileShipments?.length) {
    payload.shipments = [...combileShipments];
  }

  return {
    ...payload,
  };
};

export const getPaymentPayload = (data, selectedAddress) => {
  const {
    address = {},
    email = "",
    fullName = "",
    phoneNo = "",
  } = selectedAddress;

  const { amount = 10, id = "" } = data;
  const options = {
    key: "rzp_test_mS7B6c2rLO1bGI", // Enter the Key ID generated from the Dashboard
    amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: "clan", //your business name
    description: "Test payment",
    order_id: id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    prefill: {
      //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
      name: fullName, //your customer's name
      email: email,
      contact: phoneNo, //Provide the customer's phone number for better conversion rates
    },
    notes: {
      address: JSON.stringify(address),
    },
    theme: {
      color: "#4eb7cf",
      hide_topbar: true,
    },
    modal: {
      animation: true,
    },
  };

  return options;
};
