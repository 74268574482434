import { Grid, Typography } from "@mui/material";
import React from "react";

const OrderSuccessPage = () => {
  return (
    <React.Fragment>
      <Grid container>
        <Typography variant="h5">Order Placed SuccessFully</Typography>
      </Grid>
    </React.Fragment>
  );
};

export default OrderSuccessPage;
