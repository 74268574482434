import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import ColorImage from "./ColorImage";
import Button from "@mui/material/Button";

const ImgColors = (props) => {
  const { images = [], setProdColor = () => {}, selectedColor = "" } = props;

  return (
    <React.Fragment>
      <Grid container spacing={2} direction={"row"}>
        <Grid xs={2} md={2}>
          <Typography variant="body" fontWeight={"bold"}>
            Colors
          </Typography>
        </Grid>
        <Grid xs={10} md={10}>
          <Grid container spacing={2} gap={2}>
            {images?.length > 0 &&
              images?.map((image, idx) => (
                <Button
                  onClick={() => setProdColor(image?.colorKey)}
                  id={image?.colorKey}
                  key={image?.colorKey}
                  sx={{
                    border:
                      image?.colorKey === selectedColor
                        ? "0.5px solid"
                        : "none",
                  }}
                >
                  <ColorImage image={image} key={idx + 1} />
                </Button>
              ))}
          </Grid>
        </Grid>
        {images?.length > 0 && !selectedColor && (
          <Grid xs={12} item>
            <Typography variant="body" color={"error"} fontWeight={"bold"}>
              Select Color
            </Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ImgColors;
