import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
// import Avatar from "@mui/material/Avatar";

const AccountsMenu = () => {
  const userProfile = useSelector(
    (state) => state.userDetails?.userProfile || {}
  );
  const { fullName = "" } = userProfile;
  const uppercaseFullName = fullName?.toUpperCase() || "";

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    if (window) {
      window.location.href = "/";
    }
  };

  return (
    <React.Fragment>
      <Grid container direction={"column"} gap={2}>
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <Paper variant="outlined" elevation={3} square>
            <Grid
              container
              spacing={2}
              direction={"row"}
              sx={{ display: "inline-flex" }}
            >
              {/* <Grid xs={2} sx={{ alignContent: "space-evenly" }}>
                <Avatar alt="A" src="/static/images/avatar/1.jpg" />
              </Grid> */}
              <Grid xs={12}>
                <Typography variant="body2" fontWeight={500}>
                  Hello,
                </Typography>
                <Typography variant="h6" fontWeight={500}>
                  {uppercaseFullName}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Paper variant="outlined" elevation={3} square>
            <Grid sx={{ p: 1 }}>
              <Grid>
                <Typography variant="h6" fontWeight={500}>
                  <Link to="/myaccount/orders" className="route-link-black">
                    <ListItemButton>
                      <ListItemText primary="MY ORDERS" />
                    </ListItemButton>
                  </Link>
                </Typography>
              </Grid>
              <Divider />
              <Grid sx={{ pt: 2 }}>
                <Grid>
                  <Typography variant="h6" fontWeight={500}>
                    ACCOUNT SETTINGS
                  </Typography>
                </Grid>
                <Grid>
                  <Link to="?AM=profile" className="route-link-black">
                    <ListItemButton>
                      <ListItemText primary="Profile Information" />
                    </ListItemButton>
                  </Link>
                </Grid>
                <Grid>
                  <Link to="?AM=address" className="route-link-black">
                    <ListItemButton>
                      <ListItemText primary="Manage Addressess" />
                    </ListItemButton>
                  </Link>
                </Grid>
                <Grid>
                  <Link to="?AM=wishlist" className="route-link-black">
                    <ListItemButton>
                      <ListItemText primary="My Wishlist" />
                    </ListItemButton>
                  </Link>
                </Grid>
              </Grid>
              {/* <Grid sx={{ pt: 2 }}>
                <Grid>
                  <Typography variant="h6" fontWeight={500}>
                    PAYMENTS
                  </Typography>
                </Grid>
                <Grid>
                  <Link to="?AM=carddetails" style={{ textDecoration: "none" }}>
                    <ListItemButton>
                      <ListItemText primary="Saved Cards" />
                    </ListItemButton>
                  </Link>
                </Grid>
              </Grid> */}
              <Divider />
              <Grid>
                <Grid sx={{ p: 2 }}>
                  <Button variant="text" size="large" onClick={handleLogout}>
                    Logout
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default AccountsMenu;
