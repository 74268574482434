import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";

const ProductSizes = (props) => {
  const { productSizes = [], handleSize = () => {}, selectedSize = "" } = props;
  const existSizes = ["L", "M", "S", "XL", "XXL", "XXXL"];

  return (
    <React.Fragment>
      <Grid container spacing={2} direction={"row"}>
        <Grid xs={2} md={2}>
          <Typography variant="body" fontWeight={"bold"}>
            Sizes
          </Typography>
        </Grid>
        <Grid xs={10} md={10}>
          <Grid container spacing={2}>
            {productSizes?.length > 0 &&
              productSizes?.map((sizeVal) => {
                if (existSizes?.includes(sizeVal)) {
                  return (
                    <Grid>
                      <Button
                        variant="text"
                        size="large"
                        sx={{
                          p: 1,
                          border:
                            sizeVal === selectedSize ? "1px solid" : "none",
                        }}
                        onClick={() => handleSize(sizeVal)}
                      >
                        {sizeVal}
                      </Button>
                    </Grid>
                  );
                }
                return false;
              })}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default ProductSizes;
