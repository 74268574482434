import styled from "styled-components";
import { styled as MUIStyled } from "@mui/material/styles";

const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

/* const FooterWrapper = styled.div`
  background: #e4ecef8f;
  bottom: 0;
`; */

const AppContainer = MUIStyled("div")(({ theme }) => ({}));

export { AppWrapper, AppContainer };
