import "./product-image-slider.css";
import PropTypes from "prop-types";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import { useState } from "react";

const ProductDetailsImgSlider = (props) => {
  const { image = [] } = props;
  const [activeThumb, setActiveThumb] = useState();

  return (
    <div className="pdp-container">
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "max-width": "495px",
        }}
        spaceBetween={10}
        navigation={true}
        modules={[Navigation, Thumbs]}
        grabCursor={true}
        thumbs={{ swiper: activeThumb }}
        className="product-images-slider"
      >
        {image?.length > 0 &&
          image?.map((item, index) => (
            <SwiperSlide key={index}>
              <img src={item} alt="product images" />
            </SwiperSlide>
          ))}
      </Swiper>

      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "max-width": "495px",
          "max-heigth": "180px",
        }}
        onSwiper={setActiveThumb}
        spaceBetween={10}
        slidesPerView={4}
        modules={[Navigation, Thumbs]}
        className="product-images-slider-thumbs"
      >
        {image?.length > 0 &&
          image?.map((item, index) => (
            <SwiperSlide key={index}>
              <img
                src={item}
                alt="product-images"
                className="image-thumbnail"
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

ProductDetailsImgSlider.propTypes = {
  image: PropTypes.array.isRequired,
};

export default ProductDetailsImgSlider;
