import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Divider, Grid, Typography } from "@mui/material";
import { DrawerWrapper } from "./drawer.style";

const DrawerComponent = (props) => {
  const {
    isOpen = false,
    setIsOpen = () => {},
    title = "",
    body,
    footer,
  } = props;

  const toggleDrawer = (newOpen) => () => {
    setIsOpen(newOpen);
  };

  return (
    <React.Fragment>
      <Drawer open={isOpen} onClose={toggleDrawer(false)} anchor="right">
        <Box
          sx={{
            minWidth: "400px",
            maxWidth: "320px",
            height: "-webkit-fill-available",
          }}
          role="presentation"
        >
          <DrawerWrapper>
            <Grid container spacing={2} direction={"column"} m={0}>
              <Grid xs={12} item>
                <Typography variant="h5" textAlign={"center"}>
                  {title}
                </Typography>
                <Divider />
              </Grid>
              <Grid xs={12} item>
                {body}
              </Grid>
              <Grid xs={12} item>
                {footer}
              </Grid>
            </Grid>
          </DrawerWrapper>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default DrawerComponent;
