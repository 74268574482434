import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import { PlaceOrderCTAContainer } from "./PlaceOrderCTA.style";
import { Link } from "react-router-dom";

const PlaceOrderCTA = () => {
  return (
    <PlaceOrderCTAContainer>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            p: 2,
          },
        }}
      >
        <Paper elevation={3}>
          <Grid container direction={"row"} sx={{ flexFlow: "row-reverse" }}>
            <Link to="/checkout">
              <Button
                variant="contained"
                size="large"
                sx={{ color: "white" }}
                color="buynow"
              >
                Checkout
              </Button>
            </Link>
          </Grid>
        </Paper>
      </Box>
    </PlaceOrderCTAContainer>
  );
};

export default PlaceOrderCTA;
