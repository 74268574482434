import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  getProductRateDetails,
  isPincodeAvailable,
  setDefaultAddress,
  setUpdatePincode,
} from "../../../actions";
import TextField from "@mui/material/TextField";
import { getDeliveryRate } from "../../../utils";

const DeliveryAddressSelect = (props) => {
  const {
    DialogClose = () => {},
    AddressList = [],
    currentPincode = "",
    isGuest = true,
  } = props;
  const dispatch = useDispatch();
  const [radioSelection, SetRadioSelection] = useState("");
  const [pinCode, setPincode] = useState(currentPincode || "");
  const [fieldError, setFieldError] = useState(false);
  const [checkDelivery, setCheckDelivery] = useState(true);

  const currProductSKU = useSelector(
    (state) => state.productDetails?.prodDetails?.sku || ""
  );

  const handleChange = (e) => {
    SetRadioSelection(e.target.value);
    const selectedAddress = AddressList?.find(
      (Item) => Item.contactDetailId === e.target.value
    );
    dispatch(setDefaultAddress(selectedAddress));
    DialogClose();
  };

  const pincodeChange = (e) => {
    setCheckDelivery(true);
    setPincode(e.target.value);
  };

  const getProductDelRate = async () => {
    if (!pinCode?.length || pinCode?.length < 5) {
      setFieldError(true);
    } else {
      const rateDetails = await getDeliveryRate({
        sku: currProductSKU,
        pinCode,
      });
      const { status = 100, data = "" } = rateDetails;
      if (status === 200) {
        const { isDeliverable = true } = data;
        if (isDeliverable) {
          dispatch(isPincodeAvailable(true));
          localStorage.setItem("pincode", JSON.stringify(pinCode));
          dispatch(getProductRateDetails(data));
          dispatch(setUpdatePincode(pinCode));
          DialogClose();
        } else {
          setCheckDelivery(false);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            maxWidth: "250px",
            p: 1,
            pb: 0,
            pt: 0,
          },
        }}
      >
        <Grid container gap={1}>
          {!isGuest && (
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioSelection}
                onChange={handleChange}
              >
                {AddressList?.length > 0 &&
                  AddressList.map((address) => (
                    <AddressRadioGroup address={address} />
                  ))}
              </RadioGroup>
            </FormControl>
          )}
          {isGuest && (
            <>
              <Grid container spacing={2} direction={"row"}>
                <Grid xs={6} md={6}>
                  <TextField
                    id="outlined-error"
                    label="Enter Pincode"
                    error={fieldError}
                    value={pinCode}
                    size="small"
                    fullWidth
                    variant="standard"
                    onChange={pincodeChange}
                  />
                </Grid>
                <Grid xs={4} md={6} item>
                  <Button onClick={getProductDelRate}>Check</Button>
                </Grid>
                <Grid item>
                  {!checkDelivery && (
                    <Typography variant="body" color={"red"}>
                      Delivery not available at this Pincode, try another.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default DeliveryAddressSelect;

export const AddressRadioGroup = (props) => {
  const { address = {} } = props;
  const {
    contactDetailId = "",
    address: userAddress = {},
    fullName = "",
  } = address;
  const {
    pinCode = "",
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    state = "",
  } = userAddress;
  return (
    <React.Fragment>
      <FormControlLabel
        value={contactDetailId}
        control={<Radio />}
        sx={{ alignItems: "start" }}
        label={
          <>
            <Grid
              container
              spacing={2}
              direction={"column"}
              sx={{ p: 0, m: 0, pb: 3 }}
              rowSpacing={1}
            >
              <Grid>
                <Typography
                  variant="body"
                  component="div"
                  color="black"
                  fontWeight={"bold"}
                >
                  {`${fullName}, ${pinCode}`}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  variant="body"
                  component="div"
                  color="text.secondary"
                  fontWeight={500}
                >
                  {`${addressLine1}, ${
                    addressLine2 && addressLine2
                  }, ${city}, ${state}, ${pinCode}`}
                </Typography>
              </Grid>
            </Grid>
          </>
        }
      />
    </React.Fragment>
  );
};
