import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import AccountsMenu from "./AccountsMenu";
import { useSearchParams } from "react-router-dom";
import AccountsBody from "./AccountsBody";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const MyProfile = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("AM");

  return (
    <React.Fragment>
      <Grid
        container
        direction={"row"}
        spacing={2}
        sx={{
          pt: 2,
          m: 0,
        }}
      >
        <Grid xs={"none"} md={3} sx={{ pr: 2 }}>
          <AccountsMenu />
        </Grid>
        <Grid xs={12} md={9}>
          <Grid container direction={"column"}>
            <Box sx={{ flexGrow: 1, textAlign: "center" }}>
              <Paper variant="outlined" elevation={3} square>
                <AccountsBody text={query} />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default MyProfile;
