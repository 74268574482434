import React, { useEffect, useState } from "react";
import Constants from "../../constants";
import { getAPIService, postAPIService } from "../API";
import { decodeUserToken } from "../../utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import OHSkeleton from "../Skeletons/OHSkeleton";
import HoverRating from "./rating";
import CustomizedSnackbars from "../Shared/SnackBar";

const Review = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const orderNumQuery = searchParams.get("orderId");
  const { orderReleaseList = [] } = orderDetails;

  const getOrderDetails = async () => {
    setError(false);
    const URL = `${Constants.orders.getOrderDetails}?orderNumber=${orderNumQuery}`;
    const response = await getAPIService({ url: URL });
    const { status = 100, data = {} } = response;
    if (status === 200) {
      setOrderDetails(data);
      setLoading(false);
      setError(false);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    getOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth={"sm"}>
        <Grid
          container
          spacing={2}
          direction={"column"}
          sx={{ m: 0, mt: 2, mb: 2 }}
        >
          <Grid xs={12}>
            {loading && <OHSkeleton />}
            {!loading && (
              <Paper elevation={1} sx={{ border: "1px #D5D9D9 solid" }}>
                <Grid
                  container
                  direction={"column"}
                  columnGap={2}
                  justifyContent={"center"}
                >
                  <Grid xs={12} sx={{ textAlign: "center", p: 3 }}>
                    <Typography
                      fontWeight={500}
                    >{`Review Your Order`}</Typography>
                  </Grid>
                  <Grid xs={12} sx={{ p: 2 }}>
                    {orderReleaseList?.length > 0 &&
                      orderReleaseList?.map((SingleItem) => (
                        <SingleOrderReviewTile itemDetails={SingleItem} />
                      ))}
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
export default Review;

export const SingleOrderReviewTile = (props) => {
  const { itemDetails = {} } = props;
  const { orderItems = [] } = itemDetails;
  return (
    <>
      {orderItems?.length > 0 &&
        orderItems?.map((item) => <SingleTileReview item={item} />)}
    </>
  );
};

export const SingleTileReview = (props) => {
  const { userId = "" } = decodeUserToken();
  const navigate = useNavigate();
  const { item = {} } = props;
  const { imageUrl, name } = item;
  const [value, setValue] = React.useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [reviewComments, setReviewComments] = useState("");
  // const [orderDetails, setOrderDetails] = useState({});
  const [alert, setAlert] = useState({
    isAlert: false,
    type: "",
    msg: "",
  });

  useEffect(() => {
    if (!value) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [value]);

  const handleComments = (e) => {
    let value = e.target.value;
    setReviewComments(value);
  };

  const submitProductReview = async ({ prdRating, comment, sku }) => {
    const URL = `${Constants.reviews.AddUserReview}`;
    const payload = {
      userId,
      rating: prdRating,
      comment: comment,
      sku: sku,
    };

    const response = await postAPIService({ url: URL, data: payload });
    const { status = 100 } = response;
    if (status === 200) {
      // setOrderDetails(data);
      setAlert({
        isAlert: true,
        type: "success",
        msg: "Rating Updated Successfully.",
      });
      setTimeout(() => {
        navigate("/myaccount/orders");
      }, 1000);
    } else {
      setAlert({
        isAlert: true,
        type: "error",
        msg: "Something went wrong, please try again.",
      });
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, m: 1 }}>
        <Grid container spacing={2} m={0} justifyContent={"flex-start"}>
          <Grid xs={3}>
            <img src={imageUrl} alt="delivery_img" height={80} width={80} />
          </Grid>
          <Grid xs={8}>
            <Typography variant="body" component="div" fontWeight={500}>
              {`${name}`}
            </Typography>
          </Grid>
          <Grid xs={12} pt={1}>
            <Typography
              variant="body"
              component="div"
              sx={{ pt: 2, pb: 2 }}
              fontWeight={500}
            >
              {`OverAll Rating:`}
            </Typography>
            <Grid item>
              <HoverRating value={value} setValue={setValue} />
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Typography
              variant="body"
              component="div"
              sx={{ pt: 2, pb: 2 }}
              fontWeight={500}
            >
              {`Add a written review: (optional)`}
            </Typography>
            <TextField
              multiline
              placeholder="what did you like or dislike? what did you use this product for?"
              rows={3}
              fullWidth
              value={reviewComments}
              onChange={handleComments}
            />
          </Grid>
          <Grid xs={12} textAlign={"end"} pt={2} pb={2}>
            <Button
              onClick={() =>
                submitProductReview({
                  prdRating: value,
                  comment: reviewComments,
                  sku: item?.sku,
                })
              }
              variant="contained"
              sx={{ color: "white" }}
              disabled={submitDisabled}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid>
        {alert?.isAlert && (
          <CustomizedSnackbars
            text={alert?.msg}
            isOpen={alert?.isAlert}
            type={alert?.type}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};
