import React from "react";
import { ProductsListImageWrapper } from "./ProductsList.style";

const ProductsListImage = (props) => {
  const { imgsrc } = props;
  return (
    <ProductsListImageWrapper>
      <img
        src={imgsrc}
        height={"100%"}
        width={"100%"}
        alt="products-list-img"
      />
    </ProductsListImageWrapper>
  );
};

export default ProductsListImage;
