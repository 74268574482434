//HomePage
export const CLAN_CATEGORIES_LIST = "CLAN_CATEGORIES_LIST";
export const GET_HOMEPAGE_TILES_DATA = "GET_HOMEPAGE_TILES_DATA";

//get products
export const GET_PRODUCTS_LIST = "GET_PRODUCTS_LIST";
export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const CLEAR_PRODUCT_LIST = "CLEAR_PRODUCT_LIST";

//product Details
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const SELECTED_PRODUCT_VARIANT = "SELECTED_PRODUCT_VARIANT";
export const GET_PRODUCT_RATE_DETAILS = "GET_PRODUCT_RATE_DETAILS";
export const SET_GUEST_PINCODE = "SET_GUEST_PINCODE";
export const CHECK_ISPINCODE_AVAILABLE = "CHECK_ISPINCODE_AVAILABLE";
export const SET_UPDATE_PINCODE = "SET_UPDATE_PINCODE";

//cart
export const GET_CART_LIST = "GET_CART_LIST";
export const ADD_TO_CART_ITEM = "ADD_TO_CART_ITEM";
export const CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS";
export const SET_DEFAULT_ADDRESS = "SET_DEFAULT_ADDRESS";
export const UPDATE_CART_ITEMS = "UPDATE_CART_ITEMS";
export const GET_BUYNOW_DATA_SUCCESS = "GET_BUYNOW_DATA_SUCCESS";
export const INITIATE_BUYNOW_CALL = "INITIATE_BUYNOW_CALL";
export const GET_BUYNOW_DATA_FAIL = "GET_BUYNOW_DATA_FAIL";
export const CLEAR_BUYNOW_DATA = "CLEAR_BUYNOW_DATA";

//user
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const GET_AUTH_TOKEN = "GET_AUTH_TOKEN";
export const CLEAR_AUTH_DETAILS = "CLEAR_AUTH_DETAILS";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_WISHLIST = "GET_USER_WISHLIST";
export const DELETE_WISHLIST_ITEM = "DELETE_WISHLIST_ITEM";
export const DELETE_ADDRESSBY_CONTACTDETAILID =
  "DELETE_ADDRESSBY_CONTACTDETAILID";
export const SET_IS_LOGGEDIN = "SET_IS_LOGGEDIN";
export const IS_MERGE_CART_ELIGIBLE = "IS_MERGE_CART_ELIGIBLE";

//Orders
export const GET_MYORDERS_HISTORY = "GET_MYORDERS_HISTORY";

//orderDetails
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";

//Home Page
export const getHomePageTileData = (data) => ({
  type: GET_HOMEPAGE_TILES_DATA,
  payload: data,
});

export const addCategoriesList = (data) => ({
  type: CLAN_CATEGORIES_LIST,
  payload: data,
});

//Product details
export const getProductsList = (data) => ({
  type: GET_PRODUCTS_LIST,
  payload: data,
});

export const productDetails = (data) => ({
  type: GET_PRODUCT_DETAILS,
  payload: data,
});

export const setSelectedProdVariant = (data) => ({
  type: SELECTED_PRODUCT_VARIANT,
  payload: data,
});

export const getProductRateDetails = (data) => ({
  type: GET_PRODUCT_RATE_DETAILS,
  payload: data,
});

export const isPincodeAvailable = (data) => ({
  type: CHECK_ISPINCODE_AVAILABLE,
  payload: data,
});

export const setUpdatePincode = (data) => ({
  type: SET_UPDATE_PINCODE,
  payload: data,
});

export const setGuestPincode = (data) => ({
  type: SET_GUEST_PINCODE,
  payload: data,
});

export const clearProductList = () => ({
  type: CLEAR_PRODUCT_LIST,
});

//Cart
export const addToCartItem = (data) => ({
  type: ADD_TO_CART_ITEM,
  payload: data,
});

export const addFilter = (data) => ({
  type: ADD_FILTER,
  payload: data,
});

export const removeFilter = (data) => ({
  type: REMOVE_FILTER,
  payload: data,
});

export const setAuthToken = (data) => ({
  type: SET_AUTH_TOKEN,
  payload: data,
});

export const getAuthToken = (data) => ({
  type: GET_AUTH_TOKEN,
  payload: data,
});

export const clearAuthDetails = () => ({
  type: CLEAR_AUTH_DETAILS,
});

//CART
export const getCartList = (data) => ({
  type: GET_CART_LIST,
  payload: data,
});

export const clearCartItems = () => ({
  type: CLEAR_CART_ITEMS,
});

export const updateCartItems = (data) => ({
  type: UPDATE_CART_ITEMS,
  payload: data,
});

export const getBuyNowDataSuccess = (data) => ({
  type: GET_BUYNOW_DATA_SUCCESS,
  payload: data,
});

export const initiateBuyNowData = () => ({
  type: INITIATE_BUYNOW_CALL,
});

export const getBuyNowDataFail = () => ({
  type: GET_BUYNOW_DATA_FAIL,
});

export const clearBuyNowData = () => ({
  type: CLEAR_BUYNOW_DATA,
});

export const getAuthDetails = (data) => ({
  type: GET_USER_DETAILS,
  payload: data,
});

export const setDefaultAddress = (data) => ({
  type: SET_DEFAULT_ADDRESS,
  payload: data,
});

export const getUserWishList = (data) => ({
  type: GET_USER_WISHLIST,
  payload: data,
});

export const deleteUserAddressById = (data) => ({
  type: DELETE_ADDRESSBY_CONTACTDETAILID,
  payload: data,
});

export const deleteWishListItem = (data) => ({
  type: DELETE_WISHLIST_ITEM,
  payload: data,
});

export const setIsLoggedIn = (data) => ({
  type: SET_IS_LOGGEDIN,
  payload: data,
});

export const isMergeCartEligible = (data) => ({
  type: IS_MERGE_CART_ELIGIBLE,
  payload: data,
});

//orders Actions

export const getMyOrdersHistory = (data) => ({
  type: GET_MYORDERS_HISTORY,
  payload: data,
});

//Order Details

export const getOrderDetails = (data) => ({
  type: GET_ORDER_DETAILS,
  payload: data,
});
