import { CLAN_CATEGORIES_LIST, GET_HOMEPAGE_TILES_DATA } from "../actions";

const initialState = {
  test: "testData",
};

export const rootReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case "TEST_UPDATE":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export const categoriesReducer = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case CLAN_CATEGORIES_LIST:
      return [...state, ...payload];
    default:
      return state;
  }
};

export const homePageTileReducer = (state = [], action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_HOMEPAGE_TILES_DATA:
      return [...payload];
    default:
      return state;
  }
};
