import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { getDeliveryRate } from "../../../../utils";
import { getProductRateDetails, isPincodeAvailable } from "../../../../actions";
import { Box, Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import CustomizedSnackbars from "../../../Shared/SnackBar";
import FullLoader from "../../../Shared/FullLoader";

const GuestPincode = () => {
  const dispatch = useDispatch();
  const getPincode = localStorage.getItem("pincode");
  const parsePincode = JSON.parse(getPincode);
  const [pinCode, setPincode] = useState(parsePincode || "");
  const [fieldError, setFieldError] = useState(false);
  const [showDelivertRate, setShowDeliveryRate] = useState(false);
  const [alertOpen, setAlertOpen] = useState({
    isAlert: false,
    type: "",
    msg: "",
  });
  const [getRateLoading, setGetRateLoading] = useState(false);
  const productDetails = useSelector((state) => state.productDetails || {});
  const { prodDetails = {}, productRateDetails = {} } = productDetails;
  const { sku = "" } = prodDetails;
  const {
    expectedDeliveryDate = "",
    rate = "",
    isDeliverable = true,
  } = productRateDetails;

  const getProductDelRate = async () => {
    if (!pinCode?.length) {
      setFieldError(true);
    } else if (pinCode?.length < 5) {
      setAlertOpen({
        isAlert: true,
        type: "error",
        msg: "Enter valid Pincode",
      });
    } else {
      setGetRateLoading(true);
      const rateDetails = await getDeliveryRate({ sku, pinCode });
      const { status = 100, data = "" } = rateDetails;
      if (status === 200) {
        setGetRateLoading(false);
        dispatch(isPincodeAvailable(true));
        localStorage.setItem("pincode", JSON.stringify(pinCode));
        dispatch(getProductRateDetails(data));
        setShowDeliveryRate(true);
      } else {
        setGetRateLoading(false);
        setAlertOpen({
          isAlert: true,
          type: "error",
          msg: "Something went wrong, try again!",
        });
      }
    }
  };

  const handlePincode = (e) => {
    e.preventDefault();
    setFieldError(false);
    if (e.target.value.length === 0) {
    }
    setPincode(e.target.value);
    if (alertOpen?.isAlert) {
      setAlertOpen({
        isAlert: false,
        type: "",
        msg: "",
      });
    }
  };

  useEffect(() => {
    setGetRateLoading(false);
    if (parsePincode !== null && parsePincode?.length === 6) {
      setPincode(parsePincode);
      getProductDelRate();
    }
    if (!parsePincode || !pinCode.length) {
      setFieldError(true);
      dispatch(isPincodeAvailable(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box direction="column" spacing={2}>
        <Grid container spacing={1} direction={"column"} m={0}>
          <Grid xs={12} item>
            <Typography
              variant="body"
              color="text.secondary"
              fontWeight={"bold"}
              ml={0}
            >
              Deliver to
            </Typography>
          </Grid>
          <Grid xs={12} item>
            {!getRateLoading ? (
              <Grid container spacing={2} direction={"row"} m={0}>
                <Grid xs={4} md={4}>
                  <TextField
                    id="outlined-error"
                    label="Enter Pincode"
                    error={fieldError}
                    value={pinCode}
                    size="small"
                    fullWidth
                    variant="standard"
                    onChange={handlePincode}
                  />
                </Grid>
                <Grid item>
                  <Button onClick={getProductDelRate}>Check</Button>
                </Grid>
              </Grid>
            ) : (
              <>
                <FullLoader loaderOpenClose={getRateLoading} />
              </>
            )}
          </Grid>
          <Grid xs={12} item>
            {showDelivertRate && isDeliverable && (
              <Typography variant="body" color={"black"}>
                Delivery in {`${expectedDeliveryDate} | ₹${rate}`}
              </Typography>
            )}
            {showDelivertRate && !isDeliverable && (
              <Typography variant="body" color={"red"}>
                Delivery not available at this Pincode.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      {alertOpen?.isAlert && (
        <CustomizedSnackbars
          text={alertOpen?.msg}
          isOpen={alertOpen?.isAlert}
          type={alertOpen?.type}
        />
      )}
    </React.Fragment>
  );
};
export default GuestPincode;
