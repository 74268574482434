import {
  CLEAR_AUTH_DETAILS,
  DELETE_ADDRESSBY_CONTACTDETAILID,
  DELETE_WISHLIST_ITEM,
  GET_AUTH_TOKEN,
  GET_USER_DETAILS,
  GET_USER_WISHLIST,
  IS_MERGE_CART_ELIGIBLE,
  SET_AUTH_TOKEN,
  SET_DEFAULT_ADDRESS,
  SET_IS_LOGGEDIN,
} from "../actions";

const initialState = {};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_AUTH_TOKEN:
      return {
        ...state,
        userAuth: payload,
      };
    case GET_AUTH_TOKEN:
      return {
        ...state,
        userAuth: payload,
      };
    case CLEAR_AUTH_DETAILS:
      return {
        ...initialState,
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        userProfile: payload,
        defaultAddress: {
          ...(payload?.contactDetails?.find((Item) => Item.default) || {}),
        },
      };
    case SET_DEFAULT_ADDRESS:
      return {
        ...state,
        defaultAddress: {
          ...payload,
        },
      };
    case GET_USER_WISHLIST:
      return {
        ...state,
        userWishList: [...payload],
      };
    case DELETE_WISHLIST_ITEM:
      return {
        ...state,
        userWishList: state?.userWishList?.filter((item) => {
          return item.sku !== payload;
        }),
      };
    case DELETE_ADDRESSBY_CONTACTDETAILID:
      let profileData = { ...state.userProfile };
      const filterAddress = profileData?.contactDetails?.filter(
        (item) => item.contactDetailId !== payload
      );
      profileData.contactDetails = [...filterAddress];
      return {
        ...state,
        userProfile: {
          ...profileData,
        },
      };
    case SET_IS_LOGGEDIN:
      return {
        ...state,
        isGuest: payload,
      };
    case IS_MERGE_CART_ELIGIBLE:
      return {
        ...state,
        isMergeCart: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default userReducer;
