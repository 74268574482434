import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import CartItem from "../shared/CartItem";
import Divider from "@mui/material/Divider";

const CartItemDetails = (props) => {
  const { shipments = [] } = props;

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            p: 2,
            pb: 0,
            pt: 0,
          },
        }}
      >
        <Paper elevation={3}>
          {shipments?.length > 0 &&
            shipments?.map((cartItem) => (
              <>
                <CartItem cartItem={cartItem} />
                <Divider />
              </>
            ))}
        </Paper>
      </Box>
    </React.Fragment>
  );
};
export default CartItemDetails;
