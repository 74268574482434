/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Shared/Header";
import Grid from "@mui/material/Unstable_Grid2";
import { getAPIService } from "../API";
import constants from "../../constants";
import {
  addCategoriesList,
  clearAuthDetails,
  getAuthDetails,
  getHomePageTileData,
  setIsLoggedIn,
} from "../../actions";
import { decodeUserToken, getGuestToken } from "../../utils";

const HomeAppNavBar = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state?.categories || []);
  const homePageTiles = useSelector((state) => state.HomePageTiles || []);
  const isGuest = useSelector((state) => state.userDetails?.isGuest || true);
  const { Token = "", userId = "" } = decodeUserToken();

  const getUserAuthDetails = (data) => {
    const URL = `${constants.user.getUserDetails}`;
    const headers = {
      token: `${data}`,
    };

    getAPIService({ url: URL, headers })
      .then((response) => {
        const { status = 0, data = {} } = response || {};
        if (status === 200) {
          dispatch(getAuthDetails({ ...data, isAuthUser: true }));
        } else if (status !== 200) {
          localStorage.removeItem("userToken");
          dispatch(clearAuthDetails());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getCategories = () => {
      const URL = constants.Apis.GetCategories;
      getAPIService({ url: URL })
        .then((response) => {
          const { status = 0 } = response || {};
          if (status === 200) {
            dispatch(addCategoriesList(response?.data));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getHomePageTileItems = () => {
      const URL = constants.HomePage.getHomePageTileData;
      getAPIService({ url: URL }).then((response) => {
        const { status = 0 } = response;
        if (status === 200) {
          dispatch(getHomePageTileData(response?.data));
        }
      });
    };

    const generateGuestToken = async () => {
      const guestToken = await getGuestToken();
      const { data = "", status = 0 } = guestToken;
      if (status === 200) {
        localStorage.setItem("guestToken", JSON.stringify(data));
      }
    };

    if (!categories?.length || !homePageTiles?.length) {
      Promise.all([getCategories(), getHomePageTileItems()]);
    }

    if (!userId && !Token) {
      dispatch(setIsLoggedIn(true));
      generateGuestToken();
    } else {
      dispatch(setIsLoggedIn(false));
      getUserAuthDetails(Token);
    }
  }, []);

  useEffect(() => {
    if (!isGuest && !userId) {
      getUserAuthDetails(Token);
    }
  }, [isGuest]);

  return (
    <React.Fragment>
      <Grid container rowGap={2}>
        <Header />
      </Grid>
      <Outlet />
    </React.Fragment>
  );
};
export default HomeAppNavBar;
