import React from "react";
import { ColorImageWrapper } from "./ImgColors.style";

const ColorImage = (props) => {
  const { image = {} } = props;

  return (
    <ColorImageWrapper>
      <img
        src={image?.imageUrl}
        alt={"color-img"}
        height={"100%"}
        width={"100%"}
      />
    </ColorImageWrapper>
  );
};

export default ColorImage;
