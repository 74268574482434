import styled from "styled-components";

const ImageWrapper = styled.div`
  /* img {
    border-radius: 50px;
    padding: 8px;
  } */
`;

export { ImageWrapper };
