import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
/* import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup"; */
import TextField from "@mui/material/TextField";
/* import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel"; */
import { Button, InputAdornment } from "@mui/material";
import { getAPIService, postAPIService, putAPIService } from "../../API";
import Constants from "../../../constants";
import CustomizedSnackbars from "../SnackBar";
import {
  chooseToken,
  mobileNumValidation,
  pinCodeValidation,
} from "../../../utils";
import { useDispatch } from "react-redux";
import { getAuthDetails } from "../../../actions";
import FullLoader from "../FullLoader";

const AddOrEditAddress = (props) => {
  const dispatch = useDispatch();
  const [isAlert, setIsAlert] = useState({
    isAlert: false,
    type: "",
    msg: "",
  });

  const [validPhone, setValidPhone] = useState(true);
  const [validAltPhone, setValidAltPhone] = useState(true);
  const [validPin, setValidPin] = useState(true);

  const [addressLoader, setAddressLoader] = useState(false);
  const { Token, userId } = chooseToken();
  const {
    disableAddAddress = () => {},
    action = "",
    editAddressDetails = {},
    cancelEditAddress = () => {},
  } = props;
  const [newAddress, setNewAddress] = useState({});

  const {
    fullName = "",
    phoneNo = "",
    address = {},
    addressType = "",
  } = editAddressDetails;
  const {
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    state = "",
    pinCode = "",
    landMark = "",
    aleternatePhone = "",
  } = address;
  const FirstAndLastName = fullName?.length && fullName?.split(" ");

  const newAddressPayload = {
    firstName: "",
    lastName: "",
    phoneNo: "",
    pinCode: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    landMark: "",
    contry: "India",
    addressType: "",
    alternatePhone: "",
  };

  const EditItemPayload = {
    firstName: FirstAndLastName[0] || "",
    lastName: FirstAndLastName[1] || "",
    phoneNo,
    addressLine1,
    addressLine2,
    city,
    state,
    pinCode,
    landMark,
    addressType,
    aleternatePhone,
  };

  const handleCancelAddEdit = () => {
    if (action === "edit") {
      cancelEditAddress();
    } else {
      disableAddAddress();
    }
  };

  const initiateUpdateUserDeitals = async () => {
    const URL = `${Constants.user.getUserDetails}`;
    const headers = {
      token: `${Token}`,
    };
    await getAPIService({ url: URL, headers })
      .then((response) => {
        const { status = 0, data = {} } = response || {};
        if (status === 200) {
          dispatch(getAuthDetails({ ...data, isAuthUser: true }));
          setAddressLoader(false);
          setIsAlert({
            isAlert: true,
            type: "success",
            msg: "Profile Added Successfully",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditOrNewAddress = async () => {
    if (action === "edit") {
      setAddressLoader(true);
      const URL = Constants.user.editAddressDetails;
      const {
        firstName,
        lastName,
        phoneNo,
        pinCode,
        addressLine1,
        addressLine2,
        city,
        state,
        landMark,
        alternatePhone,
      } = newAddress;
      const payload = {
        contactDetailId: editAddressDetails?.contactDetailId,
        userId: editAddressDetails?.userId,
        fullName: `${firstName} ${lastName}`,
        phoneNo,
        alternatePhoneNo: alternatePhone,
        address: {
          pinCode,
          addressLine1,
          addressLine2,
          city,
          state,
          landmark: landMark,
        },
      };
      const response = await putAPIService({ url: URL, data: payload });
      const { status = 100 } = response;
      if (status === 200) {
        initiateUpdateUserDeitals();
        setAddressLoader(false);
        setIsAlert({
          isAlert: true,
          type: "success",
          msg: "Profile Updated Successfully",
        });
        cancelEditAddress();
      } else {
        setAddressLoader(false);
        setIsAlert(
          setIsAlert({
            isAlert: true,
            type: "error",
            msg: "Something went wrong, try again",
          })
        );
      }
    } else if (action === "add") {
      const URL = Constants.user.AddAddressDetails;
      const {
        firstName,
        lastName,
        phoneNo,
        pinCode,
        addressLine1,
        addressLine2,
        city,
        state,
        landMark,
        alternatePhone,
      } = newAddress;
      const addAddressPayload = {
        userId: userId,
        contactDetail: {
          fullName: `${firstName} ${lastName}`,
          email: "",
          phoneNo,
          alternatePhoneNo: alternatePhone,
          address: {
            pinCode,
            addressLine1,
            addressLine2,
            city,
            state,
            landmark: landMark,
            isDefaultAddress: true,
          },
        },
        isWarehouse: false,
        isSharedWarehouse: false,
      };
      setAddressLoader(true);
      const addAddressResponse = await postAPIService({
        url: URL,
        data: addAddressPayload,
      });
      const { status = 100 } = addAddressResponse;
      if (status === 200) {
        disableAddAddress();
        initiateUpdateUserDeitals();
      } else {
        setAddressLoader(false);
        setIsAlert({
          isAlert: true,
          type: "error",
          msg: "Something went wrong, try again",
        });
      }
    }
  };

  const handleUpdateAddress = (e) => {
    const curTarget = e.target.id;
    const curValue = e.target.value;
    if (curTarget === "phoneNo") {
      setValidPhone(mobileNumValidation(curValue));
    } else if (curTarget === "pinCode") {
      setValidPin(pinCodeValidation(curValue));
    } else if (curTarget === "alternatePhone") {
      setValidAltPhone(mobileNumValidation(curValue));
    }
    const currentAddress = { ...newAddress };
    currentAddress[curTarget] = curValue;
    setNewAddress(currentAddress);
  };
  useEffect(() => {
    if (action === "edit") {
      setNewAddress({
        ...EditItemPayload,
      });
    } else if (action === "add") {
      setNewAddress({
        ...newAddressPayload,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        {addressLoader && <FullLoader loaderOpenClose={addressLoader} />}
        <Paper variant="outlined" elevation={3} square>
          <Grid
            container
            spacing={2}
            direction={"row"}
            sx={{ p: 2, justifyContent: "center" }}
          >
            <Grid xs={12}>
              <Typography variant="body1" fontWeight={500}>
                {action === "edit" ? "Edit Address" : "Add New Address"}
              </Typography>
            </Grid>
            <Grid xs={10} md={5}>
              <TextField
                name="addressFirstName"
                required
                fullWidth
                size="small"
                id="firstName"
                label="First Name"
                onChange={handleUpdateAddress}
                value={newAddress?.firstName}
              />
            </Grid>
            <Grid xs={10} md={5}>
              <TextField
                name="addressLastName"
                required
                fullWidth
                size="small"
                id="lastName"
                label="Last Name"
                onChange={handleUpdateAddress}
                value={newAddress?.lastName}
              />
            </Grid>
            <Grid xs={10} md={5}>
              <TextField
                error={!validPhone}
                name="addressMobile"
                required
                fullWidth
                size="small"
                id="phoneNo"
                type="number"
                label="Mobile Number"
                onChange={handleUpdateAddress}
                value={newAddress?.phoneNo}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{`+91 `}</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={12} md={5}>
              <TextField
                error={!validPin}
                name="addressPincode"
                required
                fullWidth
                size="small"
                id="pinCode"
                type="number"
                label="PinCode"
                onChange={handleUpdateAddress}
                value={newAddress?.pinCode}
              />
            </Grid>
            <Grid xs={10} md={10}>
              <TextField
                name="addressArea1"
                fullWidth
                multiline
                required
                maxRows={2}
                id="addressLine1"
                label="Address(Area and Street) line1"
                onChange={handleUpdateAddress}
                value={newAddress?.addressLine1}
              />
            </Grid>
            <Grid xs={10} md={10}>
              <TextField
                name="addressArea2"
                fullWidth
                multiline
                maxRows={2}
                id="addressLine2"
                label="Address(Area and Street) line2"
                onChange={handleUpdateAddress}
                value={newAddress?.addressLine2}
              />
            </Grid>
            <Grid xs={10} md={5}>
              <TextField
                name="cityTown"
                required
                fullWidth
                size="small"
                id="city"
                label="City/District/Town"
                onChange={handleUpdateAddress}
                value={newAddress?.city}
              />
            </Grid>
            <Grid xs={10} md={5}>
              <TextField
                name="addressState"
                required
                fullWidth
                size="small"
                id="state"
                label="state"
                onChange={handleUpdateAddress}
                value={newAddress?.state}
              />
            </Grid>
            <Grid xs={10} md={5}>
              <TextField
                name="addressLandmark"
                fullWidth
                size="small"
                id="landMark"
                label="Landmark"
                onChange={handleUpdateAddress}
                value={newAddress?.landMark}
              />
            </Grid>
            <Grid xs={10} md={5}>
              <TextField
                error={!validAltPhone}
                name="alternateMobile"
                fullWidth
                size="small"
                id="alternatePhone"
                type="number"
                label="Alternate Phone"
                onChange={handleUpdateAddress}
                value={newAddress?.alternatePhone}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{`+91 `}</InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid xs={10} md={10}>
              <FormControl>
                <FormLabel id="row-radio-buttons-group-label">
                  Address Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={newAddress?.addressType}
                  onChange={handleUpdateAddress}
                >
                  <FormControlLabel
                    value="home"
                    control={<Radio />}
                    label="Home"
                  />
                  <FormControlLabel
                    value="work"
                    control={<Radio />}
                    label="Work"
                  />
                </RadioGroup>
              </FormControl>
            </Grid> */}
            <Grid xs={10} md={4}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleEditOrNewAddress}
              >
                Save
              </Button>
            </Grid>
            <Grid xs={10} md={4}>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleCancelAddEdit}
              >
                Cancel
              </Button>
            </Grid>
            <Grid>
              {isAlert?.isAlert && (
                <CustomizedSnackbars
                  text={isAlert.msg}
                  isOpen={isAlert?.isAlert}
                  type={isAlert.type}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default AddOrEditAddress;
