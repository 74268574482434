import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
// import TrackPackage from "../../../Shared/TrackPackage";

const OrderItem = (props) => {
  const navigate = useNavigate();
  const {
    orderItemDetails: { orderItems = [], status = "" } = {},
    orderNumber = "",
  } = props;
  const {
    brand = "",
    imageUrl = "",
    name = "",
    sku = "",
  } = orderItems[0] || {};

  /* const [open, setOpen] = useState(false); */
  const routeToReview = () => {
    navigate(`/product/review?orderId=${orderNumber}`);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} sx={{ mb: 2, mt: 1 }}>
        <Grid xs={12}>
          <Typography variant="body" component="div" fontWeight={"bold"}>
            Status: {status || "Ordered"}
          </Typography>
        </Grid>
        <Grid xs={3}>
          <Link to={`/pd/${sku}`}>
            <img src={imageUrl} alt="delivery_img" height={100} width={100} />
          </Link>
        </Grid>
        <Grid xs={5}>
          <Link to={`/pd/${sku}`} className="route-link">
            <Typography variant="body" component="div" fontWeight={500}>
              {`${brand} ${name}`}
            </Typography>
          </Link>
        </Grid>
        <Grid xs={3}>
          <Grid container spacing={2} direction={"column"} gap={2}>
            {/* <Button variant="outlined">Track package</Button> */}
            <Button variant="outlined" onClick={routeToReview}>
              Write a product review
            </Button>
          </Grid>
        </Grid>
        {/* <Grid>
              <TrackPackage
                open={open}
                setOpen={setOpen}
                shipmentEvents={shipmentEvents}
              />
            </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export default OrderItem;
