import styled from "styled-components";

const ImageCarouselWrapper = styled.div`
  width: -webkit-fill-available;
  .mySwiper {
    height: 260px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #4eb7cf;
  }
`;

const ImagesWrap = styled.div``;

export { ImageCarouselWrapper, ImagesWrap };
