import { GET_MYORDERS_HISTORY } from "../actions";

const initialState = {};

export const myOrdersReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_MYORDERS_HISTORY:
      return {
        ...state,
        ordersList: [...payload],
      };
    default:
      return state;
  }
};
