import {
  CHECK_ISPINCODE_AVAILABLE,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_RATE_DETAILS,
  SELECTED_PRODUCT_VARIANT,
  SET_UPDATE_PINCODE,
} from "../actions";

const initialState = {
  prodDetails: {},
  selectedProdVar: {},
  productRateDetails: {},
  pincodeAvailable: false,
  updatedPincode: "",
};

export const productDetailsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        prodDetails: payload,
        selectedProdVar: {},
      };
    case SELECTED_PRODUCT_VARIANT:
      return {
        ...state,
        selectedProdVar: {
          ...state?.selectedProdVar,
          ...payload,
        },
      };
    case GET_PRODUCT_RATE_DETAILS:
      return {
        ...state,
        productRateDetails: { ...payload },
      };
    case CHECK_ISPINCODE_AVAILABLE:
      return {
        ...state,
        pincodeAvailable: payload,
      };
    case SET_UPDATE_PINCODE:
      return {
        ...state,
        updatedPincode: payload,
      };
    default:
      return state;
  }
};
