import { AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

const HeaderNavBar = styled(AppBar)`
  position: relative;
  box-shadow: none;
  padding: 0 !important;
  .toolbar {
    color: white;
  }
`;

export { HeaderNavBar };
