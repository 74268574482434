import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { setDefaultAddress } from "../../../actions";
import AddOrEditAddress from "../../Shared/AddOrEditAddress";

const CheckoutAddressGroup = (props) => {
  const { stepAction = () => {} } = props;
  const dispatch = useDispatch();
  const selectedAddress = useSelector(
    (state) => state.userDetails?.defaultAddress || {}
  );
  const { contactDetailId = "" } = selectedAddress;
  const userProfile = useSelector(
    (state) => state.userDetails?.userProfile || {}
  );
  const { contactDetails = [] } = userProfile;
  const [selectedAddressIdx, setSelectedAddressIdx] =
    React.useState(contactDetailId);
  const [sortContactAddress, setSortContactAddress] = React.useState([]);
  const [noAddressList, setNoAddressList] = React.useState(false);
  const [enableAddAddress, setEnableAddAddress] = React.useState(false);

  const selectedAdresss = (event) => {
    setSelectedAddressIdx(event.target.value);
    const getChangeAddress = contactDetails?.find(
      (Item) => Item.contactDetailId === event.target.value
    );
    dispatch(setDefaultAddress(getChangeAddress));
  };

  React.useEffect(() => {
    if (contactDetails?.length) {
      setNoAddressList(false);
      let sortedList = contactDetails.reduce((prev, curr) => {
        let obj = { ...curr };
        let selectedAdd =
          curr.contactDetailId === contactDetailId ? curr.contactDetailId : "";
        if (selectedAdd) {
          prev.unshift(obj);
        } else {
          prev.push(obj);
        }
        return prev;
      }, []);
      setSortContactAddress(sortedList);
    } else {
      setNoAddressList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactDetails]);

  const addNewAddressClick = () => {
    setEnableAddAddress(true);
  };

  const disableAddAddress = () => {
    setEnableAddAddress(false);
  };

  return (
    <>
      {!noAddressList ? (
        <FormControl sx={{ width: "100%" }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={selectedAddressIdx}
            name="radio-buttons-group"
            onChange={selectedAdresss}
          >
            {sortContactAddress?.length > 0 &&
              sortContactAddress?.map((element) => (
                <FormControlLabelContainer
                  stepAction={stepAction}
                  element={element}
                  selectedAddressIdx={selectedAddressIdx}
                />
              ))}
          </RadioGroup>
        </FormControl>
      ) : (
        <Grid xs={12} item sx={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            sx={{ pt: 1, pb: 1 }}
            onClick={addNewAddressClick}
          >
            Add A New Address
          </Button>
          {enableAddAddress && (
            <AddOrEditAddress
              action="add"
              disableAddAddress={disableAddAddress}
            />
          )}
        </Grid>
      )}
    </>
  );
};
export default CheckoutAddressGroup;

const FormControlLabelContainer = (props) => {
  const { stepAction, element, selectedAddressIdx } = props;
  const { fullName = "", phoneNo = "", address = {}, userId = "" } = element;
  const {
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    state = "",
    pinCode = "",
  } = address;

  const EditAddressPlayload = {
    fullName,
    phoneNo,
    address,
    contactDetailId: selectedAddressIdx,
    userId,
  };

  // const [editDeliveryAddress, setEditDeliveryAddress] = React.useState();
  const [enableEditAddress, setEnableEditAddress] = React.useState(false);

  const handleEditDeliveryAddress = () => {
    setEnableEditAddress(true);
  };

  const handleCancelEditAddress = () => {
    setEnableEditAddress(false);
  };

  React.useEffect(() => {
    setEnableEditAddress(false);
  }, []);

  return (
    <Grid container spacing={2} direction={"row"}>
      {!enableEditAddress && (
        <Grid xs={11} md={11}>
          <FormControlLabel
            value={element?.contactDetailId}
            control={<Radio />}
            label={
              <>
                <Grid
                  container
                  spacing={2}
                  direction={"column"}
                  sx={{ p: 0, m: 0 }}
                  rowSpacing={1}
                >
                  <Grid>
                    <Typography
                      variant="body"
                      component="div"
                      color="black"
                      fontWeight={"bold"}
                    >
                      {`${fullName}, ${phoneNo}`}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant="body"
                      component="div"
                      color="text.secondary"
                      fontWeight={500}
                    >
                      {`${addressLine1}, ${
                        addressLine2 && addressLine2
                      }, ${city}, ${state}, ${pinCode}`}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            }
            sx={{ pb: 1 }}
          />
          {selectedAddressIdx === element.contactDetailId &&
            !enableEditAddress && (
              <Grid sx={{ pl: 5 }}>
                <Button
                  variant="contained"
                  color="buynow"
                  sx={{ color: "white", mb: 2 }}
                  onClick={() => stepAction(3)}
                >
                  Deliver here
                </Button>
              </Grid>
            )}
        </Grid>
      )}
      <Grid xs={1} md={1}>
        {selectedAddressIdx === element.contactDetailId &&
          !enableEditAddress && (
            <Button onClick={handleEditDeliveryAddress}>Edit</Button>
          )}
      </Grid>
      {enableEditAddress && (
        <Grid xs={12} md={12}>
          <FormControlLabel
            value={"Edit Address"}
            control={<Radio checked />}
            label={
              <>
                <Grid xs={11} md={11}>
                  <AddOrEditAddress
                    action="edit"
                    editAddressDetails={EditAddressPlayload}
                    cancelEditAddress={handleCancelEditAddress}
                  />
                </Grid>
              </>
            }
          />
        </Grid>
      )}
    </Grid>
  );
};
