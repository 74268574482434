import { Container, Grid, Typography } from "@mui/material";
import React from "react";

const TermsAndConditions = () => {
  return (
    <React.Fragment>
      <Container maxWidth={"md"}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="h4">Terms And Conditions</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Introduction
            </Typography>
            <Typography variant="body">
              Welcome to Clan.Business! These Terms and Conditions ("Terms")
              govern your use of our website and services. By accessing or using
              Clan.Business, you agree to comply with and be bound by these
              Terms.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Electronic Record
            </Typography>
            <Typography variant="body">
              This document is an electronic record in terms of the Information
              Technology Act, 2000, and the rules thereunder, as applicable, and
              the amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Eligibility
            </Typography>
            <Typography variant="body">
              By using Clan.Business, you affirm that you are at least 18 years
              old or have reached the age of majority in your jurisdiction. If
              you are under 18, you may use our services only with the
              involvement of a parent or guardian.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Account Registration
            </Typography>
            <Typography variant="body">
              To access certain features, you may need to create an account. You
              agree to provide accurate and complete information and keep your
              account details updated. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities
              under your account.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              User Conduct
            </Typography>
            <Typography variant="body">
              You agree not to use Clan.Business for any unlawful or prohibited
              activities. This includes, but is not limited to, violating
              intellectual property rights, engaging in fraudulent transactions,
              or distributing harmful software.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Product Listings and Purchases
            </Typography>
            <ul>
              <li>
                <b>Listings:</b>Clan.Business does not own or sell the products
                listed on the platform. We facilitate transactions between
                buyers and sellers.
              </li>
              <li>
                <b>Purchases:</b>When purchasing a product, you agree to the
                terms of sale set by the seller. All purchases are subject to
                availability, and prices may vary.
              </li>
            </ul>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Shipping and Delivery
            </Typography>
            <Typography variant="body">
              Shipping and delivery times are estimated and may vary based on
              location, product, and seller policies. Clan.Business is not
              responsible for delays or issues in shipping that are beyond our
              control.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Returns and Refunds
            </Typography>
            <Typography variant="body">
              Our return and refund policy vary by seller. Please review the
              seller’s return policy before making a purchase. Refunds are
              issued at the seller's discretion, subject to the conditions of
              the product.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Privacy Policy
            </Typography>
            <Typography variant="body">
              Your privacy is important to us. Please review our Privacy Policy
              to understand how we collect, use, and protect your information.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Intellectual Property
            </Typography>
            <Typography variant="body">
              All content on Clan.Business, including logos, text, graphics, and
              software, is protected by intellectual property laws. You may not
              reproduce, distribute, or create derivative works from our content
              without permission.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Disclaimer of Warranties
            </Typography>
            <Typography variant="body">
              Clan.Business provides the platform "as is" without any
              warranties. We do not guarantee the accuracy, reliability, or
              availability of our services.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Limitation of Liability
            </Typography>
            <Typography variant="body">
              Clan.Business will not be liable for any indirect, incidental,
              special, or consequential damages arising from your use of our
              platform.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Governing Law
            </Typography>
            <Typography variant="body">
              These Terms are governed by the laws of [Your Jurisdiction]. Any
              disputes will be resolved in the courts of [Your Jurisdiction].
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Changes to Terms
            </Typography>
            <Typography variant="body">
              We may update these Terms at any time. Changes will be effective
              upon posting on our website. Your continued use of Clan.Business
              after the changes signifies your acceptance of the new Terms.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ mb: 1 }}>
              Contact Us
            </Typography>
            <Typography variant="body">
              If you have any questions or concerns about these Terms, please
              contact us at [
              <a href="mailto:info@clan.business">info@clan.business</a> or{" "}
              <a href="tel:+91 9821847814">+91 9821847814</a> ].
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default TermsAndConditions;
