import React from "react";
import DrawerComponent from "../DrawerComponent";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import { Divider, Grid, Typography } from "@mui/material";

const TrackPackage = (props) => {
  const { open, setOpen, shipmentEvents = {} } = props;

  const trackBodyContainer = () => {
    return (
      <React.Fragment>
        <Grid container direction={"column"}>
          {shipmentEvents?.length > 0 &&
            shipmentEvents.map((event, idx) => (
              <>
                <Grid container direction={"row"} mt={0.5}>
                  <Grid xs={2}>
                    <AdjustOutlinedIcon color="primary" />
                  </Grid>
                  <Grid xs={6} sx={{ height: "35px" }}>
                    <Typography variant="body" component={"div"}>
                      {event?.status}
                    </Typography>
                    <Typography variant="body">
                      {event?.eventDateTime}
                    </Typography>
                  </Grid>
                </Grid>
                {idx !== shipmentEvents?.length - 1 && (
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: "40px",
                      width: "0.5px",
                      ml: 1.3,
                      background: "primary",
                      border: "1px solid #4eb7cf",
                    }}
                  />
                )}
              </>
            ))}
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <DrawerComponent
        isOpen={open}
        setIsOpen={setOpen}
        title="Track Details"
        body={trackBodyContainer()}
      />
    </React.Fragment>
  );
};

export default TrackPackage;
