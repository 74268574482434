import Constants from "../../constants";
import { chooseToken } from "../../utils";
import { getCartAPIService } from "../API";

export const getCarItems = async ({ setIsError, setIsLoading, pincode }) => {
  const URL = Constants?.cart?.getCart;
  const { Token: useToken = "" } = chooseToken();
  const token = `Bearer ${useToken}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  const responseData = await getCartAPIService({
    url: URL,
    pincode,
    headers,
  });
  const { status = 100 } = responseData || {};
  if (status === 200) {
    setIsError({
      error: false,
      errorDetails: JSON.stringify({
        errorM: "",
        status: "",
      }),
    });
    return responseData;
  } else {
    const { response: { status = "", data: { errorMessage = "" } = {} } = {} } =
      responseData;
    setIsError({
      error: true,
      errorDetails: JSON.stringify({
        errorM: errorMessage || "Something went wrong, Try again",
        status: status || "",
      }),
    });
    setIsLoading(false);
    return responseData;
  }
};
