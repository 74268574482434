import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const DialogComponent = (props) => {
  const { isOpen = false, isClose = () => {}, Title = "", body } = props;
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={isClose}
        aria-labelledby="dialog-comp-title"
        aria-describedby="dialog-comp-description"
      >
        <DialogTitle id="dialog-comp-title">{Title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-comp-description">
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={isClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogComponent;
