import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import ProductsListImage from "./ProductsListImage";
import ProductsListDesc from "./ProductsListDesc";

const ProductListDetails = (props) => {
  const { listItems = [] } = props;

  return (
    <React.Fragment>
      {listItems?.length > 0 &&
        listItems?.map((element) => (
          <Grid
            md={1}
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
            key={element?.sku}
          >
            <Link to={element?.pdUrl || "#"} className="route-link">
              <ProductsListImage imgsrc={element?.imageUrl?.imageUrl} />
              <ProductsListDesc productDetails={element} />
            </Link>
          </Grid>
        ))}
    </React.Fragment>
  );
};
export default ProductListDetails;
