import { Container, Grid, Typography } from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <React.Fragment>
      <Container maxWidth={"md"}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <Typography variant="h4">
              {`Privacy policy for clan business`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`We at Clan.Business are committed to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, share, and store your information when you interact with our website or services provided by Clan.Business.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              {`Collection of Your Information`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`When you visit our website or use our services, we do not collect any information about you without your consent. You can browse our website anonymously without revealing any personal information. However, if you voluntarily provide your personal information to us, it will be processed in accordance with applicable laws.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`Use of Your Information`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`We may collect and use your IP address to enhance the functionality of our website and to diagnose any technical issues. Additionally, we may use your information for legal or regulatory purposes and to improve our services. Any information collected is processed securely and in compliance with relevant data protection laws.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`Cookies`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`Clan.Business may use cookies or similar technologies on certain pages of our website to improve user experience and analyze website traffic. Cookies do not contain any personal information and can be managed at the individual browser level. Disabling cookies may limit certain features of our services.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`Sharing of Personal Information`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`We may share personal information internally within Clan.Business for the purpose of providing services to you. Additionally, we may share information with trusted third parties for business-related purposes, such as analytics or marketing. However, we do not disclose your personal information to unrelated third parties for marketing or advertising purposes without your explicit consent. In case of any issue please contact customer care.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`Link to Other Sites`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`Our website may contain links to third-party websites or applications. We encourage you to review the privacy policies of these websites before providing any personal information, as we are not responsible for their privacy practices.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`Security Precautions`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`Clan.Business maintains appropriate security measures to protect your information against unauthorized access or disclosure. However, please note that no method of transmission over the internet is completely secure, and we cannot guarantee the absolute security of your data.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`Children Information`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`Our services are not intended for children under the age of 18. We do not knowingly collect personal information from children, and if you are under 18, please do not provide any personal information without parental consent.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`Data Retention`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. We may also retain data to prevent fraud, defend against legal claims, or for other legitimate purposes.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`Your Rights`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`Clan.Business respects your rights regarding your personal information. If you have any concerns or wish to exercise your rights under applicable data protection laws, please contact us using the details provided below.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`Your Consent`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`By using our website or providing your information to Clan.Business, you consent to the collection, use, and processing of your information as described in this Privacy Policy. If you provide personal information about others, you represent that you have the authority to do so and permit us to use the information in accordance with this Policy.`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">{`Changes to this Privacy Policy`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body">
              {`We may update this Privacy Policy periodically to reflect changes in our information practices. Please review this Policy regularly for any updates or changes.`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
