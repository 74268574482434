import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Constants from "../../../../constants";
import { ATCAPIService } from "../../../API";
import { getCartList, updateCartItems } from "../../../../actions";
import SingleCartItem from "./SingleCartItem";
import { decodeUserToken, UpdateCartItemsPayload } from "../../../../utils";
import CheckoutSkeleton from "../../../Skeletons/CheckoutSkeleton";
import { getCarItems } from "../../cartUtil";

const CartItem = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({ error: false, errorDetails: "" });
  const {
    cartItem: {
      cartItemDetails = [],
      vendorName = "",
      rate: { expectedDeliveryDate = "" } = {},
    } = {},
  } = props;

  const userDefaultAddress = useSelector(
    (state) => state.userDetails?.defaultAddress || {}
  );

  const { address = {} } = userDefaultAddress;
  const { pinCode = "" } = address;

  const handleUpdateRemoveCart = (e) => {
    setIsLoading(true);
    const { Token = "" } = decodeUserToken();
    const URL = Constants.cart.addToCart;
    const addToCartPayload = UpdateCartItemsPayload({ ...e });
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Token}`,
    };
    ATCAPIService({ url: URL, data: addToCartPayload, headers })
      .then((response) => {
        const { status = 0, data = {} } = response;
        if (status === 200) {
          setIsError({
            error: false,
            errorDetails: JSON.stringify({
              errorM: "",
              status: "",
            }),
          });
          dispatch(updateCartItems(data?.cartItems));
        } else {
          const { message = "", status = 0 } = response;
          console.log("error_response", response);
          setIsError({
            error: true,
            errorDetails: JSON.stringify({
              errorM: message,
              status: status || "",
            }),
          });
        }
      })
      .then(async () => {
        const AllcartData = await getCarItems({
          setIsError,
          setIsLoading,
          pincode: pinCode,
        });
        if (AllcartData?.data) {
          dispatch(getCartList(AllcartData?.data));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      {isError?.error && (
        <Grid>
          <Typography variant="h5">{isError?.errorDetails}</Typography>
        </Grid>
      )}
      {isLoading ? (
        <>
          <CheckoutSkeleton />
        </>
      ) : (
        cartItemDetails?.length > 0 &&
        cartItemDetails.map((item) => (
          <SingleCartItem
            item={item}
            vendorName={vendorName}
            deliveryDateAndTime={expectedDeliveryDate}
            handleUpdateRemoveCart={handleUpdateRemoveCart}
          />
        ))
      )}
    </React.Fragment>
  );
};

export default CartItem;
