import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Navigation } from "swiper/modules";
import { HALImageWrap, HomeAndLivingWrapper } from "./homeAndLiving.style";
import { Link } from "react-router-dom";

const HomeAndLivingCarousel = ({ items, title }) => {
  return (
    <HomeAndLivingWrapper id="topCatwrapper">
      <Grid container direction={"column"}>
        <Grid sx={{ p: 2 }} textAlign={"left"}>
          <Typography variant="h6" fontWeight={500}>
            {title}
          </Typography>
        </Grid>
        <Grid sx={{ p: 2 }} width={"-webkit-fill-available"}>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            {items?.length &&
              items.map((imgSrc, idx) => (
                <SwiperSlide key={idx}>
                  <Link
                    to={
                      imgSrc?.type === "product"
                        ? `/pd/${imgSrc?.id}`
                        : `/products/${imgSrc?.id}`
                    }
                  >
                    <HALImageWrap className="homeandLiving_img_Wrap">
                      <img src={imgSrc?.imageUrl} alt="top_cat" />
                    </HALImageWrap>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
        </Grid>
      </Grid>
    </HomeAndLivingWrapper>
  );
};

export default HomeAndLivingCarousel;
